import {AbstractControl} from "@angular/forms";

export function ValidateConfirm(control: AbstractControl) {
  const value = control.value;

  if (!value) {
    return {'appConfirmCheck' : true };
  }

  return null;
}
