<p *ngIf="this.workflows.length > 0">
  Hieronder vindt u een overzicht van uw aanmelding(en). Afgeronde inschrijvingen kunnen niet meer bewerkt of verwijderd worden.
</p>
<!--    Tabel alleen voor desktop weergave-->
<table class="table table-borderless hide-for-small-only" *ngIf="this.workflows.length > 0">
  <thead>
  <tr>
    <th>&nbsp;</th>
    <th>Wie</th>
    <th>School</th>
    <th>Status</th>
    <th><ng-container *ngIf="referred">Doorverwijzing</ng-container></th>
    <th>Laatste wijziging</th>
    <th>Actie</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let workflow of workflows" [ngClass]="{'bg-warning': workflow.isTest, 'rejected': workflow.referralStatus === 'REJECTED', 'pending': workflow.referralStatus === 'PENDING'}">
    <td>
      <button class="btn btn-default btn-sm"
              (click)="openModalWithTemplateParameter(detailPO, workflow)" title="Bekijk aanmelding">
        <i class="fas fa-search" aria-hidden="true"></i></button>
    </td>
    <td>
      <div *ngIf="workflow.registration?.fullName && workflow.registration?.fullName.length > 0">
        {{workflow.registration.fullName}}
      </div>
      <div *ngIf="!workflow.registration?.fullName || workflow.registration?.fullName.length === 0">
        -
      </div>
    </td>
    <td>
      {{workflow?.schoolName}}
    </td>
    <td>
      {{getWorkflowStatus(workflow?.workflowStatus)}}
    </td>
    <td *ngIf="!workflow.referralStatus">&nbsp;</td>
    <td *ngIf="workflow.referralStatus">
      <ng-container *ngIf="workflow.referralStatus !== 'PENDING'">
        {{getReferralStatus(workflow.referralStatus)}}
      </ng-container>
      <button class="btn btn-success btn-sm" *ngIf="workflow.referralStatus === 'PENDING'"
              (click)="approve(workflow,approvePO)" title="Bekijk de te nemen actie!">
       <i class="fas fa-forward" aria-hidden="true"></i>
       </button>
    </td>
    <td>
      <div>
        {{formatDate(workflow.modifiedAt)}}
      </div>
    </td>
    <td>
      <ng-container [ngTemplateOutlet]="buttontable"
                    [ngTemplateOutletContext]="{$implicit:workflow}">
      </ng-container>
    </td>
  </tr>
  </tbody>
</table>

<!--    Tabel alleen voor mobiele weergave-->
<table class="table table-borderless table-striped hide-for-desktop" *ngIf="this.workflows.length > 0">
  <thead>
  <tr>
    <th>&nbsp;</th>
    <th>Aanmelding</th>
    <th>Actie</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let signUpWorkflow of workflows" [ngClass]="{'bg-warning': signUpWorkflow.isTest, 'rejected': signUpWorkflow.referralStatus === 'REJECTED', 'pending': signUpWorkflow.referralStatus === 'PENDING'}">
    <td>
      <button class="btn btn-default btn-sm"
              (click)="openModalWithTemplateParameter(detailPO, signUpWorkflow)" title="Bekijk aanmelding">
        <i class="fas fa-search" aria-hidden="true"></i></button>
    </td>
    <td>
      <div *ngIf="signUpWorkflow.registration.fullName">
        {{signUpWorkflow.registration.fullName}}
      </div>
      {{getWorkflowStatus(signUpWorkflow.workflowStatus)}}
      <br/>
      <div *ngIf="signUpWorkflow.referralStatus === 'PENDING'">
        Doorverwijzing
        <button class="btn btn-success btn-sm" *ngIf="signUpWorkflow.referralStatus === 'PENDING'"
                (click)="approve(signUpWorkflow,approvePO)" title="Bekijk de te nemen actie!">
          <i class="fas fa-forward" aria-hidden="true"></i>
        </button>
      </div>
    </td>
    <td>
      <ng-container [ngTemplateOutlet]="buttontable"
                    [ngTemplateOutletContext]="{$implicit:signUpWorkflow}">
      </ng-container>

      <div class="btn-group" role="group">
      </div>
    </td>
  </tr>
  </tbody>
</table>

<ng-template #buttontable let-signUpWorkflow>
  <table class="buttontable">
    <tr>
      <td  *ngIf="!signUpWorkflow.registration?.isCompleted || signUpWorkflow.isTest">
        <button class="btn btn-success btn-sm"
                (click)="editWorkflow(signUpWorkflow,'registration')" title="Aanmelding bewerken">
          <i class="fas fa-edit" aria-hidden="true"></i></button>
      </td>
      <td  *ngIf="signUpWorkflow.registration?.isCompleted && !signUpWorkflow.isTest">
        <button class="btn btn-info btn-sm"
                (click)="getRegistrationPdf(signUpWorkflow.id)" title="Download aanmelding PDF">
          <i class="fas fa-file-pdf" aria-hidden="true"></i></button>
      </td>

      <td  *ngIf="intakeByParent && (signUpWorkflow.workflowStatus === 'INTAKE_INVITE' || signUpWorkflow.workflowStatus === 'INTAKE_INPROGRESS')">
        <button class="btn btn-success btn-sm" (click)="editWorkflow(signUpWorkflow,'intake')" title="Intake starten">
          <i class="fas fa-edit" aria-hidden="true"></i></button>
      </td>
      <td *ngIf="signUpWorkflow.intake?.isCompleted">
        <button class="btn btn-info btn-sm"
                (click)="getIntakePdf(signUpWorkflow.id)" title="Download intakeformulier PDF">
          <i class="fas fa-file-pdf" aria-hidden="true"></i></button>
      </td>
      <td  *ngIf="signUpWorkflow.workflowStatus === 'SIGNUP_INVITE' || signUpWorkflow.workflowStatus === 'SIGNUP_INPROGRESS'">
        <button class="btn btn-success btn-sm"
                (click)="editWorkflow(signUpWorkflow,'signup')" title="Inschrijven">
          <i class="fas fa-edit" aria-hidden="true"></i></button>
      </td>
      <td  *ngIf="signUpWorkflow.signUp?.isCompleted">
        <button class="btn btn-info btn-sm"
                (click)="getSignUpPdf(signUpWorkflow.id)" title="Download inschrijving PDF">
          <i class="fas fa-file-pdf" aria-hidden="true"></i></button>
      </td>
      <td  *ngIf="!signUpWorkflow.signUp?.isCompleted || signUpWorkflow.isTest">
        <button class="btn btn-danger btn-sm"
                (click)="openModalWithTemplateParameter(templatePO, signUpWorkflow)" title="Aanmelding verwijderen?">
          <i class="fas fa-trash" aria-hidden="true"></i></button>
      </td>
    </tr>
  </table>
</ng-template>

<ng-template #templatePO>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Bevestigen</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    Weet u zeker dat u de aanmelding wilt verwijderen?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default" (click)="modalRef.hide()"><i class="fas fa-times" aria-hidden="true"></i> Nee</button>
    <button type="button" class="btn btn-danger" (click)="deleteWorkflowRegistration(getModalInitialStateData().id)"><i class="fas fa-check" aria-hidden="true"></i> Ja</button>
  </div>
</ng-template>


<!--Template that will be called from openModalWithTemplateParameter, with initialState.data (set in modalService.config.initialState.data) as signUpWorkflow -->
<ng-template #approvePO>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Aanmelding doorverwijzen</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <p>De aanmelding van {{getModalInitialStateData().registration.fullName}} is doorverwezen van {{getModalInitialStateData().referral.fromSchoolName}}
      naar {{getModalInitialStateData().referral.toSchoolName}}</p>
    <p *ngIf="getModalInitialStateData().referral?.noteToParent?.length > 0">Bij de doorverwijzing kwam de volgende toelichting:</p>
    <p *ngIf="getModalInitialStateData().referral?.noteToParent?.length > 0">{{getModalInitialStateData().referral.noteToParent}}</p>
    <p><b>Geeft u toestemming voor de doorverwijzing en het doorsturen van alle gegevens met betrekking tot de aanmelding naar
      {{getModalInitialStateData().referral.toSchoolName}}? </b></p>
    <p><i>Let op: weigeren van toestemming betekent dat uw aanmelding niet verder verwerkt kan worden binnen onze scholengroep.</i></p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="rejectApproval(getModalInitialStateData().id)"><i class="fas fa-times" aria-hidden="true"></i> Nee</button>
    <button type="button" class="btn btn-success" (click)="approveReferral(getModalInitialStateData().id)"><i class="fas fa-check" aria-hidden="true"></i> Ja</button>
  </div>
</ng-template>


<!--Template that will be called from openModalWithTemplateParameter, with initialState.data (set in modalService.config.initialState.data) as signUpWorkflow -->
<ng-template #detailPO>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Aanmelding</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <table class="table table-borderless table-striped">
      <tr>
        <td class="label">Wanneer</td>
        <td>{{formatDate(getModalInitialStateData().createdAt)}}</td>
      </tr>
      <tr>
        <td class="label">Wie</td>
        <td>{{getModalInitialStateData().registration?.fullName}}</td>
      </tr>
      <tr>
        <td class="label">School</td>
        <td>{{getModalInitialStateData().schoolName}}</td>
      </tr>
      <tr>
        <td class="label">Laatst gewijzigd</td>
        <td>
          <div>
            {{formatDate(getModalInitialStateData().modifiedAt)}}
          </div>
        </td>
      </tr>
      <tr *ngIf="getModalInitialStateData().referralStatus">
        <td class="label">Doorverwijzing</td>
        <td *ngIf="getModalInitialStateData().referralStatus">
          <ng-container [ngSwitch]="getModalInitialStateData().referralStatus">
            <ng-container *ngSwitchCase="'PENDING'">
              Wacht op goedkeuring door ouder(s)/verzorger(s)
            </ng-container>
            <ng-container *ngSwitchCase="'REJECTED'">
              Afgewezen door ouder(s)/verzorger(s)
            </ng-container>
            <ng-container *ngSwitchCase="'APPROVED'">
              Doorverwijzing goedgekeurd door ouder(s)/verzorger(s)
            </ng-container>
            <ng-container *ngSwitchCase="'REFERRED'">
              Doorverwezen
            </ng-container>
          </ng-container>
        </td>
      </tr>
      <tr>
        <td class="label">Status</td>
        <td>
          {{getWorkflowStatus(getModalInitialStateData().workflowStatus)}}
        </td>
      </tr>
    </table>
  </div>
</ng-template>

