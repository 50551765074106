import {AbstractControl, Validators} from "@angular/forms";

export function ValidateEmail(control: AbstractControl) {
  const value = typeof control.value === 'string' ? control.value : String(control.value);

  // string value, so null object becomes 'null'
  if (!value || value == 'null') {
    return null;
  }

  if (Validators.email(control) !== null) {
    return {
      'appCheckEmail': {value: value}
    };
  }

  if (value.includes('..')) {
    return {
      'appCheckEmail': {value: value}
    };
  }

  //
  const partparts = value.split('@')
  if (partparts.length != 2) {
    return {
      'appCheckEmail': {value: value}
    };
  }
  // check of domain part minimal 2 TLD size minimum 2
  const parts = partparts[1].split('.')
  if (parts.length < 2 || parts[parts.length-1].length < 2) {
    return {
      'appCheckEmail': {value: value}
    };
  }
  return null;
}
