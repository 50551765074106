<div class="steps-container">
  <div class="steps-header">
    <div class="steps-title">
      Stappen
    </div>
  </div>
  <div class="steps-list" *ngIf="form  && form !== null">
    <ng-container *ngFor="let section of form.sections">
      <div class="steps-step" *ngIf="section.enabled"
           [ngClass]="{'steps-active': linkEquals(section.type) }">
        <span  class="steps-name">{{section.label}}</span>
      </div>
    </ng-container>
  </div>
</div>
