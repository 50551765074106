import {Component, OnInit} from '@angular/core';
import {FormBuilder} from "@angular/forms";
import {Router} from '@angular/router';
import {Signature} from '../../shared/model/signup';
import {TextFormatService} from "../../shared/services/textformat.service";
import {DynamicSectionComponent} from "../../shared/abstract/dynamic-section-component";
import {FormService} from "../../shared/services/form.service";
import {FormType, SectionType} from "../../shared/model/formSection";
import {FormField} from "../../shared/model/formField";
import {NotificationService} from "../../shared/services/notification.service";
import {WorkflowService} from "../../shared/services/workflow.service";
import {Registration, Workflow} from "../../shared/model/workflow";
import {BsModalService} from "ngx-bootstrap/modal";
import {RuleEvaluatorService} from "../../shared/services/rule-evaluator.service";
import {ScrollService} from "../../shared/services/scroll.service";


@Component({
  selector: 'app-po-sign-aanmelding-dynamic',
  templateUrl: './po-sign-aanmelding-dynamic.component.html'
})
export class PoSignAanmeldingDynamicComponent extends DynamicSectionComponent implements OnInit {

  btnSignValue: string = '<i class="fas fa-angle-right"></i> Ondertekenen en aanmelding afronden';

  workflow: Workflow;
  registration: Registration;
  signature: Signature = {
    customQuestionField: {}
  } as Signature;

  constructor(
      router: Router,
      notificationService: NotificationService,
      ruleEvaluatorService: RuleEvaluatorService,
      private formBuilder: FormBuilder,
      formService: FormService,
      private workflowService: WorkflowService,
      private textFormatService: TextFormatService,
      modalService: BsModalService,
      scrollService: ScrollService) {
    super(router,formService,notificationService,modalService, ruleEvaluatorService, scrollService, SectionType.SIGN, FormType.SIGNUP)
    this.formGroup = this.formBuilder.group({});
  }

  ngOnInit() {
    this.scrollService.onActivate(true); //Scroll to top of page when new step is opened
    this.workflow = this.workflowService.getWorkflow();
    this.workflowService.getRegistrationByWorkflowId(this.workflow).subscribe((reg) => {
      this.workflowService.setRegistration(reg)
      this.registration = reg
    });

    if (this.registration?.signature !== undefined && this.registration?.signature !== null) {
      this.signature = this.registration?.signature;
      if (!this.signature.customQuestionField) {
        this.signature.customQuestionField = {};
      }
    }
    this.signature.signedAt = this.getCurrentDateTime();

    super.commonNgOnInit();
    if (!this.getSection()) return;
    this.getSection().fields.forEach(fld => {
      this.checkControl(fld,this.signature, null);
    });
  }

  checkCondition(fld: FormField): boolean {
    let checkOk = true;
    return checkOk;
  }

  getCurrentDateTime(): string {
    const currentDT = new Date();

    return currentDT.getDate() + '-' +
      (currentDT.getMonth() + 1) + '-' +
      currentDT.getFullYear() + ' ' +
      currentDT.getHours() + ':' +
      (currentDT.getMinutes() < 10 ? '0' + currentDT.getMinutes() : currentDT.getMinutes());
  }

  save(): boolean {
    if (!this.prepareSave(this.signature)) return false;

    this.prepareCustomQuestionsForSave(this.signature);

    this.signature.signedBy = this.textFormatService.capitalizeFirstLetter(this.signature.signedBy);

    this.workflowService.saveSignature(this.signature).subscribe(() => {
      this.getRouter().navigate(['/po/bedankt']);
    }, (err) => {
      this.getRouter().navigate(['/home']);
      this.handleSaveError(err);
    });
  }

  changeButtonText(): void {
    this.btnClickAllowed = !this.btnClickAllowed;
    this.btnTextValue === this.btnTextNext ? this.btnTextValue = this.btnTextWait : this.btnTextValue = this.btnTextNext;
  }

}
