import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from "rxjs";
import {Status} from "../model/status";
import {map, shareReplay} from "rxjs/operators";

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class StatusService {
  private statusUrl = 'api/status/';

  status$: Observable<Status>;

  constructor(private http: HttpClient) {
    this.initCache();
  }

  initCache() {
    this.status$ = this.http
      .get<Status>(this.statusUrl)
      .pipe(shareReplay(1));
  }

  getStatus(): Observable<Status> {
    return this.status$;
  }

  setPortalType(t: string): void {
    let nr = window.location.hostname.substring(0,4);
    sessionStorage.setItem("portalType" + nr,t);
  }

  getPortalType(): string {
    let nr = window.location.hostname.substring(0,4);
    return sessionStorage.getItem("portalType" + nr);
  }

  isReferralModuleActive(): Observable<boolean> {
    return this.status$.pipe(map((status) =>
      status.modules?.includes("REFERRAL") || status.modules?.includes("REFERRAL_COLLABORATION"))
    )
  }
}
