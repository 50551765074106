<div class="row">
  <div class="col">
    <p><strong *ngIf="selectedSchoolYear.schools.length === 0">Kies hieronder het schooljaar waarvoor u uw kind wilt aanmelden.</strong></p>
    <p><strong *ngIf="selectedSchoolYear.schools.length > 0">Kies hieronder de school waar u uw kind voor wilt aanmelden.</strong></p>
  </div>
</div>

<div class="front-school-block">

  <div class="row" *ngIf="schoolYears.length >= 1"> <!-- TODO length > 5 -->
    <div [class]="schoolYears.length > 1 ? 'col-lg-6 col-md-6 col-sm-12' : 'col-sm-12'" *ngFor="let schoolYear of schoolYears">
      <div (click)="selectYear(schoolYear)" *ngIf="schoolYear.schoolYear" [ngClass]="{'yearselected' : schoolYear.schoolYear === selectedSchoolYear.schoolYear,
       'single-year-block' : true, 'yearunselected' : selectedSchoolYear.schools.length > 0 && selectedSchoolYear?.schoolYear !== schoolYear.schoolYear}">
        <h2 class="title">{{schoolYear.schoolYear}}</h2>
        <span class="address">
          <i *ngIf="schoolYear.isNewYear">aanmelding voor nieuw schooljaar</i>
          <i *ngIf="schoolYear.isInfluxYear">instroom in huidig schooljaar</i>
        </span>
        <br />
      </div>
    </div>
  </div>

  <div *ngIf="getSchools().length > 0">
    <app-home-select-school (schoolSelected)="goToSchool($event)" [schools]="filteredSchools"></app-home-select-school>
  </div>

</div>

