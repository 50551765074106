import { SignUp } from '../../shared/model/signup';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignUpService } from '../../shared/services/signup.service';
import { interval, Observable, Subscription } from 'rxjs';
import { ColorService } from '../../shared/services/color.service';
import { Page } from '../../shared/model/page';
import { PageService } from '../../shared/services/page.service';
import { startWith, switchMap } from "rxjs/operators";
import { StatusService } from '../../shared/services/status.service';
import { WorkflowService } from '../../shared/services/workflow.service';

@Component({
  templateUrl: './bedankt.component.html',
  styles: ['div.alert { margin-top: 20px; }']
})
export class BedanktComponent implements OnInit, OnDestroy {

  pollingInterval: Subscription;
  nrPolls: number = 0;
  MAX_POLLS: number = 5;
  pdfReady: boolean = false;
  pdfError: boolean = false;

  page: Page;
  signUp: SignUp;

  btnTextNext: string = '<i class="fas fa-file-pdf"></i> Download de aanmelding als PDF';
  btnTextWait: string = '<i class="fas fa-spinner fa-spin"></i> Bezig met downloaden...';
  btnTextValue: string = this.btnTextNext;
  btnClickAllowed: boolean = true;

  public portalType: string;
  public workflowId: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private signUpService: SignUpService,
              private statusService: StatusService,
              private pageService: PageService,
              private workflowService: WorkflowService,
              private colorService: ColorService) { }

  ngOnInit() {
    this.signUp = this.signUpService.getSignUp();

    // Get portalType, for PO the workflow id needs to be present to check and download pdf.
    this.statusService.getStatus().subscribe((status) => {
      this.portalType = status.portalType;

      if (this.portalType === 'PO') {
        this.workflowService.getWorkflowBySignupId(this.signUp).subscribe((workflow) => {
          this.workflowId = workflow.id
        })
      }
    });

    this.pageService
      .getPage((this.signUp.school.choice === null || this.signUp.school.choice === 1) ? 'bedankt_eerste_keuze' : 'bedankt_tweede_keuze')
      .subscribe((page: Page) => {
       this.page = page;
    });
    setTimeout(()=> {
      this.pollingInterval = interval(2000)
        .pipe(
          startWith(0),
          switchMap(() => this.checkPdf())
        ).subscribe(res => {
          this.nrPolls++;
          this.pdfReady = res;
          if (res == true) {
            this.btnTextValue = this.btnTextNext;
            this.stopPolling()
          }
          else if (this.nrPolls >= this.MAX_POLLS) {
            this.stopPolling()
            this.pdfError = true;
          }
        });
    },2000);
  }

  ngOnDestroy() {
    this.stopPolling();
  }

  getPdf(): void {
    this.changeButtonText();

    this.getPdfAsync().subscribe(() => {
      this.changeButtonText();
    });
  }

  goToHome() {
    this.colorService.changeColor('common');
    this.router.navigate(['/home']);
  }

  changeButtonText(): void {
    this.btnClickAllowed = !this.btnClickAllowed;

    this.btnTextValue === this.btnTextNext ? this.btnTextValue = this.btnTextWait : this.btnTextValue = this.btnTextNext;
  }

  /**
   * Method to get the pdf, from signup (for VO schools), or from workflow (for PO schools)
   */
  getPdfAsync(): Observable<boolean> {
    return new Observable(observer => {

      if (this.portalType === 'VO') {
        this.signUpService.getPdf(this.signUp.id);
      } else {
        this.workflowService.getSignUpPdf(this.workflowId);
      }

      window.setTimeout(() => {
        observer.next();
        observer.complete();
      }, 3000);
    });
  }

  /**
   * Method to check whether the pdf is present, in signup (for VO schools), or in workflow (for PO schools)
   */
  checkPdf(): Observable<boolean> {
    if (this.portalType === 'VO') {
      return this.signUpService.checkPdf(this.signUp.id);
    } else {
      return this.workflowService.checkSignupPdf(this.workflowId);
    }
  }

  stopPolling(): void {
    if (this.pollingInterval) {
      this.pollingInterval.unsubscribe();
    }
  }

}
