import { FamilyDynamicComponent } from './modules/family-dynamic/family-dynamic.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { NotfoundComponent } from './modules/notfound/notfound.component';
import { BedanktComponent } from './modules/bedankt/bedankt.component';
import { IndexComponent } from './modules/index/index.component';
import { AuthGuard } from './shared/guards/AuthGuard';
import { StudentDynamicComponent } from "./modules/student-dynamic/student-dynamic.component";
import { SchoolDynamicComponent } from "./modules/school-dynamic/school-dynamic.component";
import { ParentDynamicComponent } from "./modules/parent-dynamic/parent-dynamic.component";
import { SignDynamicComponent } from "./modules/sign-dynamic/sign-dynamic.component";
import { PreviousSchoolDynamicComponent } from "./modules/previous-school-dynamic/previous-school-dynamic.component";
import { ParticularitiesDynamicComponent } from "./modules/particularities-dynamic/particularities-dynamic.component";
import { AdditionalDynamicComponent } from "./modules/additional-dynamic/additional-dynamic.component";
import { PageComponent } from './modules/page/page.component';
import {PoStudentDynamicComponent} from "./modules/po-student-dynamic/po-student-dynamic.component";
import {PoParentDynamicComponent} from "./modules/po-parent-dynamic/po-parent-dynamic.component";
import {
  PoSignAanmeldingDynamicComponent
} from "./modules/po-sign-aanmelding-dynamic/po-sign-aanmelding-dynamic.component";
import {PoBedanktComponent} from "./modules/po-bedankt/po-bedankt.component";
import {DirectLinkComponent} from "./modules/direct-link/direct-link.component";
import {PoCustomIntakeDynamicComponent} from "./modules/po-intake/po-custom-intake-dynamic.component";
import {PoSignIntakeDynamicComponent} from "./modules/po-intake/po-sign-intake-dynamic.component";

export const appRoutes: Routes = [
  { path: 'faq', component: PageComponent},
  { path: 'contact', component: PageComponent},
  { path: 'privacy', component: PageComponent},
  { path: 'school', component: SchoolDynamicComponent, canActivate: [AuthGuard]},
  { path: 'leerling', component: StudentDynamicComponent, canActivate: [AuthGuard]},
  { path: 'verzorger', component: ParentDynamicComponent, canActivate: [AuthGuard]},
  { path: 'gezin', component: FamilyDynamicComponent, canActivate: [AuthGuard] },
  { path: 'vorigeschool', component: PreviousSchoolDynamicComponent, canActivate: [AuthGuard] },
  { path: 'bijzonderheden', component: ParticularitiesDynamicComponent, canActivate: [AuthGuard] },
  { path: 'aanvullend', component: AdditionalDynamicComponent, canActivate: [AuthGuard] },
  { path: 'ondertekening', component: SignDynamicComponent, canActivate: [AuthGuard] },
  { path: 'bedankt', component: BedanktComponent, canActivate: [AuthGuard] },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'home/f/:fid', component: HomeComponent, canActivate: [ AuthGuard] },
  { path: '', component: IndexComponent },
  { path: 'dl/:dl', component: DirectLinkComponent },
  { path: 'po/aanmelding/leerling', component: PoStudentDynamicComponent, canActivate: [AuthGuard]},
  { path: 'po/aanmelding/verzorger', component: PoParentDynamicComponent, canActivate: [AuthGuard]},
  { path: 'po/aanmelding/ondertekening', component: PoSignAanmeldingDynamicComponent, canActivate: [AuthGuard]},
  { path: 'po/intake/stap/ondertekening', component: PoSignIntakeDynamicComponent, canActivate: [AuthGuard]},
  { path: 'po/intake/stap/:index', component: PoCustomIntakeDynamicComponent, canActivate: [AuthGuard]},
  { path: 'po/bedankt', component: PoBedanktComponent, canActivate: [AuthGuard] },
  { path: '**', pathMatch: 'full', component: NotfoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { useHash: false})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
