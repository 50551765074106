import { Directive, Input } from '@angular/core';

import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appCheckOWN]',
  providers: [{provide: NG_VALIDATORS, useExisting: OwnValidator, multi: true}]
})
export class OwnValidator implements Validator {
  @Input() appCheckOWN: string;

  validate(control: AbstractControl): {[key: string]: any} | null {
    const value = typeof control.value === 'string' ? control.value : String(control.value);

    if (!value) {
      return null;
    }

    //Check lengte en begin cijfer, own begint met een 1.
    if (value.length < 9 || value[0] != "1") { //TODO < 9 is anders dan own.validator != 9.
      return {
        'appCheckOWN': {value: value}
      };
    }

    let sum = 0;
    for (let i = 0; i < 8; i++) {
      sum += (9 - i) * parseInt(value[i]);
    }

    if (((sum % 11) === (parseInt(value[8]) + 5))) {
      return null;
    } else {
      return {
        'appCheckOWN': {value: value}
      };
    }
  }
}
