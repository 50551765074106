import {Component, Input, OnInit} from '@angular/core';
import {SignUp} from "../../../shared/model/signup";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../../shared/services/notification.service";
import {FormService} from "../../../shared/services/form.service";
import {SchoolService} from "../../../shared/services/school.service";
import {ColorService} from "../../../shared/services/color.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {SignUpService} from "../../../shared/services/signup.service";
import {WorkflowService} from "../../../shared/services/workflow.service";
import {BaseHomeOverviewComponent} from "../base-home/base-home-overview.component";
import {StatusService} from "../../../shared/services/status.service";
import {PageService} from "../../../shared/services/page.service";

@Component({
  selector: 'app-home-overview',
  templateUrl: './home-overview.component.html',
  styleUrls: ['../base-home/base-home-overview.component.css','./home-overview.component.css']
})
export class HomeOverviewComponent extends BaseHomeOverviewComponent implements OnInit {
  @Input()
  signUps: SignUp[] = [];

  @Input()
  formId: string;

  constructor(router: Router,
              route: ActivatedRoute,
              notificationService: NotificationService,
              formService: FormService,
              schoolService: SchoolService,
              colorService: ColorService,
              modalService: BsModalService,
              signUpService: SignUpService,
              workflowService: WorkflowService,
              statusService: StatusService,
              pageService: PageService) {
    super(router,route,notificationService,formService,schoolService,colorService,modalService,signUpService,workflowService,statusService,pageService)
    super.setFormId(this.formId);
  }

  ngOnInit() {
    this.checkPdfs();
  }

  pdfReady(signUp: SignUp) {
    this.getSignUpService().checkPdf(signUp.id).subscribe(res => signUp.pdfReady = res);
  }
  checkPdfs(): void {
    this.signUps.forEach((s) => {
      if (s.isCompleted || (s.isCompleted && !s.pdfReady)) this.pdfReady(s);
    });
  }


  getPdf(signUpId: string): void {
    this.getSignUpService().getPdf(signUpId);
  }
  delete(signUpId: string): void {
    this.getSignUpService().delete(signUpId).subscribe((data) => {
      this.signUps = data;
      this.checkPdfs();
      this.modalRef.hide();
      this.getNotificationService().success("", "De aanmelding is verwijderd!")
    }, (err) => {
      if(err.status == 404) {
        this.getNotificationService().signupNotFoundError();
      }
    });
  }

}
