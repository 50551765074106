<div [hidden]="!page">
  <div class="row">
    <div class="col-sm-12">
      <h1>{{page?.title}}</h1>
      <hr />
      <div [innerHtml]="page?.body | ngxSafeHtml" ></div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12">
      <div class="flex-navigation" *ngIf="!pdfReady && !pdfError">
        <i class="fas fa-spinner fa-spin"></i> uw aanmelding wordt verwerkt een moment geduld alstublieft...
      </div>
      <div class="flex-navigation"*ngIf="pdfReady">
        <span (click)="getPdf()"
              [class]="!btnClickAllowed ? 'span-disabled nav-button-next nav-button-next-only pdf-button-download' : 'nav-button-next nav-button-next-only pdf-button-download'"
              [innerHTML]="btnTextValue"></span>
      </div>
      <div class="alert alert-warning" role="alert" *ngIf="pdfError">
        <p>Er is een probleem opgetreden bij het genereren van een PDF bestand voor de aanmelding. Neem contact op met de school.</p>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-4">
      <div class="flex-navigation">
        <span (click)="logOut($event)" class="nav-button-next nav-button-next-only bedankt-buttons"><i class="fas fa-sign-out-alt"></i>Uitloggen</span>
      </div>
    </div>
    <div class="col-sm-4">
      <div class="flex-navigation">
        <span (click)="goToHome()" class="nav-button-prev bedankt-buttons"><i class="fas fa-home"></i>Terug naar Home</span>
      </div>
    </div>
  </div>
</div>
