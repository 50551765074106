import {AbstractControl} from "@angular/forms";

export function ValidateIBAN(control: AbstractControl) {
  const value = typeof control.value === 'string' ? control.value.replace(/\s*/g, '') : control.value;
  if (!value) {
    return null;
  }

  let parsedValue: string = String(value);
  // iban should be at least 15 characters long
  if (parsedValue.length < 15) {
    return { invalidIban: true };
  }

  // test for illegal characters
  const regexp = new RegExp('^[a-zA-Z0-9]+$');
  if (!regexp.test(parsedValue)) {
    return { invalidIban: true };
  }

  // move the first four characters to the back and make sure everything is uppercase
  parsedValue = (parsedValue.substr(4) + parsedValue.substr(0, 4)).toUpperCase();
  let valueWithConvertedNumbers: string = '';
  for (let i = 0; i < parsedValue.length; i++) {
    const character: number = parsedValue.charCodeAt(i);

    // If the character is A-Z, we need to convert it to a number from 10-35
    if (character > 64 && character < 91) {
      valueWithConvertedNumbers += String(character - 55);
    }
    else {
      valueWithConvertedNumbers += String.fromCharCode(character);
    }
  }

  while (valueWithConvertedNumbers.length > 10) {
    const part = +valueWithConvertedNumbers.substring(0, 10);
    valueWithConvertedNumbers = (part % 97) + valueWithConvertedNumbers.substring(10);
  }

  const modulo = +valueWithConvertedNumbers % 97;//modulo(valueWithConvertedNumbers, 97);
  if (modulo !== 1) {
    return { invalidIban: true };
  }

  return null;
}



