import { Injectable } from '@angular/core';
import {FieldRule, FieldRuleSet} from "../model/formField";
import {SignUp} from "../model/signup";
import {FormGroup} from "@angular/forms";


@Injectable({ providedIn: 'root' })
export class RuleEvaluatorService {

  evaluateRuleSet(ruleSet: FieldRuleSet, data: any, formGroup: FormGroup): boolean {

    let results = [];
    for (var ruleOrGroup of ruleSet.rules) {
      results = this.evaluateRules(ruleOrGroup, data, formGroup, results);
    }

    // Check condition and determine if all conditions are met (depending of condition and/or).
    return ruleSet.condition === 'and'
        ? results.every(result => result)
        : results.some(result => result);
  }

  evaluateRules(fieldRule: FieldRule, data: any, formGroup: FormGroup, results: boolean[]): boolean[] {
    // If rule contains a group of rules
    if (fieldRule.field == undefined) {
      let resultsPerGroup = [];
      // For every rule in the group.
      for (const fr of fieldRule.rules) {
        resultsPerGroup = this.evaluateRules(fr, data, formGroup, resultsPerGroup);
      }
      // After evaluating every condition in group, add one boolean to the results list. Dependent of the condition (and/or)
      results.push(fieldRule.condition === 'and'
          ? resultsPerGroup.every(result => result)
          : resultsPerGroup.some(result => result))
    // If rule is a single rule
    } else {
      results.push(this.evaluateRule(fieldRule, data, formGroup));
    }

    return results;
  }


  private evaluateRule(rule: FieldRule, signUp: SignUp, formGroup: FormGroup): boolean {

    function findVal(object, key) {

      var value;
      Object.keys(object).some(function(k) {

        if (k === key) {
          value = object[k];
          return true;
        }
        // Wanneer object custom question is, zoek key als value van varName key. value key is de ingevulde waarde.
        if (k === "varName" && object.varName === key) {
          value = object.value
          return true;
        }

        if (object[k] && typeof object[k] === 'object') {
          value = findVal(object[k], key);
          return value !== undefined;
        }
      });
      return value;
    }

    if (rule.field in formGroup?.controls) {
      return this.evaluateRuleOnValue(rule, formGroup?.controls[rule.field].value);
    } else {
      return this.evaluateRuleOnValue(rule, findVal(signUp, rule.field))
    }
  }

  public evaluateRuleOnValue(rule: FieldRule, fieldValue: any): boolean {
    if (fieldValue === undefined || fieldValue === "") {
      return false;
    }

    switch (rule.type) {
        case 'number':
            return this.numberCheck(rule, Number(fieldValue));
        case 'boolean':
            return this.booleanCheck(rule, fieldValue);
        case 'category':
            return this.stringCheck(rule, fieldValue);
        case 'check':
            return this.checkCheck(rule, fieldValue);
        case 'string':
            return this.stringCheck(rule, fieldValue);
    }

  }

  public stringCheck(rule: FieldRule, value: string) {
    value = String(value)
    rule.value = String(rule.value)
    switch (rule.operator) {
      case 'bevat':
        return value?.toLowerCase().includes(rule.value.toLowerCase());
      case 'gelijk aan':
        return value?.toLowerCase() === rule.value.toLowerCase();
      case 'niet gelijk aan':
        return value?.toLowerCase() !== rule.value.toLowerCase();
      // Add more operators as required
      default:
        return false;
    }
  }

  public booleanCheck(rule: FieldRule, value: boolean) {
    switch (rule.operator) {
      case 'gelijk aan':
        return value === rule.value;
      // Add more operators as required
      default:
        return false;
    }
  }

  public checkCheck(rule: FieldRule, value: boolean) {
      value = Boolean(value)
      rule.value = Boolean(rule.value)
      switch (rule.operator) {
          case 'gelijk aan':
              return value === rule.value;
          default:
              return false;
      }
  }

  public numberCheck(rule: FieldRule, value: number) {
    value = Number(value)
    rule.value = Number(rule.value)
    switch (rule.operator) {
      case 'groter dan':
        return value > rule.value;
      case 'groter dan of gelijk aan':
        return value >= rule.value;
      case 'kleiner dan':
        return value < rule.value;
      case 'kleiner dan of gelijk aan':
        return value <= rule.value;
      case 'gelijk aan':
        return value === rule.value;
      case 'niet gelijk aan':
        return value !== rule.value;
      default:
        return false;
    }

  }

}
