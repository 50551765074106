import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Page } from '../model/page';

@Injectable()
export class PageService {

  private pageUrl = 'api/page/';  // URL to web api

  constructor(private http: HttpClient) { }

  getPage(label: string): Observable<Page> {
    const url = this.pageUrl + label;
    return this.http.get<Page>(url);
  }
}
