import {AbstractControl} from "@angular/forms";

export function ValidateVnum(control: AbstractControl) {
  const value = typeof control.value === 'string' ? control.value : String(control.value);

  if (!value) {
    return null;
  }

  if (value.length != 10) {
    return {
      'appCheckVnum': {value: value}
    };
  }

  const regex = /^\d+$/;
  if (regex.test(value)) {
    return null;
  } else {
    return {
      'appCheckVnum': {value: value}
    };
  }
}
