<div class="row" *ngIf="startSchools.length > 0">

  <div class="col-sm-12 schoolfilter" *ngIf="startSchools.length >= 9">
    <input
      type="input"
      [(ngModel)]="searchFilterText"
      (input)="getSchoolsBySearch()"
      placeholder="Zoek een school op schoolnaam of adres"
      class="form-control filter-school-blocks"
    />
  </div>

  <div class="col-lg-4 col-md-6 col-sm-12" *ngFor="let school of filteredSchools">
    <div class="single-school-block" (click)="selectSchool(school)" *ngIf="school.name">
      <h2 class="title" [style.color]="school.iconColor">{{school.name}}</h2>
      <span class="address" *ngIf="school.address && school.address.length > 0">
          <i class="fas fa-map-marker-alt" [style.color]="school.iconColor" aria-hidden="true"></i>
        {{school.address}}
        </span>
      <br *ngIf="school.address && school.address.length > 0" />
      <span class="phone" *ngIf="school.phonenumber">
          <i class="fas fa-phone" [style.color]="school.iconColor" aria-hidden="true"></i>
        {{school.phonenumber}}
        </span>
      <br />
      <br />
    </div>
  </div>
</div>
