<div class="row">
  <div class="col-sm-12">
    <h1>{{page.title}}</h1>
    <div [innerHtml]="page.body | ngxSafeHtml" ></div>
  </div>
</div>
<div class="row" *ngIf="status?.portalOpen">
  <div class="col-sm-12">
    <div class="flex-navigation">
      <span (click)="login()" class="nav-button-next-only"><i class="fas fa-sign-in-alt"></i> Inloggen of registreren</span>
    </div>
  </div>
</div>


