export interface SchoolConfiguration {
  id: string;
  schoolRoleId: string;
  schoolYear: string;
  isInflux: boolean;
  name: string;
  address: string;
  phonenumber: string;
//  iconLabel: string;
  iconColor: string;
  isEnabled: boolean;
  bsnCheckOnPool: boolean;
  isVisible: boolean;
  lasType: LasType;
  locations: SchoolLocation[];
  formId: string;
  registrationFormId: string;
  stylingId: string;
  directLink: string;
}

export interface SchoolLocation {
  name: string;
  educationType: EducationType[];
  educationAdviceLevel: AdviceLevel[];
}

export enum LasType {
  Magister = 'Magister',
  Somtoday = 'Somtoday'
}

export interface EducationType {
  label: string;
  informationText: string;
  years: Year[];
}

export interface Year {
  year: number;
  levels: Level[];
}

export interface AdviceLevel {
  label: string;
}

export interface Level {
  label: string;
  hasLwoo: boolean;
  profiles: Profile[];
  lessons: Lesson[];
}

export interface Profile {
  label: string;
}

export interface Lesson {
  label: string;
}
