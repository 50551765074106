import { FormField } from "./formField";

export interface FormSection {
  id: string;
  label: string;
  title: string;
  introduction: string;
  enabled: boolean;
  condition: ConditionType;
  type: SectionType;
  fields: FormField[];
}

export enum SectionType {
  SCHOOL = "SCHOOL",
  CHOICE = "CHOICE",
  STUDENT = "STUDENT",
  PARENT = "PARENT",
  FAMILY = "FAMILY",
  PREVIOUSSCHOOL = "PREVIOUSSCHOOL",
  PARTICULARITIES = "PARTICULARITIES",
  ADDITIONAL = "ADDITIONAL",
  SIGN = "SIGN",
  CUSTOM = "CUSTOM",
  CUSTOM1 = "CUSTOM1",
  CUSTOM2 = "CUSTOM2",
  CUSTOM3 = "CUSTOM3",
  CUSTOM4 = "CUSTOM4",
  CUSTOM5 = "CUSTOM5",
  CUSTOM6 = "CUSTOM6",
  CUSTOM7 = "CUSTOM7",
  CUSTOM8 = "CUSTOM8",
  CUSTOM9 = "CUSTOM9",
}

export enum FormType {
  PO_APPLICATION = "PO_APPLICATION",
  PO_INTAKE = "PO_INTAKE",
  PO_SIGNUP = "PO_SIGNUP",
  SIGNUP = "SIGNUP"

}

export enum ConditionType {
  FIRST_YEAR = "FIRST_YEAR"
}
