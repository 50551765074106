import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {SignUpService} from '../../services/signup.service';
import {SignUp} from '../../model/signup';
import {SchoolService} from '../../services/school.service';
import {SchoolConfiguration} from '../../model/schoolConfiguration';
import {FormService} from "../../services/form.service";
import {FormDefinition} from "../../model/formDefinition";
import {FormType, SectionType} from "../../model/formSection";

@Component({
  selector: 'app-stappenplan',
  templateUrl: './app-stappenplan.component.html'
})
export class AppStappenplanComponent {

  signUp: SignUp;
  school: SchoolConfiguration;
  form: FormDefinition;

  constructor(private router: Router,
              private signUpService: SignUpService,
              private formService: FormService,
              private schoolService: SchoolService) {
    this.signUp = this.signUpService.getSignUp();
    this.school = this.schoolService.getSchool();
    this.form = this.formService.getForm();
  }

  linkEquals(type: SectionType) {
    if (this.formService.getFormType() === 'PO_INTAKE') {
      return (this.linkEqualsPo(type));
    }
    return this.router.url === AppStappenplanComponent.getLinkForSection(this.formService.getFormType(),type);
  }

  linkEqualsPo(type: SectionType) {
    if (type === 'SIGN') {
      return this.router.url === AppStappenplanComponent.getLinkForCustomSection(type, null);
    }
    let stepIndex = this.form.sections.findIndex(section => section.type === type) + 1
    return this.router.url === AppStappenplanComponent.getLinkForCustomSection(type, stepIndex);
  }

  public static getLinkForCustomSection(type: SectionType, index: number): string {
    if (type === 'SIGN') {
      return '/po/intake/stap/ondertekening';
    }
    return '/po/intake/stap/' + index;
  }

  public static getLinkForSection(formType: FormType, type: SectionType): string {
    switch (formType) {
      case FormType.SIGNUP:
        switch (type) {
          case SectionType.SCHOOL : return '/school'
          case SectionType.CHOICE: return '/keuze'
          case SectionType.STUDENT: return '/leerling'
          case SectionType.PARENT: return '/verzorger'
          case SectionType.FAMILY: return '/gezin'
          case SectionType.PREVIOUSSCHOOL: return '/vorigeschool'
          case SectionType.PARTICULARITIES: return '/bijzonderheden'
          case SectionType.ADDITIONAL: return '/aanvullend'
          case SectionType.SIGN: return '/ondertekening'
          default: return '/home'
        }
      case FormType.PO_APPLICATION:
        switch (type) {
          case SectionType.STUDENT: return '/po/aanmelding/leerling'
          case SectionType.PARENT: return '/po/aanmelding/verzorger'
          case SectionType.SIGN: return '/po/aanmelding/ondertekening'
          default: return '/home'
        }
      default: return '/home'
    }
  }
}
