import {Component, Input, OnInit} from '@angular/core';
import {SchoolYear} from "../../../shared/model/schoolYear";
import {SchoolYearService} from "../../../shared/services/schoolYear.service";
import {SchoolConfiguration} from "../../../shared/model/schoolConfiguration";
import {SchoolService} from "../../../shared/services/school.service";
import {FormService} from "../../../shared/services/form.service";
import {ColorService} from "../../../shared/services/color.service";
import {Router} from "@angular/router";
import {BehaviorSubject, Observable} from "rxjs";
import {StatusService} from "../../../shared/services/status.service";

@Component({
  selector: 'app-home-start',
  templateUrl: './home-start.component.html',
  styleUrls: ['../base-home/base-home-start.component.css']
})
export class HomeStartComponent implements OnInit {

  @Input()
  formId: string;

  public schoolYears: SchoolYear[] = [];

  public selectedSchoolYear = {
    schools: []
  } as SchoolYear;

  private _data: BehaviorSubject<SchoolConfiguration[]> = new BehaviorSubject<SchoolConfiguration[]>([]);

  constructor(
    private router: Router,
    private schoolService: SchoolService,
    private formService: FormService,
    private colorService: ColorService,
    private schoolYearService: SchoolYearService,
    private statusService: StatusService,
  ) {}

  ngOnInit() {
    this.getSchoolYears();
  }

  public get filteredSchools(): Observable<SchoolConfiguration[]>{
    return this._data.asObservable();
  }

  getSchoolYears(): void {
    this.schoolYearService.getSchoolYears().subscribe((data) => {
      this.schoolYears = data;
      if (this.schoolYears.length == 1) {
        this.selectedSchoolYear = this.schoolYears[0];
        this.updateSchools();
      }
    });
  }

  updateSchools(): void {
    this._data.next(this.getSchools());
  }

  getSchools(): any {
    if (this.schoolYears.length > 0) {
      if (this.selectedSchoolYear) {
        return this.schoolService.filterSchoolsByIsVisibleIsTrue(this.selectedSchoolYear.schools);
      }
    }
    return [];
  }

  onSchoolSelected($event) {
    this.goToSchool($event)
  }

  selectYear(schoolYear: SchoolYear): void {
    this.selectedSchoolYear = schoolYear;
    this.updateSchools();
  }

  goToSchool(schoolId: string) {
    this.statusService.getStatus().pipe().subscribe((s) => {
      if (s.portalType === 'VO' && this.statusService.isReferralModuleActive()) {
        this.schoolService.getReferralSchools();
      }
    })
    this.schoolService.getSchoolById(schoolId).subscribe((s) => {
      s.isInflux = this.selectedSchoolYear.isInfluxYear;
      this.schoolService.setSchool(s);
      this.colorService.changeColor(s.stylingId);
      this.formService.getFormById(this.formId ? this.formId : s.formId).subscribe(form => {
        this.formService.setForm(form);
        this.router.navigate(['/school']);
      });
    })
  }
}
