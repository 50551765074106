import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { BagAddress } from '../model/addresses';
import { CountryCode } from '../model/countrycode';
import { CareTakers, Parent, PaymentInformation, PhoneNumber, SignUp, Student } from '../model/signup';
import { AddressService } from '../services/address.service';
import { CountryService } from '../services/country.service';
import { SignUpService } from "../services/signup.service";
import { TextFormatService } from "../services/textformat.service";
import { SchoolService } from '../services/school.service';
import { LasType } from '../model/schoolConfiguration';
import { DynamicSectionComponent } from "./dynamic-section-component";
import { FormService } from "../services/form.service";
import {FormType, SectionType} from "../model/formSection";
import { ConditionType, FormField, SelectOption } from "../model/formField";
import { Postalcodelookup } from '../model/postalcodelookup';
import { NotificationService } from '../services/notification.service';
import { WorkflowService } from '../services/workflow.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { tap } from "rxjs/operators";
import {RuleEvaluatorService} from "../services/rule-evaluator.service";
import {ScrollService} from "../services/scroll.service";

export class ParentDynamicComponent extends DynamicSectionComponent {

  signUp: SignUp;
  vLasType = LasType;

  postalcodelookup: Postalcodelookup = {} as Postalcodelookup;

  caretakers: CareTakers = {
    secondCareTaker: true,
    thirdCareTaker: false,
    parentOne: {
      sameAddressAsStudent: true,
      relationToStudent: '',
      sex: '',
      phoneNumber: {
        isSecret: ''
      } as PhoneNumber,
      mobileNumber: {
        isSecret: ''
      } as PhoneNumber,
    } as Parent,
    parentTwo: {
      sameAddressAsStudent: true,
      relationToStudent: '',
      sex: '',
      phoneNumber: {
        isSecret:''
      } as PhoneNumber,
      mobileNumber: {
        isSecret:''
      } as PhoneNumber,
    } as Parent,
    parentThree: {
      sameAddressAsStudent: true,
      relationToStudent: '',
      sex: '',
      phoneNumber: {
        isSecret:''
      } as PhoneNumber,
      mobileNumber: {
        isSecret: ''
      } as PhoneNumber,
    } as Parent,
    paymentInformation: {} as PaymentInformation
  } as CareTakers;

  careTakersFromSignUp = false;

  countryCodes: CountryCode[];

  constructor(router: Router,
              formService: FormService,
              notificationService: NotificationService,
              ruleEvaluatorService: RuleEvaluatorService,
              private formBuilder: FormBuilder,
              private signUpService: SignUpService,
              private workflowService: WorkflowService,
              private addressService: AddressService,
              private countryService: CountryService,
              private schoolService: SchoolService,
              private textFormatService: TextFormatService,
              formType: FormType,
              modalService: BsModalService,
              scrollService: ScrollService) {
    super(router,formService, notificationService, modalService, ruleEvaluatorService, scrollService, SectionType.PARENT, FormType.SIGNUP)
    this.setFormType(formType);

    this.signUp = this.signUpService.getSignUp();

    this.countryService.getCountries().subscribe((countries) => {
      this.countryCodes = countries;
      this.setCountryOptions();
    });

    this.caretakers = this.getCareTakers();
    this.checkNullCaretakers();
    this.formGroup = this.formBuilder.group({});
  }

  getStudent() {
    if (this.isWorkflowFormType()) {
      this.workflowService.getRegistration()?.student;
    } else {
      this.signUpService.getSignUp().student;
    }

    return this.isWorkflowFormType() ? this.workflowService.getRegistration()?.student : this.signUpService.getSignUp().student
  }

  getCareTakers() {
    if (this.careTakersFromSignUp) {
      return this.caretakers
    }
    this.careTakersFromSignUp = true;

    if (this.isWorkflowFormType()) {
      if (this.workflowService.getWorkflow() != null && this.workflowService.getRegistration() != null) {
        let ct = this.workflowService.getRegistration().careTakers
        if (ct !== undefined && ct !== null) {
          return ct;
        }
        else {
          return this.caretakers;
        }
      }
    }
    else {
      if (this.signUp.careTakers !== undefined && this.signUp.careTakers !== null) {
        return this.signUp.careTakers;
      }
    }
    return null;
  }

  checkNullCaretakers() {
    if (this.caretakers.parentTwo === null) {
      this.caretakers.parentTwo = this.getNewParent();
    }
    if (this.caretakers.parentThree === null) {
      this.caretakers.parentThree = this.getNewParent();
    }
  }

  genericNgOnInit() {
    super.setSchool(this.schoolService.getSchool());
    let s = this.getStudent()
    if (s) {
      this.checkSameAddressParentOne();

      if (this.caretakers.secondCareTaker && this.caretakers.parentTwo.sameAddressAsStudent == true) {
        this.checkSameAddressParentTwo();
      }
      if (this.caretakers.thirdCareTaker && this.caretakers.parentThree.sameAddressAsStudent == true) {
        this.checkSameAddressParentThree();
      }
    }
    super.commonNgOnInit();
    if (!this.getSection()) return;

    this.getSection().fields.forEach(fld => {
      this.addCallbackFunctions(fld);
      this.checkControl(fld, this.caretakers, this.signUp);
    });

    // if (this.formGroup.controls[parent+'.country']) {
    if (this.caretakers.parentOne.sameAddressAsStudent == true && this.formGroup.controls['parentOne.sameAddressAsStudent']) {
      this.addAddress('parentOne');
    }
    if (this.caretakers.secondCareTaker && this.caretakers.parentTwo.sameAddressAsStudent == true
      && this.formGroup.controls['parentTwo.sameAddressAsStudent']) {
      this.addAddress('parentTwo');
    }
    if (this.caretakers.thirdCareTaker && this.caretakers.parentThree.sameAddressAsStudent == true
      && this.formGroup.controls['parentThree.sameAddressAsStudent']) {
      this.addAddress('parentThree');
    }

    if (this.formGroup.controls['parentOne.country']) {
      this.setCountryChangeFunction('parentOne')
    }
    if (this.formGroup.controls['parentTwo.country']) {
      this.setCountryChangeFunction('parentTwo')
    }
    if (this.formGroup.controls['parentThree.country']) {
      this.setCountryChangeFunction('parentThree')
    }
    this.checkFacturationOptions();
  }

  setCountryChangeFunction(parent: string): void {
    if (this.formGroup.controls[parent+'.country']) {
      this.formGroup.controls[parent+'.country'].valueChanges.subscribe(value => {
        if (value == 'Nederland') {
          switch(parent) {
            case "parentOne":
              this.getFieldByVarName(parent+'.postalCode').callbackFn = this.checkPostalCodeParentOne;
              this.getFieldByVarName(parent+'.houseNumber').callbackFn = this.checkPostalCodeParentOne;
              break;
            case "parentTwo":
              this.getFieldByVarName(parent+'.postalCode').callbackFn = this.checkPostalCodeParentTwo;
              this.getFieldByVarName(parent+'.houseNumber').callbackFn = this.checkPostalCodeParentTwo;
              break;
            case "parentThree":
              this.getFieldByVarName(parent+'.postalCode').callbackFn = this.checkPostalCodeParentThree;
              this.getFieldByVarName(parent+'.houseNumber').callbackFn = this.checkPostalCodeParentThree;
              break;
          }

        }
        else {
          this.getFieldByVarName(parent+'.postalCode').callbackFn = null;
          this.getFieldByVarName(parent+'.houseNumber').callbackFn = null;
        }
        this.setPostalCodeValidator(parent+'.postalCode',value)
      });
    }
  }

  addCallbackFunctions(fld: FormField): void {
    switch (fld.varName) {
      case 'secondCareTaker':
        fld.changeFn = this.onSecondCareTakerChange;
        break;
      case 'thirdCareTaker':
        fld.changeFn = this.onThirdCareTakerChange;
        break;
      case 'parentOne.lastName':
      case 'parentOne.prefix':
      case 'parentOne.initials':
        fld.changeFn = this.checkInitialsParentOne;
        break;
      case 'parentOne.emailAddress':
      case 'parentTwo.lastName':
      case 'parentTwo.prefix':
      case 'parentTwo.initials':
        fld.changeFn = this.checkInitialsParentTwo;
        break;
      case 'parentTwo.emailAddress':
      case 'parentThree.lastName':
      case 'parentThree.prefix':
      case 'parentThree.initials':
        fld.changeFn = this.checkInitialsParentThree;
        break;
      case 'parentThree.emailAddress':
        fld.changeFn = this.onBillableCaretakerChange;
        break;
      case 'parentOne.sameAddressAsStudent':
        fld.changeFn = this.checkAddressParentOne;
        break;
      case 'parentTwo.sameAddressAsStudent':
        fld.changeFn = this.checkAddressParentTwo;
        break;
      case 'parentThree.sameAddressAsStudent':
        fld.changeFn = this.checkAddressParentThree;
        break;
      case 'paymentInformation.billableCaretaker':
        fld.changeFn = this.onBillableCaretakerChange;
    }
    if (this.countryIsNl(this.caretakers.parentOne)) {
      if (fld.varName == 'parentOne.postalCode' || fld.varName == 'parentOne.houseNumber') {
        fld.callbackFn = this.checkPostalCodeParentOne;
      }
    }
    if (this.countryIsNl(this.caretakers.parentTwo)) {
      if (fld.varName == 'parentTwo.postalCode' || fld.varName == 'parentTwo.houseNumber') {
        fld.callbackFn = this.checkPostalCodeParentTwo;
      }
    }
    if (this.countryIsNl(this.caretakers.parentThree)) {
      if (fld.varName == 'parentThree.postalCode' || fld.varName == 'parentThree.houseNumber') {
        fld.callbackFn = this.checkPostalCodeParentThree;
      }
    }
  }

  checkCondition(fld: FormField): boolean {
    let checkOk = true;
    fld.condition.forEach(condition => {
      switch (condition) {
        case ConditionType.SECOND_CARETAKER:
          if (this.caretakers.secondCareTaker.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.THIRD_CARETAKER:
          if (this.caretakers.thirdCareTaker.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.LAS_MAGISTER:
          if (this.school.lasType !== this.vLasType.Magister) {
            fld.conditionalDisabled = true;
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.LAS_SOMTODAY:
          if (this.school.lasType !== this.vLasType.Somtoday) {
            fld.conditionalDisabled = true;
            checkOk = false;
            return false;
          }
          break;
      }
    })
    return checkOk;
  }

  setCountryOptions(): void {
    if (!this.getSection()) return;
    let fld = this.getFieldByVarName('parentOne.country');
    fld.options$ = this.countryService.getCountriesOptions();
    fld = this.getFieldByVarName('parentTwo.country');
    if (fld) {
      fld.options$ = this.countryService.getCountriesOptions();
    }
    fld = this.getFieldByVarName('parentThree.country');
    if (fld) {
      fld.options$ = this.countryService.getCountriesOptions();
    }
  }

  checkFieldsForParent(parent: string) {
    this.getCurrentSection().fields.forEach(fld => {
      if (fld.varName.indexOf(parent) >= 0) {
        this.checkControl(fld, this.caretakers, this.signUp);
      }
    })
  }

  /**
   * bij 1 ouder Verzoger 1 activeren als persoon voor facturatie en de accountname vast voorinvullen.
   */
  onSecondCareTakerChange = (): void => {
    this.caretakers.secondCareTaker = 'true' == this.getFormValueByVarName('secondCareTaker');
    if (!this.caretakers.secondCareTaker) {

      this.caretakers.paymentInformation.billableCaretaker = 1;
      this.setFormValueByVarName('paymentInformation.billableCaretaker',this.caretakers.paymentInformation.billableCaretaker);
      this.setAccountHolderNameForParent(1);
      this.setFacturationEmail();
      this.setHideLabelForField('title.parentTwo',true);
      this.caretakers.thirdCareTaker = false;

      if (this.formGroup.controls['thirdCareTaker']) {
        this.formGroup.controls['thirdCareTaker'].setValue("false");
        this.onThirdCareTakerChange();
      }
    }
    else {
      if (!this.caretakers.parentTwo) {
        this.caretakers.parentTwo = this.getNewParent();
      }
      this.setHideLabelForField('title.parentTwo',false);

      let parentThreeField = this.getFieldByVarName('thirdCareTaker');

      /**
       * in case of PO there is no option for a third parent
       */
      if (parentThreeField !== null) {
        this.checkControl(parentThreeField, this.caretakers, this.signUp);
        this.formGroup.controls['thirdCareTaker'].setValue("false");
        this.onThirdCareTakerChange();
      }
    }

    this.checkFacturationOptions();
    this.checkFieldsForParent('parentTwo');

    if (this.formGroup.controls['thirdCareTaker']) {
      this.checkControl(this.getFieldByVarName('thirdCareTaker'), this.caretakers, this.signUp);
      this.checkFieldsForParent('parentThree');
    }

    this.checkAddressParentTwo();

  }

  onThirdCareTakerChange = (): void => {
    this.caretakers.thirdCareTaker = 'true' == this.getFormValueByVarName('thirdCareTaker');
    if (!this.caretakers.thirdCareTaker) {
      this.caretakers.paymentInformation.billableCaretaker = 1;
      this.setFormValueByVarName('paymentInformation.billableCaretaker',this.caretakers.paymentInformation.billableCaretaker);
      this.setAccountHolderNameForParent(1);
      this.setFacturationEmail();
      this.setHideLabelForField('title.parentThree',true);
    }
    else {
      if (!this.caretakers.parentThree) {
        this.caretakers.parentThree = this.getNewParent();
      }
      this.setHideLabelForField('title.parentThree',false);
    }
    this.checkFieldsForParent('parentThree');
    let c =this.getFormValueByVarName('parentThree.country');
    if (!c) { c = 'Nederland' }
    this.getFieldByVarName('parentThree.sameAddressAsStudent').changeFn = this.checkAddressParentThree;
    this.setCountryChangeFunction('parentThree')
    this.setPostalCodeValidator('parentThree.postalCode',c)
    this.checkFacturationOptions();
    this.checkAddressParentThree();
  }

  checkInitialsParentOne = (): void => {
    this.checkInitials('parentOne');
  }

  checkInitialsParentTwo = (): void => {
    this.checkInitials('parentTwo');
  }

  checkInitialsParentThree = (): void => {
    this.checkInitials('parentThree');
  }

  checkInitials(parent: string): void {
    let uppercaseInitials: string = this.formGroup.controls[parent + '.initials']?.value?.toUpperCase();
    if (!uppercaseInitials) {
      return;
    }
    if (uppercaseInitials.length === 1) {
      uppercaseInitials += '.';
    }
    this.formGroup.controls[parent + '.initials'].setValue(uppercaseInitials);
  }

  getNewParent(): Parent {
    return {
        sameAddressAsStudent: true,
        relationToStudent: '',
        sex: '',
        phoneNumber: {
        isSecret:''
      } as PhoneNumber,
        mobileNumber: {
        isSecret: ''
      } as PhoneNumber,
    } as Parent
  }

  onBillableCaretakerChange = (): void => {
    this.caretakers.paymentInformation.billableCaretaker = +this.getFormValueByVarName('paymentInformation.billableCaretaker');
    this.setAccountHolderNameForParent(this.caretakers.paymentInformation.billableCaretaker);
    this.setFacturationEmail();
  }

  checkFacturationOptions(): void {
    let fld = this.getFieldByVarName('paymentInformation.billableCaretaker');
    if (!fld) return;
    if (this.caretakers.secondCareTaker.toString() == 'true') {
      if (fld.options.length < 2) {
        fld.options.push(new SelectOption("2",fld.options[0].text.replace("1","2")))
      }
    }
    else {
      fld.options.splice(1,fld.options.length-1)
    }
    if (this.caretakers.thirdCareTaker.toString() == 'true') {
      if (fld.options.length < 3) {
        fld.options.push(new SelectOption("3",fld.options[0].text.replace("1","3")))
      }
    }
    else {
      fld.options.splice(2,1)
    }
  }

  getCareTakerObjectNameByNumber(nr: number): string {
    switch (nr) {
      case 1: return 'parentOne';
      case 2: return 'parentTwo';
      case 3: return 'parentThree';
    }
    return 'parentOne';
  }

  setHideLabelForField(varName: string, val: boolean) {
    let fld = this.getFieldByVarName(varName);
    if (fld) {
      fld.conditionalDisabled = val;
      fld.hideLabel = val;
    }
  }
  checkAddressParentOne = (): void => {
    this.checkAddressParent('parentOne');
  }

  checkAddressParentTwo = (): void => {
    this.checkSameAddressParentTwo()
    this.checkAddressParent('parentTwo');
  }

  checkAddressParentThree = (): void => {
    this.checkSameAddressParentThree()
    this.checkAddressParent('parentThree');
  }

  checkAddressParent(parent: string) {
    let val = this.getFormValueByVarName(parent + '.sameAddressAsStudent');
    if (!val) return;
    if (val.toString().toLowerCase() === 'ja' || val.toString().toLowerCase() == 'true') {
      this.addAddress(parent);
    }
    else {
      this.removeAddress(parent);
    }
  }

  save(): boolean {
    if (!this.prepareSave(this.caretakers)) return false;

    this.caretakers.parentOne.postalCode = this.caretakers.parentOne.postalCode.replace(/\s+/g, '');

    if (this.caretakers.secondCareTaker && this.caretakers.secondCareTaker.toString() == 'true') {
      this.caretakers.parentTwo.postalCode = this.caretakers.parentTwo.postalCode.replace(/\s+/g, '');
    }
    else {
      this.caretakers.parentTwo = null;
      this.caretakers.parentThree = null;
    }
    if (this.caretakers.thirdCareTaker && this.caretakers.thirdCareTaker.toString() == 'true') {
      this.caretakers.parentThree.postalCode = this.caretakers.parentThree.postalCode.replace(/\s+/g, '');
    }
    else {
      this.caretakers.parentThree = null;
    }

    if (this.caretakers.paymentInformation.iban) {
      this.caretakers.paymentInformation.iban = this.caretakers.paymentInformation.iban.replace(/\s+/g, '');
      this.caretakers.paymentInformation.iban = this.caretakers.paymentInformation.iban.toUpperCase();
      if (this.caretakers.paymentInformation.sendToEmail) {
        this.caretakers.paymentInformation.sendToEmail = this.caretakers.paymentInformation.sendToEmail.toLowerCase();
      }
    }

    this.caretakers.parentOne.lastName = this.textFormatService.capitalizeFirstLetter(this.caretakers.parentOne.lastName);
    this.caretakers.parentOne.initials = this.textFormatService.formatInitials(this.caretakers.parentOne.initials);
    this.caretakers.parentOne.postalCode = this.caretakers.parentOne.postalCode.toUpperCase();
    this.caretakers.parentOne.emailAddress = this.caretakers.parentOne.emailAddress.toLowerCase();

    if (this.caretakers.secondCareTaker.toString() == 'true') {
      this.caretakers.parentTwo.lastName = this.textFormatService.capitalizeFirstLetter(this.caretakers.parentTwo.lastName);
      this.caretakers.parentTwo.initials = this.textFormatService.formatInitials(this.caretakers.parentTwo.initials);
      this.caretakers.parentTwo.postalCode = this.caretakers.parentTwo.postalCode.toUpperCase();
      if (this.caretakers.parentTwo.emailAddress) {
        this.caretakers.parentTwo.emailAddress = this.caretakers.parentTwo.emailAddress.toLowerCase();
      }
    }
    if (this.caretakers.thirdCareTaker.toString() == 'true') {
      this.caretakers.parentThree.lastName = this.textFormatService.capitalizeFirstLetter(this.caretakers.parentThree.lastName);
      this.caretakers.parentThree.initials = this.textFormatService.formatInitials(this.caretakers.parentThree.initials);
      this.caretakers.parentThree.postalCode = this.caretakers.parentThree.postalCode.toUpperCase();
      if (this.caretakers.parentThree.emailAddress) {
        this.caretakers.parentThree.emailAddress = this.caretakers.parentThree.emailAddress.toLowerCase();
      }
    }

    if (this.isWorkflowFormType()) {
      this.workflowService.saveCareTakers(this.caretakers).subscribe(
        () => {
          //this.workflowService.setRegistration(registration)
          this.isSubmitted = false;
          this.goToNextInWorkflow();
        }, (err) => {
          this.getRouter().navigate(['/home']);
          this.handleSaveError(err);
        }
      );
    }
    else {
      this.signUpService.saveCareTakers(this.caretakers)
        .pipe(
          tap({
            next: value => {
              this.isSubmitted = false;
              this.goToNext(this.signUpService.getSignUp());
            },
            error: err => {
              this.getRouter().navigate(['/home']);
              this.handleSaveError(err);
            }
          })
        ).subscribe();
    }
    return true;
  }

  setAccountHolderNameForParent(parent: number) {
    let caretakerStr = this.getCareTakerObjectNameByNumber(parent);
    let caretaker: Parent;
    switch(parent) {
      case 1: caretakerStr = 'parentOne'; break;
      case 2: caretakerStr = 'parentTwo'; break;
      case 3: caretakerStr = 'parentThree'; break;
    }

    let caretakerName = '';
    caretaker = this.caretakers[caretakerStr];

    caretaker.initials = this.getFormValueByVarName(caretakerStr + '.initials');
    if (caretaker.initials !== undefined && caretaker.initials) {
      caretakerName += caretaker.initials;
    }

    caretaker.prefix = this.getFormValueByVarName(caretakerStr + '.prefix');
    if (caretaker.prefix !== undefined && caretaker.prefix) {
      caretakerName += ' ' + caretaker.prefix;
    }

    caretaker.lastName= this.getFormValueByVarName(caretakerStr + '.lastName');
    if (caretaker.lastName !== undefined && caretaker.lastName) {
      caretakerName += ' ' + caretaker.lastName;
    }

    this.caretakers.paymentInformation.nameAccountHolder = caretakerName.trim();
    this.setFormValueByVarName('paymentInformation.nameAccountHolder',this.caretakers.paymentInformation.nameAccountHolder);
  }

  setFacturationEmail(): void {
    let objName = this.getCareTakerObjectNameByNumber(this.caretakers.paymentInformation.billableCaretaker);
    let email = this.getFormValueByVarName(objName + '.emailAddress')
    this.setFormValueByVarName('paymentInformation.sendToEmail',email)
  }

  checkPostalCodeParentOne = (): void => {
    this.checkPostalCode('parentOne');
  }

  checkPostalCodeParentTwo = (): void => {
    this.checkPostalCode('parentTwo');
  }

  checkPostalCodeParentThree = (): void => {
    this.checkPostalCode('parentThree');
  }

  /**
   * postcode lookup, returns address from bagdata
   * @param parentObj
   */
  checkPostalCode(parentObj: string) {
    let parent = this.caretakers[parentObj];

    const postalCode = this.getFormValueByVarName(parentObj + '.postalCode').replace(/\s*/g, '').toUpperCase();
    const houseNumberMatch = this.getFormValueByVarName(parentObj + '.houseNumber').match(/\d+/);

    if (postalCode.length !== 6 || !houseNumberMatch) {
      return;
    }

    this.postalcodelookup.postalCode = postalCode;
    this.postalcodelookup.houseNumber = Number(houseNumberMatch[0]);

    this.addressService.getAddresses(this.postalcodelookup).subscribe((data: BagAddress) => {
      if (data.streetName !== '') {
        parent.street = data.streetName;
        this.setFormValueByVarName(parentObj + '.street',parent.street);
      }
      if (data.cityName !== '') {
        parent.city = data.cityName;
        this.setFormValueByVarName(parentObj + '.city',parent.city);
      }
    });
  }

  checkSameAddressParentOne() {
    this.checkSameAddressForParent('parentOne');
  }

  checkSameAddressParentTwo() {
    this.checkSameAddressForParent('parentTwo');
  }

  checkSameAddressParentThree() {
    this.checkSameAddressForParent('parentThree');
  }

  checkSameAddressForParent(parent: string) {
    const student: Student = this.getStudent() as Student;
    if (student && student.postalCode) {
      if (this.caretakers[parent].sameAddressAsStudent == true) {
        this.caretakers[parent].postalCode = student.postalCode;
        this.caretakers[parent].houseNumber = student.houseNumber;
        this.caretakers[parent].houseNumberAddition = student.houseNumberAddition;
        this.caretakers[parent].street = student.street;
        this.caretakers[parent].country = student.country;
        this.caretakers[parent].city = student.city;
      }
    }

  }

  addAddress(parent: string) {
    const student = this.getStudent() as Student;
    if (student && student.country == undefined) {
      return;
    }
    this.setFormValueByVarName(parent + '.country',student.country);
    this.setFormValueByVarName(parent + '.postalCode',student.postalCode);
    this.setFormValueByVarName(parent + '.houseNumber',student.houseNumber);
    if (student.houseNumberAddition) {
      this.setFormValueByVarName(parent + '.houseNumberAddition',student.houseNumberAddition);
    }
    else {
      this.setFormValueByVarName(parent + '.houseNumberAddition','');
    }
    this.setFormValueByVarName(parent + '.street',student.street);
    this.setFormValueByVarName(parent + '.city',student.city);
    this.disableFormFieldByVarName(parent + '.city');
    this.disableFormFieldByVarName(parent + '.street');
    this.disableFormFieldByVarName(parent + '.houseNumber');
    this.disableFormFieldByVarName(parent + '.houseNumberAddition');
    this.disableFormFieldByVarName(parent + '.country');
    this.disableFormFieldByVarName(parent + '.postalCode');
    this.getFieldByVarName(parent + '.street').callbackFn = null;
    this.getFieldByVarName(parent + '.houseNumber').callbackFn = null;
    this.setPostalCodeValidator(parent+'.postalCode',this.getFormValueByVarName(parent + '.country'))
    this.setCountryChangeFunction(parent)
  }

  removeAddress(parent: string) {
    this.setFormValueByVarName(parent + '.country','Nederland');
    this.setFormValueByVarName(parent + '.postalCode','');
    this.setFormValueByVarName(parent + '.houseNumber','');
    this.setFormValueByVarName(parent + '.houseNumberAddition','');
    this.setFormValueByVarName(parent + '.street','');
    this.setFormValueByVarName(parent + '.city','');
    this.enableFormFieldByVarName(parent + '.city');
    this.enableFormFieldByVarName(parent + '.street');
    this.enableFormFieldByVarName(parent + '.houseNumber');
    this.enableFormFieldByVarName(parent + '.houseNumberAddition');
    this.enableFormFieldByVarName(parent + '.country');
    this.enableFormFieldByVarName(parent + '.postalCode');
    this.setPostalCodeValidator(parent+'.postalCode',this.getFormValueByVarName(parent + '.country'))
    this.setCountryChangeFunction(parent)
  }
}
