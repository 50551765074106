import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {interval, Observable, Subscription} from 'rxjs';
import {ColorService} from '../../shared/services/color.service';
import {Page} from '../../shared/model/page';
import {PageService} from '../../shared/services/page.service';
import {Workflow} from "../../shared/model/workflow";
import {WorkflowService} from "../../shared/services/workflow.service";
import {startWith, switchMap} from "rxjs/operators";

@Component({
  templateUrl: './po-bedankt.component.html',
  styles: ['div.alert { margin-top: 20px; }']
})
export class PoBedanktComponent implements OnInit, OnDestroy {
  pollingInterval: Subscription;
  nrPolls: number = 0;
  MAX_POLLS: number = 5;
  pdfReady: boolean = false;
  pdfError: boolean = false;

  page: Page;
  workflow: Workflow;

  btnTextNext: string = '<i class="fas fa-file-pdf"></i> Download document als PDF';
  btnTextWait: string = '<i class="fas fa-spinner fa-spin"></i> Bezig met downloaden...';
  btnTextValue: string = this.btnTextNext;
  btnClickAllowed: boolean = true;

  bedanktPage: string = "";
  formType: string; // Registration of Intake

  constructor(private route: ActivatedRoute,
              private router: Router,
              private workflowService: WorkflowService,
              private pageService: PageService,
              private colorService: ColorService) { }

  ngOnInit() {
    this.workflow = this.workflowService.getWorkflow();

    if (this.workflow.intakeId == null) {
      this.formType = 'Registration';
      this.bedanktPage = "bedankt_aanmelden_po";
    } else if (this.workflow.signUpId == null) {
      this.formType = 'Intake';
      this.bedanktPage = "bedankt_aanmelden_intake_po";
    }

    this.pageService
      .getPage(this.bedanktPage)
      .subscribe((page: Page) => {
       this.page = page;
    });
    setTimeout(()=> {
      this.pollingInterval = interval(2000)
        .pipe(
          startWith(0),
          switchMap(() => this.checkPdf())
        ).subscribe(res => {
          this.nrPolls++;
          this.pdfReady = res;
          if (res == true) {
            this.btnTextValue = this.btnTextNext;
            this.stopPolling()
          }
          else if (this.nrPolls >= this.MAX_POLLS) {
            this.stopPolling()
            this.pdfError = true;
          }
        });
    },2000);
  }

  ngOnDestroy():void {
    this.stopPolling();
  }

  getPdf(): void {
    this.changeButtonText();

    this.getPdfAsync().subscribe(() => {
      this.changeButtonText();
    });
  }

  goToHome() {
    this.colorService.changeColor('common');
    this.router.navigate(['/home']);
  }

  changeButtonText(): void {
    this.btnClickAllowed = !this.btnClickAllowed;

    this.btnTextValue === this.btnTextNext ? this.btnTextValue = this.btnTextWait : this.btnTextValue = this.btnTextNext;
  }

  getPdfAsync(): Observable<boolean> {
    return new Observable(observer => {

      if (this.formType === 'Registration') {
        this.workflowService.getRegistrationPdf(this.workflow.id);
      } else if (this.formType === 'Intake') {
        this.workflowService.getIntakePdf(this.workflow.id);
      }

      window.setTimeout(() => {
        observer.next();
        observer.complete();
      }, 3000);
    });
  }

  checkPdf(): Observable<boolean> {
    if (this.formType === 'Intake') {
      return this.workflowService.checkIntakePdf(this.workflow.id)
    } else if (this.formType === 'Registration') {
      return this.workflowService.checkRegistrationPdf(this.workflow.id)
    }
  }

  stopPolling(): void {
    if (this.pollingInterval) {
      this.pollingInterval.unsubscribe();
    }
  }

}
