<div class="modal-header">
  <h4 class="modal-title pull-left">{{ title }}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
    <span aria-hidden="true">×</span>
  </button>
</div>
<div class="modal-body" *ngIf="type !== 'html'; else useInnerHtml">{{text}}</div>
<ng-template #useInnerHtml>
  <div class="modal-body" [innerHtml]="text | ngxSafeHtml" ></div>
</ng-template>
<div class="modal-footer">
  <button type="button" class="btn btn-default" (click)="closeAndRedirect()"><i class="fas fa-times" aria-hidden="true"></i> {{ closeBtnName }}</button>
</div>

