import {AbstractControl} from "@angular/forms";

export function ValidatePhone(control: AbstractControl) {

  if(control.value == null ||
    ((typeof control.value === 'string' || Array.isArray(control.value)) && control.value.length === 0)) {
    return null
  }

  const value = typeof control.value === 'string' ? control.value : String(control.value);

  /* Dutch phonenumber international format */
  if (/^(0031|\+31)[1-9][0-9]{8}$/.test(value)) {
    return null;
  }
  /* Dutch phone number (Dutch format) */
  if (/^(0031|\+31|0)[1-9][0-9]{8}$/.test(value)) {
    return null;
  }
  /* Dutch mobile phone number */
  if (/^(0031|\+31|0)6[0-9]{8}$/.test(value)) {
    return null;
  }

  /* German phone numbers always starting with 0049 or +49 */
  /* German phone number (12 digits) */
  if (/^(0049|\+49)[1-9][0-9]{5,10}$/.test(value)) {
    return null;
  }
  /* German mobile phone number (12 digits) */
  if (/^(0049|\+49)[1][5-7][0-9]{7,9}$/.test(value)) {
    return null;
  }
  /* Belgian phone numbers always starting with 0032 or +32 */
  /* Belgian phone number (9 digits) */
  if (/((^0032|\+32)[1-9][0-9]{6,7})$/.test(value)) {
    return null;
  }
  /* Belgian mobile phone number */
  if (/(^(0032|\+32)[4][0-9]{8}$)/.test(value)) {
    return null;
  }

  /* none of them matches.... error */
  return {
      'appCheckPhone': {value: value}
  }
}
