import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';


@Directive({
    selector: '[appClusterCheck]',
    providers: [ { provide: NG_VALIDATORS, useExisting: ClusterCheckDirective, multi: true } ]
})
export class ClusterCheckDirective implements Validator {
    @Input()
    appDateCheck: string;

    validate(control: AbstractControl): {[key: string]: any} | null {
        const value = control.value;
        if (!value) {
            return null;
        }

        if (value.length === 10) {
            const day = Number(value.substr(0, 2));
            const month = Number(value.substr(3, 2)) - 1;
            const year = Number(value.substr(6, 4));

            const date = new Date(year, month, day);
            const isValid = date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
            const isInFuture = date.getTime() > new Date().getTime();
            if (isValid && isInFuture) {
                return null;
            }
        }
        return {'appClusterCheck': { value: value } };
    }
}
