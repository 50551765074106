import { Component, OnInit} from '@angular/core';
import { ColorService } from '../../services/color.service';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import {StatusService} from "../../services/status.service";
import {Status} from "../../model/status";
import {share, shareReplay} from "rxjs";

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html'
})
export class AppHeaderComponent implements OnInit {
  isLoggedIn: boolean;
  collapsed = true;
  public portalType: string;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    public colorService: ColorService,
    private statusService: StatusService,
    ) { }

  ngOnInit() {
    if (this.keycloakService.isLoggedIn()) {
      this.isLoggedIn = true;
    }
    this.statusService.getStatus().pipe(shareReplay(1)).subscribe((status: Status) => {
      this.portalType = status.portalType;
    })
  }

  changeColor(name: string): boolean {
    this.colorService.changeColor(name);

    if (this.isLoggedIn) {
      this.router.navigate(['/home']);
    } else {
      this.router.navigate(['/']);
    }
    this.collapsed = true;

    return false;
  }
  toggleCollapsed(): void {
    this.collapsed = !this.collapsed;
  }
  logout($event: Event): void {
    $event.preventDefault();
    this.isLoggedIn = false;
    this.keycloakService.logout(this.getCurrentHome());
  }

  getCurrentHome(): string {
    let url = window.location.href;
    if (url.indexOf('http') == 0) {
      let homeSlash = url.indexOf('/',8);
      if (homeSlash > 0) {
        return url.substring(0,homeSlash);
      }
    }
    return url;
  }

  shouldShowHeader(): boolean {
    if(this.portalType === 'PO') return true

    return this.router.url === '/' ||
      this.router.url.includes('/home') ||
      this.router.url.includes('/faq') ||
      this.router.url.includes('/privacy') ||
      this.router.url.includes('/contact') ||
      this.router.url.includes('/school') ||
      this.router.url.includes('/login') ||
      this.router.url.includes('/logout');
  }

  goTo(name: string, url: string) {
    this.colorService.changeColor(name);
    this.router.navigate([url]);
    this.collapsed = true;
  }
}
