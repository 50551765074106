import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '../../shared/model/page';
import { KeycloakService } from 'keycloak-angular';
import { PageService } from '../../shared/services/page.service';
import { StatusService } from "../../shared/services/status.service";
import { Status } from "../../shared/model/status";
import {DirectLinkService} from "../../shared/services/direct-link.service";

@Component({
  selector: 'app-home',
  templateUrl: './index.component.html',
})
export class IndexComponent implements OnInit {
  page: Page = {
    title: '',
    body: ''
  } as Page;
  welkomPage = 'welkom';
  status: Status;

  constructor(
    private router: Router,
    private keycloakService: KeycloakService,
    private statusService: StatusService,
    private pageService: PageService,
    private directLinkService: DirectLinkService
  ) { }

  ngOnInit() {
      if (this.keycloakService.isLoggedIn()) {
          const directLink = localStorage.getItem("directLink");
          this.statusService.getStatus()
              .subscribe((s) => {
                  this.statusService.setPortalType(s.portalType);
                  if (s.portalType === 'VO') {
                      if (directLink !== undefined && directLink !== null) {
                          this.directLinkService.navigate(directLink);
                          return;
                      }
                  }
                  this.router.navigate(['/home']);
              });
      }

    this.getWelcomePage();
  }

  getWelcomePage(): void {
    this.statusService.getStatus()
      .subscribe((s) => {
        this.status = s;
        this.statusService.setPortalType(this.status.portalType)
        if (this.status.portalOpen) {
          this.welkomPage = 'welkom';
        }
        else {
          this.welkomPage = 'welkom_gesloten';
        }
        this.getPage();
      });
  }


  getPage(): void {
    this.pageService
      .getPage(this.welkomPage)
      .pipe().subscribe((page: Page) => {
      this.page = page;
    });
  }

  login(): void {
    this.keycloakService.login();
  }
}
