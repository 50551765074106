<p *ngIf="this.signUps.length > 0">Hieronder vindt u een overzicht van uw aanmelding(en). Afgeronde aanmeldingen kunnen niet meer bewerkt of verwijderd worden.</p>
<!--    Tabel alleen voor desktop weergave-->
<table class="table table-borderless hide-for-small-only" *ngIf="this.signUps.length > 0">
  <thead>
  <tr>
    <th>Waar</th>
    <th>Schooljaar</th>
    <th>Wie</th>
    <th>Status</th>
    <th>Laatste wijziging</th>
    <th>Actie</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let signUp of this.signUps" [ngClass]="{'bg-warning': signUp.isTest}">
    <td>{{signUp.school.name}}</td>
    <td>{{signUp.schoolYear}}</td>
    <td>
      <div *ngIf="signUp.student">
        {{signUp.student?.nickName}} {{signUp.student?.prefix}} {{signUp.student?.lastName}}
      </div>
      <div *ngIf="!signUp.student">
        <i>nog niet ingevuld</i>
      </div>
    </td>
    <td [ngSwitch]="signUp.isCompleted">
      <ng-container *ngSwitchCase="false">In bewerking</ng-container>
      <ng-container *ngSwitchCase="true">Afgerond</ng-container>
    </td>
    <td>
      <div>
        {{formatDate(signUp.modifiedAt)}}
      </div>
    </td>
    <td>
      <div class="btn-group" role="group">
        <button class="btn btn-info btn-sm" *ngIf="!signUp.isCompleted || signUp.isTest" (click)="edit(signUp)"><i class="fas fa-edit" aria-hidden="true"></i></button>
        <button class="btn btn-danger btn-sm" *ngIf="!signUp.isCompleted || signUp.isTest" (click)="openModal(template)"><i class="fas fa-trash" aria-hidden="true"></i></button>
        <ng-template #template>
          <div class="modal-header">
            <h4 class="modal-title pull-left">Bevestigen</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            Weet u zeker dat u de aanmelding wilt verwijderen?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="modalRef.hide()"><i class="fas fa-times" aria-hidden="true"></i> Nee</button>
            <button type="button" class="btn btn-danger" (click)="delete(signUp.id)"><i class="fas fa-check" aria-hidden="true"></i> Ja</button>
          </div>
        </ng-template>

        <button class="btn btn-info btn-sm" *ngIf="signUp.pdfReady && (signUp.isCompleted || signUp.isTest)" (click)="getPdf(signUp.id)"><i class="fas fa-file-pdf" aria-hidden="true"></i></button>
        <i title="Neem contact op met de school" class="warning fas fa-exclamation-triangle" *ngIf="!signUp.pdfReady && signUp.isCompleted"> </i>
        <div class="errorbutton" *ngIf="signUp.isCompleted && signUp.malwareDetected">
          <i title="Malware gedetecteerd in bestanden. Neem contact op met de school" class="error fas fa-exclamation-triangle"> </i>
        </div>
        <div class="errorbutton" *ngIf="!signUp.isCompleted && signUp.malwareDetected">
          <i title="Malware gedetecteerd in bestand(en)" class="error fas fa-exclamation-triangle"> </i>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</table>

<!--    Tabel alleen voor mobiele weergave-->
<table class="table table-borderless table-striped hide-for-desktop" *ngIf="this.signUps.length > 0">
  <thead>
  <tr>
    <th>Aanmelding</th>
    <th>Actie</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let signUp of this.signUps" [ngClass]="{'bg-warning': signUp.isTest}">
    <td>

      {{signUp.school.name}}

      <div *ngIf="signUp.student">
        {{signUp.student?.nickName}} {{signUp.student?.prefix}} {{signUp.student?.lastName}}
      </div>

      <div [ngSwitch]="signUp.isCompleted">
        <ng-container *ngSwitchCase="false">In bewerking</ng-container>
        <ng-container *ngSwitchCase="true">Afgerond</ng-container>
      </div>

      <br/>

    </td>

    <td>
      <div class="btn-group" role="group">
        <button class="btn btn-info btn-sm" *ngIf="!signUp.isCompleted || signUp.isTest" (click)="edit(signUp)"><i class="fas fa-edit" aria-hidden="true"></i></button>
        <button class="btn btn-danger btn-sm" *ngIf="!signUp.isCompleted || signUp.isTest" (click)="openModal(template)"><i class="fas fa-trash" aria-hidden="true"></i></button>
        <ng-template #template>
          <div class="modal-header">
            <h4 class="modal-title pull-left">Bevestigen</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            Weet u zeker dat u de aanmelding wilt verwijderen?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default" (click)="modalRef.hide()"><i class="fas fa-times" aria-hidden="true"></i> Nee</button>
            <button type="button" class="btn btn-danger" (click)="delete(signUp.id)"><i class="fas fa-check" aria-hidden="true"></i> Ja</button>
          </div>
        </ng-template>

        <button class="btn btn-info btn-sm" *ngIf="signUp.pdfReady && (signUp.isCompleted || signUp.isTest)" (click)="getPdf(signUp.id)"><i class="fas fa-file-pdf" aria-hidden="true"></i></button>
        <i title="Neem contact op met de school" class="warning fas fa-exclamation-triangle" *ngIf="!signUp.pdfReady && signUp.isCompleted"> </i>
        <div class="errorbutton" *ngIf="signUp.isCompleted && signUp.malwareDetected">
          <i title="Malware gedetecteerd in bestanden. Neem contact op met de school" class="error fas fa-exclamation-triangle"> </i>
        </div>
        <div class="errorbutton" *ngIf="!signUp.isCompleted && signUp.malwareDetected">
          <i title="Malware gedetecteerd in bestand(en)" class="error fas fa-exclamation-triangle"> </i>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</table>

