import {Component, Input} from '@angular/core';
import {Intake, ReferralStatus, Registration, Workflow, WorkflowStatus} from "../../../shared/model/workflow";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../../shared/services/notification.service";
import {WorkflowService} from "../../../shared/services/workflow.service";
import {FormService} from "../../../shared/services/form.service";
import {SchoolService} from "../../../shared/services/school.service";
import {ColorService} from "../../../shared/services/color.service";
import {BaseHomeOverviewComponent} from "../base-home/base-home-overview.component";
import {SignUpService} from "../../../shared/services/signup.service";
import {SignUp} from "../../../shared/model/signup";
import {BsModalService} from "ngx-bootstrap/modal";
import {StatusService} from "../../../shared/services/status.service";
import {PageService} from "../../../shared/services/page.service";

@Component({
  selector: 'app-home-po-overview',
  templateUrl: './home-po-overview.component.html',
  styleUrls: ['../base-home/base-home-overview.component.css','./home-po-overview.component.css']
})
export class HomePoOverviewComponent extends BaseHomeOverviewComponent {

  @Input()
  workflows: Workflow[] = [];

  @Input()
  formId: string;

  @Input()
  intakeByParent: boolean;

  referred = true;

  constructor(router: Router,
              route: ActivatedRoute,
              notificationService: NotificationService,
              formService: FormService,
              schoolService: SchoolService,
              colorService: ColorService,
              modalService: BsModalService,
              signUpService: SignUpService,
              workflowService: WorkflowService,
              statusService: StatusService,
              pageService: PageService,
  ) {
   super(router,route,notificationService,formService,schoolService,colorService,modalService,signUpService,workflowService,statusService,pageService)
    super.setFormId(this.formId);
  }

  startSignUp(workflow: Workflow) {
    this.getSchoolService().getSchoolById(workflow.schoolId).subscribe((s) => {
      this.getSchoolService().setSchool(s);
      this.getColorService().changeColor(s.stylingId);
      this.getFormService().getFormById(s.formId).subscribe(form => {
        this.getFormService().setForm(form);
        this.getWorkflowService().startSignUp(workflow.id).subscribe(signUp => {
          this.getWorkflowService().setWorkflow(workflow,true)
          this.getWorkflowService().setSignUp(signUp)
          this.getSignUpService().setSignUp(signUp)
          this.getRouter().navigate(['/' + signUp.lastStep]);
        })
      });
    });
  }
  editSignUp(signup: SignUp): void {
    this.getSchoolService().getSchoolById(signup.school.schoolId).subscribe((s) => {
      this.getSchoolService().setSchool(s);
      this.getColorService().changeColor(s.stylingId);
      this.getSignUpService().setSignUp(signup);

      const routerCommand = '/' + signup.lastStep;
      if (signup.formId !== undefined && signup.formId !== null) {
        this.getFormService().getFormById(this.formId ? this.formId : signup.formId).subscribe(form => {
          this.getFormService().setForm(form);
          this.getRouter().navigate([routerCommand]);
        });
      } else {
        this.getFormService().getFormById(this.formId ? this.formId :s.formId).subscribe(form => {
          this.getFormService().setForm(form);
          this.getRouter().navigate([routerCommand]);
        });
      }
    });
  }
  editRegistration(registration: Registration, registrationFormId: string): void {
    const lastStep = registration?.lastStep;
    const routerCommand = '/po/aanmelding/' + (registration ? lastStep : "leerling");

    this.getFormService().getFormById(this.formId ? this.formId : registrationFormId).subscribe(form => {
      this.getFormService().setForm(form);
      this.getRouter().navigate([routerCommand]);
    });
  }

  editIntake(intake: Intake): void {
    const routerCommand = '/po/intake/stap/';
    let stepIndex = 1;

    this.getFormService().getFormById(this.formId ? this.formId : intake.formId).subscribe(form => {
      this.getFormService().setForm(form);

      if (intake.lastStep) {
        stepIndex = (this.getFormService().getForm().sections.findIndex((section) => section.type === intake.lastStep) + 1)
      }
      this.getRouter().navigate([routerCommand + stepIndex]);
    });
  }

  editWorkflow(workflow: Workflow, type: string): void {
    this.getSchoolService().getSchoolById(workflow.schoolId).subscribe((schoolConfig) => {
      // Check if portal is open and school is enabled, If not: return to home with message
      this.determineSchoolConfigEnabled(schoolConfig).subscribe((portalOpenAndEnabled: boolean) => {
        if (!portalOpenAndEnabled) {
          this.handlePortalClosedError();
          return;
        }

        if (type == 'signup') {
          this.getWorkflowService().getSignUpByWorkflowId(workflow).subscribe((data) => {
            this.edit(data);
          });
        } else if (type == 'intake') {
          this.getSchoolService().setSchool(schoolConfig);
          this.getColorService().changeColor(schoolConfig.stylingId);
          this.getWorkflowService().setWorkflow(workflow, true);
          if (workflow.intakeId != null) {
            this.getWorkflowService().getIntakeByWorkflowId(workflow).subscribe((data) => {
              this.editIntake(data);
            });
          } else {
            this.getWorkflowService().startIntake(workflow.id).subscribe(intake => {
              this.getWorkflowService().setIntake(intake);
              this.getWorkflowService().getWorkflowByWorkflowId(workflow.id).subscribe((workflow) => {
                // Set form so that Steps can be filled within ap
                this.getFormService().getFormById(workflow.intake.formId).subscribe(form => {
                  this.getFormService().setForm(form);
                  this.getRouter().navigate(['/po/intake/stap/1']);
                });
              })
            });
          }

        } else {
          this.getSchoolService().setSchool(schoolConfig);
          this.getColorService().changeColor(schoolConfig.stylingId);

          this.getWorkflowService().setWorkflow(workflow, true);
          if (workflow.registrationId != null) {
            this.getWorkflowService().getRegistrationByWorkflowId(workflow).subscribe((data) => {
              this.getWorkflowService().setRegistration(data);
              this.editRegistration(data, schoolConfig.registrationFormId);
            });
          } else {
            this.editRegistration(undefined, schoolConfig.registrationFormId);
          }
        }
      })
    })
  }
  getReferralStatus(statusEnum: string): string {
    for (const status of Object.keys(ReferralStatus)) {
      if (statusEnum === status) {
        return ReferralStatus[status]
      }
    }
    return "Onbekend"
  }
  getWorkflowStatus(statusEnum: string): string {
    for (const status of Object.keys(WorkflowStatus)) {
      if (statusEnum === status) {
        return WorkflowStatus[status]
      }
    }
    return "Onbekend"
  }
  getRegistrationPdf(signUpWorkflowId: string): void {
    this.getWorkflowService().getRegistrationPdf(signUpWorkflowId);
  }

  getSignUpPdf(signUpWorkflowId: string): void {
    this.getWorkflowService().getSignUpPdf(signUpWorkflowId);
  }

  getIntakePdf(signUpWorkflowId: string): void {
    this.getWorkflowService().getIntakePdf(signUpWorkflowId);
  }

  deleteWorkflowRegistration(signUpWorkflowId: string): void {
    this.getWorkflowService().delete(signUpWorkflowId).subscribe((data) => {
      this.workflows = data;
      this.updateReferral();
      this.modalRef.hide();
      this.getNotificationService().success("", "De aanmelding is verwijderd!")
    }, (err) => {
      if(err.status == 404) {
        this.getNotificationService().signupNotFoundError();
      }
    });
  }

  updateReferral(): void {
    this.workflows.forEach((w) => {
      if (w.referralStatus) { this.referred = true; }
    });
  }

  approve(signUpWorkflow: Workflow,templateId: any): void {
    this.getWorkflowService().getReferral(signUpWorkflow.id).subscribe((data) => {
      signUpWorkflow.referral = data;
      this.openModalWithTemplateParameter(templateId, signUpWorkflow)
    });
  }
  approveReferral(signUpWorkflowId: string): void {
    this.getWorkflowService().approveReferral(signUpWorkflowId).subscribe((data) => {
      this.workflows = data;
      this.updateReferral();
      this.modalRef.hide();
      this.getNotificationService().success("", "De doorverwijzing is verwerkt")
    }, (err) => {
      if(err.status == 404) {
        this.getNotificationService().signupNotFoundError();
      }
    });
  }

  rejectApproval(signUpWorkflowId: string): void {
    this.getWorkflowService().rejectApproval(signUpWorkflowId).subscribe((data) => {
      this.workflows = data;
      this.updateReferral();
      this.modalRef.hide();
      this.getNotificationService().success("", "De doorverwijzing is afgewezen")
    }, (err) => {
      if(err.status == 404) {
        this.getNotificationService().signupNotFoundError();
      }
    });

  }
}
