export class SentFile {
    signUpId: string;
    filename: string;
    malwareDetected: boolean;
    malwareName: string;
    encodedFile: string;
    uploaded: boolean;
    fileType: string;
    varName?: string;
}

export enum UploadedDocumentType {
  onderzoeksgegevens = "onderzoeksgegevens",
  opp = "opp",
  ouderlijkgezag = "ouderlijkgezag",
  aanvullend = "aanvullend",
  custom = "custom"
}


export enum UploadedDocumentTypeVarName {
    additionalReport = UploadedDocumentType.onderzoeksgegevens,
    additionalOpp = UploadedDocumentType.opp,
    additionalAuthority = UploadedDocumentType.ouderlijkgezag,
    additionalDocument = UploadedDocumentType.aanvullend,
    customDocumentQuestionField = UploadedDocumentType.custom
}
