<header class="app-header">
  <nav id="navbar" class="navbar navbar-expand-lg fixed-top" [ngClass]="{'always-color-header': !shouldShowHeader()}">
    <div class="container">
    <a class="navbar-brand" (click)="changeColor('common')">
        <img id="logo" src="{{colorService.currentColor}}">
      </a>
      <button class="navbar-toggler" type="button" (click)="toggleCollapsed()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav" [ngClass]="{'collapse': collapsed, 'navbar-collapse': true, 'show': collapsed == false, 'navbar-bg': collapsed == false}">
        <ul class="nav navbar-nav d-md-down-none ml-auto">
          <li class="nav-item px-3">
            <a class="nav-link active" href="#" (click)="changeColor('common')">Home</a>
          </li>
          <li class="nav-item px-3 hide-for-desktop">
            <a class="nav-link active" (click)="goTo('common', '/privacy')">Privacy</a>
          </li>
          <li class="nav-item px-3 hide-for-desktop">
            <a class="nav-link active" (click)="goTo('common','/faq')">Veelgestelde vragen</a>
          </li>
          <li class="nav-item px-3 hide-for-desktop">
            <a class="nav-link active" (click)="goTo('common','/contact')">Contact</a>
          </li>
          <li class="nav-item px-3" *ngIf="isLoggedIn">
            <a class="nav-link active" href="#"  (click)="logout($event)">Uitloggen</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
<div *ngIf="!shouldShowHeader()" [ngClass]="{'no-image' : !shouldShowHeader()}"></div>

<div id="home-header-image" class="home-header-image" *ngIf="shouldShowHeader()">
  <div *ngIf="this.portalType !== 'PO'" class="home-header-image-text">
    <div class="intro-content">
      <h1>Aanmeldformulier</h1>
    </div>
  </div>
</div>
