import { Component } from '@angular/core';
import { ColorService } from "../../services/color.service";

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html'
})
export class AppFooterComponent {
  constructor(public colorService: ColorService) { }

  changeColor(name: string) {
    this.colorService.changeColor(name);
  }
}
