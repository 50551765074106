
export interface SignUp {
  id?: string;
  formId: string;
  schoolYear: string;
  accountId: string;
  createdAt: string;
  modifiedAt: string;
  lastStep: string;
  isCompleted: boolean;
  isInflux: boolean;
  isTest: boolean;
  malwareDetected: boolean;
  pdfReady: boolean;
  additionalInformation: AdditionalInformation;
  student: Student;
  careTakers: CareTakers;
  family: Family;
  school: School;
  schoolOfOrigin: SchoolOfOrigin;
  particularities: Particularities;
  signature: Signature;
  schoolPreferences: SchoolPreference[];
}

export interface AdditionalInformation {
  remarks: string; //deprecated
  additionalRemarks: string;
  sendByPostService: boolean;
  documents: UploadedDocument[];
  customQuestionField: any;
  customDocumentQuestionField: any;
  customQuestions: CustomQuestion[];
  customDocumentQuestions: CustomQuestion[];
  preferredClassmates: string;
}


export interface CareTakers {
    secondCareTaker: boolean;
    thirdCareTaker: boolean;
    parentOne?: Parent;
    parentTwo?: Parent;
    parentThree?: Parent;
    paymentInformation: PaymentInformation;
}

export interface Signature {
    signedAt: string;
    signedBy: string;
    isSigned: boolean;
    customQuestionField: any;
    customQuestions: CustomQuestion[];
}

export class CustomQuestion {
  varName: string;
  value: string;
}

export interface UploadedDocument {
  type: string;
  fileName: string;
  originalFileName: string;

  malwareDetected: boolean;
  malwareName: string;
  varName: string;
}

export interface Student {
  firstName: string;
  nickName: string;
  prefix?: string;
  lastName: string;
  bsn: string;
  hasBSN: boolean;
  own: string;
  vnum: string;
  sex: string;
  pronouns: string;
  birthDate: string;
  birthPlace: string;
  birthTownship: string;
  birthCountry: string;
  arrival?: string;
  previousEducation?: string;
  nationality: string;
  country: string;
  street: string;
  houseNumber: string;
  houseNumberAddition?: string;
  postalCode: string;
  city: string;
  mobileNumber: PhoneNumber;
  emailAddress: string;
  customQuestionField: any;
  customQuestions: CustomQuestion[];
}

export interface PhoneNumber {
  number: string;
  isSecret: string;
}

export interface PaymentInformation {
  billableCaretaker: number;
  nameAccountHolder: string;
  iban: string;
  sendToEmail: string;
}

export interface Parent {
  sameAddressAsStudent: boolean;
  relationToStudent: string;
  initials: string;
  prefix?: string;
  lastName: string;
  sex: string;

  hasParentalAuthority: boolean;
  emailAddress: string;
  country: string;
  street: string;
  houseNumber: string;
  houseNumberAddition: string;
  postalCode: string;
  city: string;
  phoneNumber: PhoneNumber;
  mobileNumber: PhoneNumber;
}
export interface Family {
  parentsDivorced: boolean;
  studentLivesAt: string;
  studentLivesAtText: string;
  parentDied: boolean;
  parentalAuthority: string;
  parentalAuthorityExplanation: string;
  whichParentDied: string;
  familyRemarks: string;
  childrenAtSchool: string;
  deceasedNotes: string;
  church: string;
  customQuestionField: any;
  customQuestions: CustomQuestion[];
}
export interface School {
  schoolId: string;
  schoolRoleId: string;
  name: string;
  schoolYear: string;
  startDate: string;
  schoolLocation: string;
  educationYear: number;
  educationType: string;
  educationLevel: string;
  educationLwoo: boolean;
  educationLevelAdvice: string;
  educationProfile: string;
  educationLesson: string;
  choice: number;
  firstChoiceSchoolName: string;
  secondSchoolPreference: SchoolPreference;
  thirdSchoolPreference: SchoolPreference;
  fourthSchoolPreference: SchoolPreference;
  fifthSchoolPreference: SchoolPreference;
  customQuestionField: any;
  customQuestions: CustomQuestion[];
}

export interface SchoolPreference {
  schoolId: string;
  contractNr: string;
  schoolName: string;
  educationLevel?: string;
  schoolYear: string;
  schoolLocation: string;
  educationType?: string;
  educationLevelAdvice: string;
}

export interface SchoolOfOrigin {
  schoolType: string;
  name: string;
  brin: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  country: string;
  city: string;
  contact?: string;
  contactEmail?: string;
  currentYear?: number;
  currentDepartment?: string;
  previousDepartments?: string;
  reasonSwap?: string;
  contactPhone: PhoneNumber;
  customQuestionField: any;
  customQuestions: CustomQuestion[];
}

export interface DuoSchool {
   schoolType: string;
   brinNumber: string;
   locationNumber: string;
   displayName: string;
   locationName: string;
   searchField: string;
   streetName: string;
   houseNumber: string;
   postalCode: string;
   cityName: string;
   phoneNumber: string;
   internetAddress: string;
}

export interface Particularities {
  hasMedicalDiagnosis: boolean;
  hasMedicalParticularities: boolean;
  hasMedicationUse: boolean;
  hasClusterIndication: boolean;
  hasNeedForSupport: boolean;
  medicalDiagnostics: string;
  needForSupport: string;
  emergServices: string;
  hasOpp: boolean;
  clusterIndication?: (ClusterIndicationEntity)[] | null;
  medicalParticularities: string;
  medicationUsed: string;
  customQuestionField: any;
  customQuestions: CustomQuestion[];
}
export interface ClusterIndicationEntity {
  validUntil: string;
}
