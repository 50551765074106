import {DynamicSectionComponent} from "./dynamic-section-component";
import {FormBuilder} from "@angular/forms";
import {Router} from "@angular/router";
import {FormService} from "../services/form.service";
import {AddressService} from "../services/address.service";
import {CountryService} from "../services/country.service";
import {SchoolService} from "../services/school.service";
import {TextFormatService} from "../services/textformat.service";
import {FormType, SectionType} from '../model/formSection';
import {SignUp, Student} from "../model/signup";
import {CountryCode} from "../model/countrycode";
import {NationalityCode} from "../model/nationalitycode";
import {ConditionType, FormField} from "../model/formField";
import {BagAddress} from "../model/addresses";
import {LasType} from "../model/schoolConfiguration";
import {Postalcodelookup} from '../model/postalcodelookup';
import {NotificationService} from "../services/notification.service";
import {Workflow} from "../model/workflow";
import {WorkflowService} from "../services/workflow.service";
import {SignUpService} from "../services/signup.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {RuleEvaluatorService} from "../services/rule-evaluator.service";
import {ScrollService} from "../services/scroll.service";

export abstract class StudentComponent extends DynamicSectionComponent {

  workflow: Workflow;
  registrationFormId: string;
  postalcodelookup: Postalcodelookup = {} as Postalcodelookup;
  student: Student;
  signUp: SignUp;
  vLasType = LasType;
  countryCodes: CountryCode[] = [
    {
      countryCode: '6030',
      countryName: 'Nederland'
    }
  ];
  nationalityCodes: NationalityCode[] = [
    {
      nationalityCode: '0001',
      nationalityName: 'Nederlandse'
    }
  ];

  constructor(router: Router,
              formService: FormService,
              notificationService: NotificationService,
              ruleEvaluatorService: RuleEvaluatorService,
              private formBuilder: FormBuilder,
              private signUpService: SignUpService,
              private workflowService: WorkflowService,
              private addressService: AddressService,
              private countryService: CountryService,
              private schoolService: SchoolService,
              private textFormatService: TextFormatService,
              formType: FormType,
              modalService: BsModalService,
              scrollService: ScrollService
              ) {
    super(router,formService,notificationService,modalService, ruleEvaluatorService, scrollService, SectionType.STUDENT, FormType.SIGNUP)
    this.setFormType(formType);

    if (this.isWorkflowFormType()) {
      this.student = this.workflowService.getRegistration()?.student as Student;
    }
    else {
      this.signUp = this.signUpService.getSignUp();
      this.student = this.signUp.student as Student;
    }

    this.countryService.getCountries().subscribe((countries) => {
      this.countryCodes = countries;
      this.setCountryOptions();
    });

    this.countryService.getNationalities().subscribe((nationalities) => {
      this.nationalityCodes = nationalities;
      this.setNationalityOptions();
    });
    this.formGroup = this.formBuilder.group({});
  }

  genericNgOnInit() {
    if (this.isWorkflowFormType()) {
      this.workflow = this.workflowService.getWorkflow();
      let applicationId = sessionStorage.getItem("aanmeldingId");

      if (applicationId !== undefined && applicationId !== null) {
        this.registrationFormId = applicationId;
      }
    }

    if (!this.student) {
      this.student = {
        firstName: '',
        nickName: '',
        lastName: '',
        bsn: '',
        hasBSN: false,
        own: '',
        vnum: '',
        sex: '',
        pronouns: '',
        birthDate: '', // new Date().toISOString().replace(/\//g, '-'),
        birthCountry: 'Nederland',
        birthPlace: '',
        birthTownship: '',
        nationality: 'Nederlandse',
        country: 'Nederland',
        street: '',
        houseNumber: '',
        houseNumberAddition: '',
        postalCode: '',
        city: '',
        emailAddress: '',
        mobileNumber: {
          isSecret: '',
          number: ''
        },
        customQuestions: [],
        customQuestionField: {}
      };

      if (this.isWorkflowFormType()) {
        this.workflowService.setStudent(this.student);
      }
      else {
        this.signUpService.setStudent(this.student);
      }
    }

    this.initCustomQuestions(this.student);

    super.commonNgOnInit();
    if (!this.getSection()) return;

    this.getSection().fields.forEach(fld => {
      this.addCallbackFunctions(fld);
      this.checkControl(fld, this.student, this.signUp);
    });

    this.determineIdentityChecks();

    // check fields
    if (this.formGroup.get('country')) {
      this.setPostalCodeValidator('postalCode',this.formGroup.get('country').value)
      this.formGroup.get('country').valueChanges.subscribe(value => {
        if (value == 'Nederland') {
          this.getFieldByVarName('postalCode').callbackFn = this.checkPostalCode;
          this.getFieldByVarName('houseNumber').callbackFn = this.checkPostalCode
        }
        else {
          this.getFieldByVarName('postalCode').callbackFn = null;
          this.getFieldByVarName('houseNumber').callbackFn = null;
        }
        this.setPostalCodeValidator('postalCode',value)
      });
    }

    if (this.formGroup.get('birthCountry')) {
      this.formGroup.get('birthCountry').valueChanges.subscribe(value => {
        if (value == 'Nederland') {
          this.removeControlFromFormGroup('arrival');
          this.removeControlFromFormGroup('previousEducation');
        }
        else {
          this.addControlToFormGroup(this.getFieldByVarName('arrival'),this.student);
          this.addControlToFormGroup(this.getFieldByVarName('previousEducation'),this.student);
        }
      })
    }
  }

  emptyValue(varName: string) {
    // Check of field is ingevuld, zo ja: leeg field
    if (this.formGroup.get(varName) != null && this.formGroup.get(varName).value != null && this.formGroup.get(varName).value != "") {
      this.formGroup.get(varName).setValue("");
    }
  }
  enableField(varName: string) {
    // Check of field disabled is, enable field dan
    if (this.formGroup.get(varName) != null && !this.formGroup.get(varName).enabled) {
      this.formGroup.get(varName).enable();
    }
  }
  disableField(varName: string) {
    // Check of field enabled is, disable field dan
    if (this.formGroup.get(varName) != null && !this.formGroup.get(varName).disabled) {
      this.formGroup.get(varName).disable();
    }
  }

  setFieldDisableIdentityFields(varName: string, identityFieldVarNames: string[]): void {
    // Check of varName eerder al ingevuld is. Disable andere twee velden dan.
    if (this.formGroup.get(varName) != null && this.formGroup.get(varName).value != null && this.formGroup.get(varName).value != "") {
      identityFieldVarNames.filter(fieldVarName => fieldVarName != varName).forEach(fieldVarName => {
        this.disableField(fieldVarName)
      });
    }
  }

  setValueChangeIdentityFields(varName: string, identityFieldVarNames: string[]): void {
    if (this.formGroup.get(varName) != null) {
      // Check of varName ingevuld wordt, zo ja: disable andere twee velden.
      this.formGroup.get(varName).valueChanges.subscribe(value => {
        if (!this.formGroup.get(varName).disabled) {
          // Check of field van varName is ingevuld
          if (this.formGroup.get(varName).value != null && this.formGroup.get(varName).value != "") {
            // Empty en disable andere twee velden
            identityFieldVarNames.filter(field => field != varName).forEach(field => {
              this.emptyValue(field);
              this.disableField(field)
            });
          }
          else {
            // Wanneer field van varName weer leeg gemaakt wordt, enable andere twee velden
            identityFieldVarNames.filter(field => field != varName).forEach(field => {
              this.enableField(field)
            });
          }
        }
      })
    }
  }

  determineIdentityChecks() {
    let identityFieldVarNames = ['bsn', 'own', 'vnum'];

    // Eerste check of velden eerder al ingevuld zijn. Disable andere twee velden dan.
    this.setFieldDisableIdentityFields('bsn', identityFieldVarNames);
    this.setFieldDisableIdentityFields('own', identityFieldVarNames);
    this.setFieldDisableIdentityFields('vnum', identityFieldVarNames);

    // Als Onderwijsnummer veld en Vreemdelingennummer niet aanwezig, maak BSN 10 breed.
    if (this.formGroup.get('own') == null && this.formGroup.get('vnum') == null) {
      this.getFieldByVarName('bsn').nrColumns = 10;
    }

    // Check of velden ingevuld worden, zo ja: empty en disable andere twee velden, zo nee (veld is weer leeg): enable andere twee velden.
    this.setValueChangeIdentityFields('bsn',  identityFieldVarNames);
    this.setValueChangeIdentityFields('own', identityFieldVarNames);
    this.setValueChangeIdentityFields('vnum', identityFieldVarNames);
  }

  addCallbackFunctions(fld: FormField): void {
    if (this.student.country == 'Nederland' && (fld.varName == 'postalCode' || fld.varName == 'houseNumber')) {
      fld.callbackFn = this.checkPostalCode;
    }
  }

  checkCondition(fld: FormField): boolean {
    let checkOk = true;
    fld.condition.forEach(condition => {
      switch (condition) {
        case ConditionType.BORN_OUTSIDE_NL:
          if (this.student.birthCountry == 'Nederland') {
            checkOk = false;
            fld.conditionalDisabled = true;
            return false;
          }
          break;
        case ConditionType.BORN_IN_NL:
          if (this.student.birthCountry != 'Nederland') {
            checkOk = false;
            fld.conditionalDisabled = true;
            return false;
          }
          break;
        case ConditionType.USE_PLACE_OF_BIRTH:
          if (this.schoolService.getSchool().lasType == this.vLasType.Magister && this.student.birthCountry == 'Nederland') {
            checkOk = false;
            fld.conditionalDisabled = true;
            return false;
          }
          else {
          }
          break;
        case ConditionType.LAS_MAGISTER:
          if (this.schoolService.getSchool().lasType !== this.vLasType.Magister) {
            checkOk = false;
            fld.conditionalDisabled = true;
            return false;
          }
          break;
        case ConditionType.LAS_SOMTODAY:
          if (this.schoolService.getSchool().lasType !== this.vLasType.Somtoday) {
            checkOk = false;
            fld.conditionalDisabled = true;
            return false;
          }
          break;
      }
    });
    return checkOk;
  }

  setNationalityOptions(): void {
    let fld = this.getFieldByVarName('nationality');
    if (!fld) return;
    fld.options$ = this.countryService.getNationalitiesOptions();
  }

  setCountryOptions(): void {
    let fld = this.getFieldByVarName('country');
    if (!fld) return;
    fld.options$ = this.countryService.getCountriesOptions();
    fld = this.getFieldByVarName('birthCountry');
    fld.options$ = this.countryService.getCountriesOptions();
  }

  save(): boolean {
    if (!this.prepareSave(this.student)) return false;
    this.prepareCustomQuestionsForSave(this.student);

    this.student.hasBSN = this.student.bsn != null && this.student.bsn != "";
    if (this.student.postalCode) {
      this.student.postalCode = this.student.postalCode.replace(/\s+/, '');
      this.student.postalCode = this.student.postalCode.toUpperCase();
    }
    this.student.lastName = this.textFormatService.capitalizeFirstLetter(this.student.lastName);
    this.student.nickName = this.textFormatService.capitalizeFirstLetter(this.student.nickName);
    this.student.firstName = this.textFormatService.capitalizeFirstLetterOfEachWord(this.student.firstName);
    if (this.student.birthPlace) this.student.birthPlace = this.textFormatService.capitalizeFirstLetter(this.student.birthPlace);
    if (this.student.emailAddress) {
      this.student.emailAddress = this.student.emailAddress.toLowerCase();
    }

    if (this.isWorkflowFormType()) {
      if (this.workflow.id) {
        this.workflowService.saveStudent(this.student).subscribe((registration) => {

          this.isSubmitted = false;
          this.goToNextInWorkflow();
        }, (err) => {
          this.getRouter().navigate(['/home']);
          this.handleSaveError(err);
        });
      } else {
        this.workflowService.start(this.student, this.schoolService.getSchool()?.id).subscribe((signupWorkflow) => {
          this.isSubmitted = false;
          this.goToNextInWorkflow();
        });
      }
    }
    else {
      this.signUpService.saveStudent(this.student).subscribe((signUp) => {
        this.isSubmitted = false;
        this.goToNext(signUp);
      }, (err) => {
        if (err.error === 'Not allowed to create another first choice signup') {
          this.handleSaveErrorFromDto();
        }
        else {
          this.getRouter().navigate(['/home']);
          this.handleSaveError(err);
        }
      });
    }
    return true;
  }

  checkPostalCode = (): void => {
    const postalCode = this.formGroup.controls['postalCode'].value?.replace(/\s+/, '').toUpperCase();
    const houseNumberMatch = this.formGroup.controls['houseNumber'].value?.match(/\d+/);
    if (!postalCode || postalCode.length !== 6 || !houseNumberMatch) {
      return;
    }

    this.postalcodelookup.postalCode = postalCode;
    this.postalcodelookup.houseNumber = Number(houseNumberMatch[0]);

    this.addressService.getAddresses(this.postalcodelookup).subscribe((data: BagAddress) => {
      if (data.streetName !== '') {
        this.formGroup.controls['street'].setValue(data.streetName);
      }
      if (data.cityName !== '') {
        this.formGroup.controls['city'].setValue(data.cityName);
      }
    });
  }
}
