import { HttpClient } from '@angular/common/http';
import { DuoSchool, SchoolOfOrigin } from '../model/signup';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SchoolAutocompleteService {
    private autocompleteUrl = 'api/schoolautocomplete';

    constructor(private http: HttpClient) { }

    getAutocompleteSuggestions(duoSchool: { schoolType: string, cityName: string, schoolName: string, filter: boolean }): Observable<DuoSchool[]> {
        return this.http.post<DuoSchool[]>(this.autocompleteUrl, duoSchool);
    }

}
