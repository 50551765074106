import {Injectable} from '@angular/core';
import {CareTakers, Signature, SignUp, Student} from '../model/signup';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import saveAs from 'file-saver';
import {Intake, IntakeDTO, Referral, Registration, Workflow} from '../model/workflow';
import {map, mergeMap, tap} from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable()
export class WorkflowService {
  private workflowUrl = 'api/workflow/';  // URL to web api
  private readonly SIGNUP_PREFIX: string = 'inschrijving_';
  private readonly INTAKE_PREFIX: string = 'intake_';
  private readonly REGISTRATION_PREFIX: string = 'aanmelding_';

  private workflow: Workflow = {} as Workflow;
  private registration: Registration = {} as Registration;
  private signUp: SignUp = {} as SignUp;
  private intake: Intake = {} as Intake;

  constructor(private http: HttpClient) {
  }

  setWorkflow(workflow: Workflow, ignoreRegistration: boolean) {
    // Get call to api/workflow/ID/registration
    this.workflow = workflow;
    if (!ignoreRegistration) {
      if (workflow.registrationId) {
        this.http.get<Registration>(this.workflowUrl + workflow.id + "/registration").pipe(
          tap((data: Registration) => {
            this.registration = data;
          })
        );
      } else {
        this.setRegistration(undefined)
      }
    }
  }
  setRegistration(registration: Registration) {
    this.registration = registration;
  }
  getWorkflowByWorkflowId(workflowId: string): Observable<Workflow> {
    // Get call to api/workflow/ID
    return this.http.get<Workflow>(this.workflowUrl + workflowId);
  }
  getRegistrationByWorkflowId(workflow: Workflow): Observable<Registration> {
    // Get call to api/workflow/ID/registration
    return this.http.get<Registration>(this.workflowUrl + workflow.id + "/registration");
  }
  getSignUpByWorkflowId(workflow: Workflow): Observable<SignUp> {
    // Get call to api/workflow/ID/signup
    return this.http.get<SignUp>(this.workflowUrl + workflow.id + "/signup");
  }
  getIntakeByWorkflowId(workflow: Workflow): Observable<Intake> {
    // Get call to api/workflow/ID/intake
    return this.http.get<Intake>(this.workflowUrl + workflow.id + "/intake");
  }
  getWorkflowBySignupId(signup: SignUp): Observable<Workflow> {
    // Get call to api/workflow/signup/ID
    return this.http.get<Workflow>(this.workflowUrl + 'signup/' + signup.id);
  }
  getWorkflow(): Workflow {
    return this.workflow;
  }
  getRegistration(): Registration {
    return this.registration;
  }
  setSignUp(signUp: SignUp): void {
    this.signUp = signUp;
  }
  getSignUp(): SignUp {
    return this.signUp;
  }

  setIntake(intake: Intake): void {
    this.intake = intake;
  }
  getIntake(): Intake {
    return this.intake;
  }

  getWorkflows(): Observable<Workflow[]> {
    // Get call to api/workflow
    return this.http.get<Workflow[]>(this.workflowUrl);
  }

  delete(workflowId: string): Observable<Workflow[]> {
    // Delete call to api/workflow/ID
    const deleteUrl = this.workflowUrl + workflowId;
    return this.http.delete<Workflow[]>(deleteUrl);
  }

  getReferral(workflowId: string): Observable<Referral> {
    // Get call to api/workflow/ID/referral
    const referralUrl = this.workflowUrl + workflowId + '/referral';
    return this.http.get<Referral>(referralUrl);
  }

  approveReferral(workflowId: string): Observable<Workflow[]> {
    // Post call to api/workflow/ID/approvereferral
    const approveUrl = this.workflowUrl + workflowId + '/approvereferral';
    return this.http.post<Workflow[]>(approveUrl,{});
  }

  rejectApproval(workflowId: string): Observable<Workflow[]> {
    // Post call to api/workflow/ID/rejectreferral
    const rejectUrl = this.workflowUrl + workflowId + '/rejectreferral';
    return this.http.post<Workflow[]>(rejectUrl,{});
  }

  setStudent(data: Student) {
    if (!this.registration) {
      this.setRegistration({} as Registration);
    }
    this.getRegistration().student = data;
  }

  startWorkflow(schoolid: string, startDate: string, birthDate: string): Observable<Workflow> {
    let url = this.workflowUrl;

    if (schoolid !== null && schoolid !== undefined) {
      url = url + 'school/' + schoolid;
    }
    return this.http.post<Workflow>(url,{startDate: startDate, birthDate: birthDate}).pipe(
      mergeMap((workflow) => {
        return this.http.get<Registration>(this.workflowUrl + workflow.id + "/registration").pipe(
          map((registration) => {
            workflow.registration = registration;
            this.setRegistration(registration)
            return workflow;
          })
        );
      })
    );
  }
  startIntake(workFlowId: string): Observable<Intake> {
    // Post call to api/workflow/ID/intake/start
    let url = this.workflowUrl;
    url = url + workFlowId + '/intake/start';

    return this.http.post<Intake>(url, null).pipe(
      tap((data: Intake) => {
        this.workflow.intakeId = data.id
        this.setIntake(data);
      }));
  }

  startSignUp(workFlowId: string) {
    // Post call to api/workflow/ID/signup/start
    let url = this.workflowUrl;

    url = url + workFlowId + '/signup/start';
    return this.http.post<SignUp>(url, null).pipe(
      tap((data: SignUp) => {
        this.workflow.signUpId = data.id
        this.setSignUp(data);
      }));

  }

  start(student: Student, sid: string): Observable<Workflow> {
    let url = this.workflowUrl;

    if (sid !== null && sid !== undefined) {
        url = url + 'school/' + sid;
    }

    return this.http.post<Workflow>(url, student).pipe(
      tap((data: Workflow) => {
        this.setWorkflow(data, false);
      }));
  }

  saveStudent(student: Student): Observable<Registration> {
    // Post call to api/workflow/ID/registration/student
    const studentUrl = this.workflowUrl + this.workflow.id + '/registration/student';

    return this.http.post<Registration>(studentUrl, student).pipe(
      tap((data: Registration) => {
        this.registration = data;
      })
    );
  }

  saveCareTakers(caretakers: CareTakers): Observable<Registration> {
    // Post call to api/workflow/ID/registration/caretakers
    const url = this.workflowUrl + this.workflow.id + '/registration/caretakers';

    return this.http.post<Registration>(url, caretakers).pipe(
      tap((data: Registration) => {
        this.registration = data;
      })
    );
  }

  saveSignature(signature: Signature): Observable<Workflow> {
    // Post call to api/workflow/ID/registration/sign
    const url = this.workflowUrl + this.workflow.id + '/registration/sign';

    return this.http.post<Workflow>(url, signature).pipe(
      tap((data: Workflow) => {
        this.setWorkflow(data, false);
      })
    );
  }

  /**
   * save the intake in between steps
   * @param intakeDto
   */
  saveIntake(intakeDto: IntakeDTO): Observable<Intake> {
    // Put call to api/workflow/ID/intake
    const url = this.workflowUrl + this.workflow.id + '/intake';

    return this.http.put<Intake>(url, intakeDto).pipe(
      tap((data: Intake) => {
        this.intake = data;
      })
    );
  }

  /**
   * api call to save signature for intake form
   * @param signature
   */
  saveIntakeSignature(signature: Signature): Observable<Workflow> {
    // Put call to api/workflow/ID/intake/sign
    const url = this.workflowUrl + this.workflow.id + '/intake/sign';

    return this.http.put<Workflow>(url, signature).pipe(
      tap((data: Workflow) => {
        this.setWorkflow(data, false);
      })
    );
  }

  /**
   *
   * @param workflowId
   */
  getRegistrationPdf(workflowId: string) {
    // Get call to api/workflow/ID/registration/pdf
    const url = this.workflowUrl + workflowId + '/registration/pdf';
    this.getPdfForUrl(workflowId,url,this.REGISTRATION_PREFIX)
  }

  getSignUpPdf(workflowId: string) {
    // Get call to api/workflow/ID/signup/pdf
    const url = this.workflowUrl + workflowId + '/signup/pdf';
    this.getPdfForUrl(workflowId,url,this.SIGNUP_PREFIX)
  }

  getIntakePdf(workflowId: string) {
    // Get call to api/workflow/ID/intake/pdf
    const url = this.workflowUrl + workflowId + '/intake/pdf';
    this.getPdfForUrl(workflowId,url,this.INTAKE_PREFIX)
  }

  getPdfForUrl(workflowId: string, url: string, prefix: string) {
    let headers = new HttpHeaders();

    headers = headers.set('Accept', 'application/pdf');

    this.http.get(url, {headers: headers, responseType: 'blob'}).subscribe((data) => {
      const blob = new Blob([data], {type: 'application/pdf'});
      saveAs(blob, prefix + workflowId + '.pdf');
    });
  }

  checkRegistrationPdf(id: string): Observable<boolean> {
    // Get call to api/workflow/ID/registration/checkpdf
    const url = this.workflowUrl + id + '/registration/checkpdf';
    return this.http.get<boolean>(url);
  }
  checkIntakePdf(id: string): Observable<boolean> {
    // Get call to api/workflow/ID/intake/checkpdf
    const url = this.workflowUrl + id + '/intake/checkpdf';
    return this.http.get<boolean>(url);
  }

  checkSignupPdf(id: string): Observable<boolean> {
    // Get call to api/workflow/ID/signup/checkpdf
    const url = this.workflowUrl + id + '/signup/checkpdf';
    return this.http.get<boolean>(url);
  }
}
