<div [formGroup]="form">
  <input  type="file"
          [class.is-invalid]="showError"
          class="form-control-file"
          [formControlName]="field?.varName"
          [id]="field?.varName"
          [accept]="acceptedFiles"
          multiple="multiple"
          (change)="onUpload($event)"
  />
  <div class="row" *ngIf="getFiles().length !== 0 || documentsInProgress.length !== 0">
    <div class="col-sm-12 form-group app-uploader">
      <table class="table table-borderless table-sm">
        <thead>
        <tr>
          <th>#</th>
          <th>Bestand</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of getFiles(); let i = index" [ngClass]="{'alert-danger' : item.malwareDetected}">
          <th class="number-column">{{i + 1}}</th>
          <td>{{item.originalFileName}} <div *ngIf="item.malwareDetected">{{item.malwareName}} aangetroffen</div></td>
          <td class="text-right"><button type="button" class="btn btn-danger btn-sm" (click)="onRemove($event,item)" *ngIf="!checking"><i class="fas fa-trash" aria-hidden="true"></i></button></td>
        </tr>
        <tr *ngFor="let item of documentsInProgress; let i = index">
          <td></td>
          <td>
            <div class="progress" *ngIf="item.uploadProgress">
              <progressbar [animate]="false" [value]="item.uploadProgress" type="success"><b>{{item.uploadProgress}}% - {{item.name}}</b></progressbar>
            </div>
          </td>
          <td></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
