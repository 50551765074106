import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Router} from '@angular/router';
import {Particularities, SignUp} from '../../shared/model/signup';
import {SignUpService} from '../../shared/services/signup.service';
import {DynamicSectionComponent} from "../../shared/abstract/dynamic-section-component";
import {FormService} from "../../shared/services/form.service";
import {FormType, SectionType} from "../../shared/model/formSection";
import {ConditionType, FormField} from "../../shared/model/formField";
import {NotificationService} from "../../shared/services/notification.service";
import { BsModalService } from 'ngx-bootstrap/modal';
import {RuleEvaluatorService} from "../../shared/services/rule-evaluator.service";
import {ScrollService} from "../../shared/services/scroll.service";

@Component({
  selector: 'app-particularities-dynamic',
  templateUrl: '../../shared/abstract/dynamic-section.component.html'
})
export class ParticularitiesDynamicComponent extends DynamicSectionComponent implements OnInit {
  @Input()
  checking: boolean;

  signUp: SignUp;
  particularities: Particularities = {
    hasMedicalDiagnosis: false,
    hasMedicalParticularities: false,
    hasMedicationUse: false,
    hasClusterIndication: false,
    hasNeedForSupport: false,
    hasOpp: false,
    clusterIndication: [
      { validUntil: '' },
      { validUntil: '' },
      { validUntil: '' },
      { validUntil: '' }
    ],
    customQuestionField: {}
  } as Particularities;

  constructor(router: Router,
              notificationService: NotificationService,
              ruleEvaluatorService: RuleEvaluatorService,
              private formBuilder: FormBuilder,
              formService: FormService,
              private signUpService: SignUpService,
              modalService: BsModalService,
              scrollService: ScrollService) {
    super(router,formService,notificationService,modalService, ruleEvaluatorService, scrollService, SectionType.PARTICULARITIES, FormType.SIGNUP)
    this.formGroup = this.formBuilder.group({});
  }

  ngOnInit() {
    this.scrollService.onActivate(true); //Scroll to top of page when new step is opened

    this.signUp = this.signUpService.getSignUp();

    if (this.signUp.particularities !== undefined && this.signUp.particularities !== null) {
      this.particularities = this.signUp.particularities;
      if (!this.particularities.clusterIndication) {
        this.particularities.clusterIndication = [
          { validUntil: '' },
          { validUntil: '' },
          { validUntil: '' },
          { validUntil: '' }
        ];
      }
      this.initCustomQuestions(this.particularities);
    }
    super.commonNgOnInit();
    if (!this.getSection()) return;
    this.getSection().fields.forEach(fld => {
      if (fld.varName === 'hasMedicalDiagnosis'
        || fld.varName === 'hasNeedForSupport'
        || fld.varName === 'hasMedicationUse'
        || fld.varName === 'hasOpp'
        || fld.varName === 'hasClusterIndication'
        || fld.varName === 'hasMedicalParticularities'
      ) {
        fld.changeFn = this.onChoiceChange;
      }
      this.checkControl(fld,this.particularities, this.signUp);
    });
  }

  checkCondition(fld: FormField): boolean {
    let checkOk = true;
    fld.condition.forEach(condition => {
      switch (condition) {
        case ConditionType.MEDICAL_DIAGNOSIS:
          if (this.particularities.hasMedicalDiagnosis.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName, this.particularities);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.HAS_OPP:
          if (this.particularities.hasOpp.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName, this.particularities);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.NEED_FOR_SUPPORT:
          if (this.particularities.hasNeedForSupport.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName, this.particularities);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.CLUSTER_INDICATION:
          if (this.particularities.hasClusterIndication.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName, this.particularities);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.MEDICAL_PARTICULARITIES:
          if (this.particularities.hasMedicalParticularities.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName, this.particularities);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.HAS_MEDICATION_USE:
          if (this.particularities.hasMedicationUse.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName, this.particularities);
            checkOk = false;
            return false;
          }
          break;


      }
    })
    return checkOk;
  }

  onChoiceChange = (): void => {
    this.setValuesFromFormGroup(this.particularities);

    this.getSection().fields.forEach(fld => {
      this.checkControl(fld,this.particularities, this.signUp);
    })
  }

  save(): boolean {
    if (!this.prepareSave(this.particularities)) return false;

    this.prepareCustomQuestionsForSave(this.particularities);

    this.signUpService.saveParticularities(this.particularities).subscribe(() => {
      this.isSubmitted = false;
      this.goToNext(this.signUp);
    }, (err) => {
      this.getRouter().navigate(['/home']);
      this.handleSaveError(err);
    });

    return true;
  }
}
