import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { SchoolService } from "./school.service";
import { take } from "rxjs";
import { SignUpService } from "./signup.service";
import { SchoolConfiguration } from "../model/schoolConfiguration";
import { FormService } from "./form.service";
import { ColorService } from "./color.service";
import { SignUp } from "../model/signup";
import {SchoolYear} from "../model/schoolYear";
import {SchoolYearService} from "./schoolYear.service";
import {tap} from "rxjs/operators";
import {StatusService} from "./status.service";

@Injectable()
export class DirectLinkService {

  school: SchoolConfiguration;
  schoolYear: SchoolYear;

  constructor(
    private router: Router,
    private schoolService: SchoolService,
    private schoolYearService: SchoolYearService,
    private signUpService: SignUpService,
    private statusService: StatusService,
    private formService: FormService,
    private colorService: ColorService
  ) {}

  navigate(directLink: string): void {
    if (this.statusService.getPortalType() === 'PO') {
      this.router.navigate(['/home']);
      return;
    }
    this.schoolService.getSchoolByDirectLink(directLink)
      .pipe(
        tap({
          next: (school) => {
            localStorage.removeItem('directLink');
            this.school = school;
            if (this.school === null || this.school.isEnabled === null || !this.school.isEnabled) {
              this.router.navigate(['/home']);
              return;
            }
            this.schoolYearService.getSchoolYears()
              .pipe(take(1))
              .subscribe((years) => {
                let yearArray = years.filter(year => year.schoolYear === this.school.schoolYear);
                this.school.isInflux = yearArray[0].isInfluxYear;
              })
            this.existingSignUpCheck();
          },
          error: (_) => {
            this.router.navigate(['/home']);
          }
        }))
      .subscribe();
  }

  existingSignUpCheck(): void {
    this.signUpService.getOpenSignUpsForSchool(this.school.id)
      .pipe(take(1))
      .subscribe((signUps) => {
        if (signUps.length >= 2) {
          // More than 1 open signup, send to home
          this.router.navigate(['/home']);
        } else if (signUps.length === 0) {
          // No open signups, start new
          this.goToSchool();
        } else {
          // 1 open signup, continue on that signup
          this.edit(signUps[0]);
        }
      })
  }

  goToSchool(): void {
    this.schoolService.setSchool(this.school);
    this.colorService.changeColor(this.school.stylingId);
    this.formService.getFormById(this.school.formId).subscribe(form => {
      this.formService.setForm(form);
      this.router.navigate(['/school']);
    });
  }

  edit(signup: SignUp): void {
    this.schoolService.setSchool(this.school);
    this.colorService.changeColor(this.school.stylingId);
    this.signUpService.setSignUp(signup);

    const routerCommand = '/' + signup.lastStep;
    if (signup.formId !== undefined && signup.formId !== null) {
      this.formService.getFormById(signup.formId).subscribe(form => {
        this.formService.setForm(form);
        this.router.navigate([routerCommand]);
      });
    } else {
      this.formService.getFormById(this.school.formId).subscribe(form => {
        this.formService.setForm(form);
        this.router.navigate([routerCommand]);
      });
    }
  }
}
