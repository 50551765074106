import { SignDynamicComponent } from './modules/sign-dynamic/sign-dynamic.component';
import { FamilyDynamicComponent } from './modules/family-dynamic/family-dynamic.component';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppHeaderComponent } from './shared/components/app-header';
import { AppFooterComponent } from './shared/components/app-footer';
import { ModalContentComponent } from './shared/components/app-modal/app-modal-content.component';
import { ParentDynamicComponent } from './modules/parent-dynamic/parent-dynamic.component';
import { HomeComponent } from './modules/home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { SignUpService } from './shared/services/signup.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PreviousSchoolDynamicComponent } from './modules/previous-school-dynamic/previous-school-dynamic.component';
import { FileService } from './shared/services/file.service';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AddressService } from './shared/services/address.service';
import { SchoolAutocompleteService } from './shared/services/schoolautocomplete.service';

import { FormService } from './shared/services/form.service';
import { SchoolService } from './shared/services/school.service';
import { SchoolYearService } from './shared/services/schoolYear.service';
import { ColorService } from './shared/services/color.service';
import { NotfoundComponent } from './modules/notfound/notfound.component';
import { AppStappenplanComponent } from './shared/components/app-stappenplan';
import { BedanktComponent } from './modules/bedankt/bedankt.component';
import { PageService } from './shared/services/page.service';
import { BsnValidator } from './shared/validators/bsncheck.directive';
import { OwnValidator } from './shared/validators/owncheck.directive';
import { IbanValidator } from './shared/validators/iban-validator.directive';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { environment } from '../environments/environment';
import { IndexComponent } from './modules/index/index.component';
import { CountryService } from './shared/services/country.service';
import { SafeHtmlPipe } from './shared/pipes/safehtml.pipe';
import { DateCheckDirective } from './shared/validators/datecheck.directive';
import { ClusterCheckDirective } from './shared/validators/clustercheck.directive';

import { NgSelectModule } from '@ng-select/ng-select';
import { TextFormatService } from './shared/services/textformat.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {KeycloakAngularModule, KeycloakEventType, KeycloakService} from "keycloak-angular";
import { StudentDynamicComponent } from "./modules/student-dynamic/student-dynamic.component";
import { SchoolDynamicComponent } from "./modules/school-dynamic/school-dynamic.component";
import { DynamicFormfieldComponent } from "./shared/input/dynamic-formfield/dynamic-formfield.component";
import { ParticularitiesDynamicComponent } from "./modules/particularities-dynamic/particularities-dynamic.component";
import { AdditionalDynamicComponent } from "./modules/additional-dynamic/additional-dynamic.component";
import { PageComponent } from './modules/page/page.component';
import { StatusService } from "./shared/services/status.service";
import { noop } from "rxjs";
import { ToastrModule } from "ngx-toastr";
import {NotificationService} from "./shared/services/notification.service";
import {PoStudentDynamicComponent} from "./modules/po-student-dynamic/po-student-dynamic.component";
import {PoParentDynamicComponent} from "./modules/po-parent-dynamic/po-parent-dynamic.component";
import {PoSignAanmeldingDynamicComponent} from "./modules/po-sign-aanmelding-dynamic/po-sign-aanmelding-dynamic.component";
import {PoBedanktComponent} from "./modules/po-bedankt/po-bedankt.component";
import {WorkflowService} from "./shared/services/workflow.service";
import { HomePoOverviewComponent } from './modules/home/home-po-overview/home-po-overview.component';
import { HomeOverviewComponent } from './modules/home/home-overview/home-overview.component';
import {NgForOf} from "@angular/common";
import { HomeStartComponent } from './modules/home/home-start/home-start.component';
import { HomePoStartComponent } from './modules/home/home-po-start/home-po-start.component';
import { HomeSelectSchoolComponent } from './modules/home/home-select-school/home-select-school.component';
import {DirectLinkComponent} from "./modules/direct-link/direct-link.component";
import {DirectLinkService} from "./shared/services/direct-link.service";
import {PoCustomIntakeDynamicComponent} from "./modules/po-intake/po-custom-intake-dynamic.component";
import {PoSignIntakeDynamicComponent} from "./modules/po-intake/po-sign-intake-dynamic.component";
import {UploaderComponent} from "./shared/components/app-uploader/app-uploader.component";
import {ProgressbarModule} from "ngx-bootstrap/progressbar";
import {ScrollService} from "./shared/services/scroll.service";

function initializeKeycloak(keycloak: KeycloakService) {
  let cfg = environment.keycloakConfig;
  cfg.realm = window.location.hostname.substring(0,4) + 'ap';

  keycloak.keycloakEvents$.subscribe({
    next: (e) => {
      if (e.type == KeycloakEventType.OnTokenExpired) {
        keycloak.updateToken(20).then(noop);
      }
    },
  });

  return () =>
    keycloak.init({
      config: cfg,
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri:
          window.location.origin + '/assets/silent-check-sso.html'
      },
      bearerExcludedUrls: ['/contact', '/faq', '/privacy', '/welkom', '/gesloten']
    });
}

@NgModule({ declarations: [
        AppComponent,
        AppHeaderComponent,
        AppFooterComponent,
        ModalContentComponent,
        IndexComponent,
        NotfoundComponent,
        DirectLinkComponent,
        BsnValidator,
        OwnValidator,
        DateCheckDirective,
        ClusterCheckDirective,
        IbanValidator,
        HomeComponent,
        HomeOverviewComponent,
        HomePoOverviewComponent,
        PageComponent,
        AppStappenplanComponent,
        StudentDynamicComponent,
        SchoolDynamicComponent,
        ParentDynamicComponent,
        FamilyDynamicComponent,
        PreviousSchoolDynamicComponent,
        ParticularitiesDynamicComponent,
        AdditionalDynamicComponent,
        SignDynamicComponent,
        PoStudentDynamicComponent,
        PoParentDynamicComponent,
        PoSignAanmeldingDynamicComponent,
        PoCustomIntakeDynamicComponent,
        PoSignIntakeDynamicComponent,
        PoBedanktComponent,
        BedanktComponent,
        DynamicFormfieldComponent,
        UploaderComponent,
        SafeHtmlPipe,
        HomeStartComponent,
        HomePoStartComponent,
        HomeSelectSchoolComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        KeycloakAngularModule,
        ReactiveFormsModule,
        FormsModule,
        AppRoutingModule,
        NgSelectModule,
        TypeaheadModule.forRoot(),
        ModalModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-center',
            preventDuplicates: true
        }),
        NgForOf,
        NgForOf, ProgressbarModule], providers: [
        SignUpService,
        WorkflowService,
        SchoolService,
        ScrollService,
        SchoolYearService,
        StatusService,
        FormService,
        FileService,
        PageService,
        AddressService,
        BsModalService,
        SchoolAutocompleteService,
        ColorService,
        CountryService,
        TextFormatService,
        NotificationService,
        DirectLinkService,
        {
            provide: APP_INITIALIZER,
            useFactory: initializeKeycloak,
            multi: true,
            deps: [KeycloakService]
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
