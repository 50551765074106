import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
  EducationType,
  Lesson,
  Level,
  Profile,
  SchoolConfiguration,
  SchoolLocation,
  Year
} from '../../shared/model/schoolConfiguration';
import {School, SignUp} from '../../shared/model/signup';
import {SchoolService} from '../../shared/services/school.service';
import {SignUpService} from '../../shared/services/signup.service';
import {FormService} from "../../shared/services/form.service";
import {DynamicSectionComponent} from "../../shared/abstract/dynamic-section-component";
import {FormType, SectionType} from "../../shared/model/formSection";
import {ConditionType, FormField, SelectOption} from "../../shared/model/formField";
import {NotificationService} from "../../shared/services/notification.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {RuleEvaluatorService} from "../../shared/services/rule-evaluator.service";
import {ScrollService} from "../../shared/services/scroll.service";

@Component({
  selector: 'app-school-dynamic',
  templateUrl: '../../shared/abstract/dynamic-section.component.html'
})
export class SchoolDynamicComponent extends DynamicSectionComponent implements OnInit {
  @Input()
  checking: boolean;
  formId: string;
  signUp: SignUp;
  school: SchoolConfiguration;
  selectedSchool: School = {
    schoolLocation: '',
    educationType: '',
    choice: 1
  } as School;

  schoolLocations: SchoolLocation[] = [];
  schoolLocation: SchoolLocation;
  educationTypes: EducationType[] = [];
  educationType: EducationType;
  educationYears: Year[] = [];
  educationYear: Year;
  educationLevels: Level[] = [];
  educationLevel: Level;
  educationProfiles: Profile[] = [];
  educationLessons: Lesson[] = [];

  constructor(private route: ActivatedRoute,
    router: Router,
    notificationService: NotificationService,
    ruleEvaluatorService: RuleEvaluatorService,
    private formBuilder: FormBuilder,
    private schoolService: SchoolService,
    formService: FormService,
    private signUpService: SignUpService,
    modalService: BsModalService,
    scrollService: ScrollService) {
      super(router,formService,notificationService, modalService, ruleEvaluatorService, scrollService, SectionType.SCHOOL, FormType.SIGNUP);
    this.formGroup = this.formBuilder.group({});
  }

  ngOnInit() {
    this.scrollService.onActivate(true); //Scroll to top of page when new step is opened
    let formId = sessionStorage.getItem("formulier");

    if (formId !== undefined && formId !== null) {
      this.formId = formId;
    }

    this.signUp = this.signUpService.getSignUp();
    this.school = this.schoolService.getSchool();

    // On reload, school is empty. Route back home.
    if (!this.school) {
      this.getRouter().navigate(['/home']);
      return;
    }
    this.getCurrentSection().title = this.school.name;

    if (this.signUp || this.school) {
      if (this.signUp.school !== undefined && this.signUp.school !== null) {

        this.selectedSchool = this.signUp.school;

        this.schoolLocations = this.school.locations;
        this.schoolLocation = this.school.locations.find(
          (location) => location.name === this.selectedSchool.schoolLocation);

        this.educationTypes = this.schoolLocation.educationType;
        this.educationType = this.schoolLocation.educationType.find(
          (educationType) => educationType.label === this.selectedSchool.educationType);

        this.educationYears = this.educationType.years;
        this.educationYear = this.educationType.years.find(
          (year) => year.year === this.selectedSchool.educationYear);

        this.educationLevels = this.educationYear.levels;
        if (this.educationLevels.length > 0) {
          this.educationLevel = this.educationYear.levels.find(
            (level) => level.label === this.selectedSchool.educationLevel
          );
          this.educationLessons = this.educationLevel.lessons;
          this.educationProfiles = this.educationLevel.profiles;
        }

      } else if (this.school) {
        this.signUp.isInflux = this.school.isInflux;
        this.signUp.schoolYear = this.school.schoolYear; // Used in dynamic section for startDate
        this.selectedSchool.name = this.school.name;
        this.selectedSchool.schoolId = this.school.id;
        this.selectedSchool.schoolRoleId = this.school.schoolRoleId;
        this.selectedSchool.schoolYear = this.school.schoolYear;

        this.schoolLocations = this.school.locations;
        if (this.schoolLocations.length == 1) {
          this.schoolLocation = this.schoolLocations[0];
          this.selectedSchool.schoolLocation = this.schoolLocation.name;
          this.educationTypes = this.schoolLocation.educationType;
          if (this.educationTypes.length == 1) {
            this.educationType = this.schoolLocation.educationType[0];
            this.selectedSchool.educationType = this.educationType.label;
            this.educationYears = this.educationType.years;
          }
        }
      }
    }
    this.initCustomQuestions(this.selectedSchool);

    super.commonNgOnInit();
    if (!this.getSection()) return;

    this.setAllOptions();

    this.getSection().fields.forEach(fld => {
      this.addCallbackFunctions(fld)
      this.checkHideLabel(fld);
      this.checkControl(fld,this.selectedSchool, this.signUp);
    })
  }

  addCallbackFunctions(fld: FormField): void {
    switch (fld.varName) {
      case "startDate":
        fld.changeFn = this.onStartDateChange;
        break;
      case "schoolLocation":
        fld.changeFn = this.onSchoolLocationChange;
        break;
      case "educationType":
        fld.changeFn = this.onEducationTypeChange;
        break;
      case "educationYear":
        fld.changeFn = this.onEducationYearChange;
        break;
      case "educationLevel":
        fld.changeFn = this.onEducationLevelChange;
        break;
      case "choice":
        fld.changeFn = this.onChoiceChange;
        break;
    }
  }

  setAllOptions(): void {
    this.setSchoolLocationOptions();
    this.setEducationTypeOptions();
    this.setEducationYearOptions();
    this.setEducationLevelOptions();
    this.setEducationAdviceLevelOptions();
    this.setEducationProfileOptions();
    this.setEducationLessonOptions();
  }

  checkHideLabel(fld: FormField): void {
    // hide labels ?
    switch (fld.varName) {
      case "schoolLocation":
        if (this.schoolLocations != undefined && this.schoolLocations.length > 1) { fld.hideLabel = false;
        } else {
          fld.hideLabel = true; }
        break;
      case "educationType":
        if (this.educationTypes != undefined && this.educationTypes.length > 1) { fld.hideLabel = false;
        } else { fld.hideLabel = true; }
        break;
    }
  }

  checkCondition(fld: FormField): boolean {
    let checkOk = true;
    this.checkHideLabel(fld)
    fld.condition.forEach(condition => {
      switch (condition) {
        case ConditionType.NOT_INFLUX:

          if (this.school.isInflux || this.signUp.isInflux || (this.selectedSchool.educationYear && this.selectedSchool.educationYear > 1)) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.INFLUX:
          if (!(this.school.isInflux || this.signUp.isInflux || (this.selectedSchool.educationYear && this.selectedSchool.educationYear > 1))) {

            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.LEVEL_NOT_EMPTY:
          if (this.selectedSchool.educationLevel == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.PROFILES_NOT_EMPTY:
          if (this.selectedSchool.educationLevel == undefined || this.educationLevel.profiles == undefined || this.educationLevel.profiles.length == 0) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.LESSONS_NOT_EMPTY:
          if (this.selectedSchool.educationLevel == undefined || this.educationLevel.lessons == undefined || this.educationLevel.lessons.length == 0) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.LOCATION_NOT_EMPTY:
          if (this.selectedSchool.schoolLocation == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          } else {
            fld.conditionalDisabled = false;
          }
          break;
        case ConditionType.EDUCATION_TYPE_NOT_EMPTY:
          if (this.selectedSchool.educationType == undefined || this.selectedSchool.educationType == '') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.LWOO:
          if (!this.educationLevel || !this.educationLevel.hasLwoo || this.educationLevel.hasLwoo.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.YEAR_NOT_EMPTY:
          if (this.selectedSchool.educationYear == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.FIRST_YEAR:
          if (this.selectedSchool.educationYear == undefined ||  this.selectedSchool.educationYear > 1) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.HIGHER_YEAR:
          if (this.selectedSchool.educationYear == undefined ||  this.selectedSchool.educationYear == 1) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.SECOND_CHOICE:
          if (this.selectedSchool.choice < 2) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
      }
    })
    return checkOk;
  }

  setSchoolLocationOptions(): void {
    if (this.schoolLocations == undefined) return;
    let fld = this.getFieldByVarName('schoolLocation');
    fld.options = Array();
    for (const element of this.schoolLocations) {
      let opt = new SelectOption('' + element.name, '' + element.name);
      fld.options.push(opt);
    }
  }
  setEducationTypeOptions(): void {
    if (this.educationTypes == undefined) return;
    let fld = this.getFieldByVarName('educationType');
    if (!fld) return
    fld.options = Array();
    for (const element of this.educationTypes) {
      let opt = new SelectOption('' + element.label, '' + element.label);
      fld.options.push(opt);
    }
  }

  setEducationYearOptions(): void {
    if (this.educationYears == undefined) return;
    let fld = this.getFieldByVarName('educationYear');
    if (!fld) return
    fld.options = Array();
    for (const element of this.educationYears) {
      let opt = new SelectOption('' + element.year, '' + element.year);
      fld.options.push(opt);
    }
  }

  setEducationLevelOptions(): void {
    if (this.educationLevels == undefined) return;
    let fld = this.getFieldByVarName('educationLevel');
    if (!fld) return
    fld.options = Array();
    for (const element of this.educationLevels) {
      let opt = new SelectOption(element.label, element.label);
      fld.options.push(opt);
    }
  }
  setEducationAdviceLevelOptions(): void {
    if (this.school == undefined) return;
    let fld = this.getFieldByVarName('educationLevelAdvice');
    if (!fld) return
    fld.options = Array();
    if (this.schoolLocation) {
      for (const element of this.schoolLocation.educationAdviceLevel) {
        let opt = new SelectOption(element.label, element.label);
        fld.options.push(opt);
      }
    }
  }
  setEducationProfileOptions(): void {
    if (this.educationLevel == undefined || this.educationLevel.profiles == undefined) return;
    let fld = this.getFieldByVarName('educationProfile');
    if (!fld) return
    fld.options = Array();
    for (const element of this.educationLevel.profiles) {
      let opt = new SelectOption(element.label, element.label);
      fld.options.push(opt);
    }
  }
  setEducationLessonOptions(): void {
    if (this.educationLevel == undefined || this.educationLevel.lessons == undefined) return;
    let fld = this.getFieldByVarName('educationLesson');
    if (!fld) return
    fld.options = Array();
    for (const element of this.educationLevel.lessons) {
      let opt = new SelectOption(element.label, element.label);
      fld.options.push(opt);
    }
  }

  onSchoolLocationChange = (): void => {
    if (this.formGroup && this.formGroup.controls && this.formGroup.controls['schoolLocation']) {
      this.selectedSchool.schoolLocation = this.formGroup.controls['schoolLocation'].value;
    }

    this.selectedSchool.educationType = '';
    this.selectedSchool.educationYear = undefined;
    this.selectedSchool.educationLevelAdvice = undefined;
    this.selectedSchool.educationLevel = undefined;
    this.selectedSchool.educationProfile = undefined;
    this.selectedSchool.educationLesson = undefined;

    this.educationProfiles = [];
    this.educationLessons = [];

    this.schoolLocation= this.school.locations.find(
      (location) => location.name === this.selectedSchool.schoolLocation);

    if (this.schoolLocation) {
      this.educationTypes = this.schoolLocation.educationType;
      if (this.educationTypes.length == 1) {
        this.educationType = this.educationTypes[0];
        this.selectedSchool.educationType = this.educationType.label;
        this.educationYears = this.educationType.years;
      }
    }

    this.setEducationTypeOptions();
    this.checkControlByVarName('educationType',this.selectedSchool);
    this.setEducationYearOptions();
    this.checkControlByVarName('educationYear',this.selectedSchool);
    this.checkControlByVarName('choice',this.selectedSchool);
    this.checkControlByVarName('firstChoiceSchoolName',this.selectedSchool);
  }

  onEducationTypeChange = (): void => {
    if (this.formGroup && this.formGroup.controls && this.formGroup.controls['educationType']) {
      this.selectedSchool.educationType = this.formGroup.controls['educationType'].value;
    }

    this.selectedSchool.educationYear = undefined;
    this.selectedSchool.educationLevelAdvice = undefined;
    this.selectedSchool.educationLevel = undefined;
    this.selectedSchool.educationProfile = undefined;
    this.selectedSchool.educationLesson = undefined;

    this.educationProfiles = [];
    this.educationLessons = [];

    this.schoolLocation= this.school.locations.find(
      (location) => location.name === this.selectedSchool.schoolLocation);

    this.educationType = this.schoolLocation.educationType.find(
      (educationType) => educationType.label === this.selectedSchool.educationType);
    this.educationYears = this.educationType.years;

    this.checkControlByVarName('educationYear',this.selectedSchool);
    this.setEducationYearOptions();

    this.checkControlByVarName('educationLevelAdvice',this.selectedSchool);
    this.checkControlByVarName('educationLevel',this.selectedSchool);
    this.checkControlByVarName('educationProfile',this.selectedSchool);
    this.checkControlByVarName('educationLesson',this.selectedSchool);
    this.setEducationAdviceLevelOptions();
    this.setEducationLevelOptions();
  }
  onStartDateChange= (): void => {
    this.selectedSchool.startDate = this.formGroup.controls['startDate'].value;
  }
  onEducationYearChange = (): void => {
    this.selectedSchool.educationYear = +this.formGroup.controls['educationYear'].value;

    this.selectedSchool.educationLevelAdvice = undefined;
    this.selectedSchool.educationLevel = undefined;
    this.selectedSchool.educationProfile = undefined;
    this.selectedSchool.educationLesson = undefined;

    if (this.selectedSchool.educationYear === 1) {
      this.signUp.isInflux = this.school.isInflux;
      // zet startdate alleen terug voor het nieuwe schooljaar, niet voor zij-instroom
      if (!this.signUp.isInflux) {
        this.formGroup.controls['startDate']?.setValue(null);
        this.selectedSchool.startDate = this.formGroup.controls['startDate']?.value;
      }
    }

    this.educationProfiles = [];
    this.educationLessons = [];

    this.schoolLocation= this.school.locations.find(
      (location) => location.name === this.selectedSchool.schoolLocation);

    this.educationType = this.schoolLocation.educationType.find(
      (educationType) => educationType.label === this.selectedSchool.educationType);

    this.educationYear = this.educationType.years.find(
      (year) => year.year === this.selectedSchool.educationYear);

    this.educationLevels = this.educationYear.levels;
    if (this.educationLevels.length == 1) {
      this.educationLevel = this.educationLevels[0];
      this.selectedSchool.educationLevel = this.educationLevel.label;
      this.setEducationProfileOptions();
      this.setEducationLessonOptions();
    }

    this.checkControlByVarName('educationLevelAdvice',this.selectedSchool);
    this.checkControlByVarName('educationLevel',this.selectedSchool);
    this.checkControlByVarName('educationProfile',this.selectedSchool);
    this.checkControlByVarName('educationLesson',this.selectedSchool);

    this.checkControlByVarName('startDate',this.selectedSchool);

    this.setEducationAdviceLevelOptions();
    this.setEducationLevelOptions();

    this.checkControlByVarName('choice',this.selectedSchool);
    this.checkControlByVarName('firstChoiceSchoolName',this.selectedSchool);
  }

  onEducationLevelChange = (): void => {
    this.selectedSchool.educationLevel = this.formGroup.controls['educationLevel'].value;

    this.selectedSchool.educationProfile = undefined;
    this.selectedSchool.educationLesson = undefined;

    this.schoolLocation= this.school.locations.find(
      (location) => location.name === this.selectedSchool.schoolLocation);

    this.educationType = this.schoolLocation.educationType.find(
      (educationType) => educationType.label === this.selectedSchool.educationType);

    this.educationYear = this.educationType.years.find(
      (year) => year.year === this.selectedSchool.educationYear);

    this.educationLevel = this.educationYear.levels.find(
      (level) => level.label === this.selectedSchool.educationLevel
    );

    this.educationProfiles = this.educationLevel.profiles;
    this.educationLessons = this.educationLevel.lessons;

    this.checkControlByVarName('educationLwoo',this.selectedSchool);
    this.checkControlByVarName('educationProfile',this.selectedSchool);
    this.checkControlByVarName('educationLesson',this.selectedSchool);

    this.setEducationProfileOptions();
    this.setEducationLessonOptions();
  }

  onChoiceChange = (): void => {
    this.selectedSchool.choice = this.formGroup.controls['choice'].value;
    this.checkControlByVarName('firstChoiceSchoolName',this.selectedSchool);
  }

  save(): boolean {
    if (!this.prepareSave(this.selectedSchool)) return false;
    this.prepareCustomQuestionsForSave(this.selectedSchool);

    if (this.signUp.id) {
      this.signUpService.saveSchool(this.selectedSchool).subscribe((signup) => {
        this.isSubmitted = false;
        this.goToNext(signup);
      }, (err) => {
        this.getRouter().navigate(['/home']);
        this.handleSaveError(err);
      });
    } else {
      this.signUpService.start(this.selectedSchool, this.formId).subscribe((signup) => {
        this.isSubmitted = false;
        this.goToNext(signup);
      });
    }
    return false;
  }

}
