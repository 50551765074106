import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private readonly toastr: ToastrService) {}
  public ERROR_TEXT_NOT_FOUND: string = "De aanmelding is niet gevonden, aanpassingen zijn niet mogelijk";
  public ERROR_TEXT_COMPLETED: string = "De aanmelding is al ondertekend en verstuurd, aanpassingen zijn niet meer mogelijk";

  success(action: string, message: string): void {
    this.toastr.success(message, action);
  }

  warning(action: string, message: string): void {
    this.toastr.warning(message, action);
  }

  error(action: string, message: string): void {
    this.toastr.error(message, action);
  }

  signupNotFoundError(): void {
    this.toastr.error(this.ERROR_TEXT_NOT_FOUND, '');
  }

  signupAlreadyCompletedError(): void {
    this.toastr.error(this.ERROR_TEXT_COMPLETED, '');
  }

}
