import {AbstractControl, Validators} from "@angular/forms";

export function ValidateHouseNumber(control: AbstractControl) {
  const value = typeof control.value === 'string' ? control.value : String(control.value);

  if (!value) {
    return null;
  }

  let parsedValue: string = String(value);
  const regexp = new RegExp("^[1-9][0-9]{0,4}$");
  if (!regexp.test(parsedValue)) {
    return {
      'appHouseNumber': {value: value}
    }
  }

  return null;
}
