import { Injectable } from '@angular/core';
import {
  AdditionalInformation,
  CareTakers,
  Family,
  Particularities,
  School,
  SchoolOfOrigin,
  Signature,
  SignUp,
  Student
} from '../model/signup';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import saveAs from 'file-saver';
import { tap } from 'rxjs/operators';


@Injectable()
export class SignUpService {
  private signUpUrl = 'api/signup/';  // URL to web api

  private signUp: SignUp = {} as SignUp;

  constructor(private http: HttpClient) {
  }

  setSignUp(signUp: SignUp) {
    this.signUp = signUp;
  }

  getSignUp(): SignUp {
    return this.signUp;
  }

  getPdf(id: string): void {
    const url = this.signUpUrl + id + '/pdf';

    let headers = new HttpHeaders();

    headers = headers.set('Accept', 'application/pdf');

    this.http.get(url, {headers: headers, responseType: 'blob'}).subscribe((data) => {
      const blob = new Blob([data], {type: 'application/pdf'});
      saveAs(blob, id + '.pdf');
    });
  }

  checkPdf(id: string): Observable<boolean> {
    const url = this.signUpUrl + id + '/checkpdf';
    return this.http.get<boolean>(url);
  }

  getSignUps(): Observable<SignUp[]> {
    return this.http.get<SignUp[]>(this.signUpUrl);
  }

  getOpenSignUpsForSchool(schoolId: string): Observable<SignUp[]> {
    return this.http.get<SignUp[]>(this.signUpUrl + 'school/' + schoolId);
  }

  delete(signUpId: string): Observable<SignUp[]> {
    const deleteUrl = this.signUpUrl + signUpId;
    return this.http.delete<SignUp[]>(deleteUrl);
  }

  setStudent(data: Student) {
    this.signUp.student = data;
  }

  start(school: School, fid: string): Observable<SignUp> {
    let url = this.signUpUrl;

    if (fid !== null && fid !== undefined) {
        url = url + 'form/' + fid;
    }

    return this.http.post<SignUp>(url, school).pipe(
      tap((data: SignUp) => {
        this.signUp = data;
      }));
  }
  startApplication(student: Student, fid: string): Observable<SignUp> {
    let url = this.signUpUrl + '/start';

    if (fid !== null && fid !== undefined) {
      url = url + 'form/' + fid;
    }

    return this.http.post<SignUp>(url, student).pipe(
      tap((data: SignUp) => {
        this.signUp = data;
      }));
  }

  saveSchool(school: School): Observable<SignUp> {
    const url = this.signUpUrl + this.signUp.id + '/school';

    return this.http.post<SignUp>(url, school).pipe(
      tap((data: SignUp) => {
        this.signUp = data;
      }));
  }

  saveStudent(student: Student): Observable<any> {
    const studentUrl = this.signUpUrl + this.signUp.id + '/student';
    return this.http.post<any>(studentUrl, student);
  }

  saveCareTakers(caretakers: CareTakers): Observable<HttpResponse<SignUp>> {
    const url = this.signUpUrl + this.signUp.id + '/caretakers';

    return this.http.post<SignUp>(url, caretakers, {
      observe: "response"
    }).pipe(
      tap((data: HttpResponse<SignUp>) => {
        this.signUp = data.body;
      })
    );
  }

  saveFamily(family: Family): Observable<SignUp> {
    const familyUpUrl = this.signUpUrl + this.signUp.id + '/family';

    return this.http.post<SignUp>(familyUpUrl, family).pipe(
      tap((data: SignUp) => {
        this.signUp = data;
      })
    );
  }

  savePreviousSchool(schoolOfOrigin: SchoolOfOrigin): Observable<SignUp> {
    const previousSchoolUrl = this.signUpUrl + this.signUp.id + '/previous-school';

    return this.http.post<SignUp>(previousSchoolUrl, schoolOfOrigin).pipe(
      tap((data: SignUp) => {
        this.signUp = data;
      })
    );
  }

  saveParticularities(particularities: Particularities): Observable<SignUp> {
    const particularitiesUrl = this.signUpUrl + this.signUp.id + '/particularities';

    return this.http.post<SignUp>(particularitiesUrl, particularities).pipe(
      tap((data: SignUp) => {
        this.signUp = data;
      })
    );
  }

  saveAdditional(additional: AdditionalInformation): Observable<SignUp> {
    const additionalUrl = this.signUpUrl + this.signUp.id + '/additional';

    return this.http.post<SignUp>(additionalUrl, additional).pipe(
      tap((data: SignUp) => {
        this.signUp = data;
      })
    );
  }

  saveSignature(signature: Signature): Observable<any> {
    const url = this.signUpUrl + this.signUp.id + '/sign';
    return this.http.post<any>(url, signature);
  }
}
