import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Postalcodelookup } from '../model/postalcodelookup';

@Injectable()
export class AddressService {
    private addressUrl = 'api/addresses';

    constructor(private http: HttpClient) {
    }

    getAddresses(postalcodelookup: Postalcodelookup) {
        return this.http.post(this.addressUrl + '/', postalcodelookup);
    }
}
