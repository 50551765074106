<div class="row" *ngIf="!startRegistration">
  <div class="col signupbutton">
    <button class="btn btn-primary btn-sm" (click)="register()">Ik wil mijn kind aanmelden</button>
  </div>
</div>
<ng-container *ngIf="startRegistration">
  <div class="col-sm-12 dateinputs front-school-block">
    <p>Vul hieronder de gewenste startdatum en de geboortedatum van uw kind in, en klik op de knop "Verder"
      {{(directLink === null || directLink === undefined) ?
        " om de scholen te zien waarvoor u uw kind kunt aanmelden." :
        " om door te gaan naar het aanmeldformulier."
      }}</p>
    <form [formGroup]="dateCheckForm">
      <input
        type="text" id="startDate" formControlName="startDate" (change)="resetSchools()"
        placeholder="Gewenste startdatum op school"
        class="form-control"
      />
      <div *ngIf="dateCheckForm.controls.startDate.invalid && dateCheckForm.controls.startDate.touched">
        <div class="errorMessage">Ongeldige datum, gebruik formaat dd-mm-jjjj en datum in de toekomst</div>
      </div>
      <br />
      <input
        type="text"
        id="birthDate" formControlName="birthDate" (change)="resetSchools()"
        placeholder="Geboortedatum kind"
        class="form-control"
      />
      <div *ngIf="dateCheckForm.controls.birthDate.invalid && dateCheckForm.controls.birthDate.touched">
        <div class="errorMessage">Ongeldige datum, gebruik formaat dd-mm-jjjj</div>
      </div>
      <div class="buttons">
        <button
          class="btn btn-info btn-sm"
          *ngIf="!errorMessage"
          [disabled]="!validDates"
          (click)="selectSchools()"
        >
          Verder
        </button>
        <button class="btn btn-default btn-sm" (click)="cancel()">Annuleren</button>
      </div>
      <div class="errorMessage" *ngIf="errorMessage">
        {{errorMessage}}
        <br />
        <button class="btn btn-danger btn-sm" (click)="resetError()">Sluiten</button>
      </div>

    </form>
  </div>

  <div class="row" *ngIf="schools.length > 0 && directLinkValid === false">
    <div class="col">
      <p><strong>
        Kies hieronder de school waar u uw kind voor wilt aanmelden.
      </strong></p>
    </div>
  </div>

  <div class="front-school-block" *ngIf="schools.length > 0 && directLinkValid === false">
    <div>
      <app-home-select-school [schools]="filteredSchools" (schoolSelected)="goToSchool($event)"></app-home-select-school>
    </div>
  </div>
</ng-container>

