<div class="row">
  <div class="col">
    <h1>Aanmeldingen</h1>
    <hr/>
    <p *ngIf="this.signUps.length === 0 && this.workflows.length === 0">U heeft nog geen aanmeldingen in bewerking of afgerond!</p>
    <div *ngIf="this.signUps.length > 0">
      <app-home-overview [signUps]="signUps" [formId]="formId"></app-home-overview>
    </div>
    <div *ngIf="this.workflows.length > 0">
      <app-home-po-overview [workflows]="workflows" [formId]="formId" [intakeByParent]="intakeByParent"></app-home-po-overview>

    </div>

  </div>
</div>
<div *ngIf="!isPo">
  <app-home-start [formId]="formId"></app-home-start>
</div>
<div *ngIf="isPo">
  <app-home-po-start [formId]="formId"></app-home-po-start>
</div>
<br/>
