<footer class="app-footer hide-for-small-only">
  <div class="container main-footer">
    <div class="row">
      <div class="col-md-3 footer-logo-img">
        <span class="helper"></span>
        <img src="{{colorService.footerLogo}}">
      </div>

        <nav class="col-md-9 footer-links">
          <ul>
            <li>
              <a routerLink="/privacy" (click)="changeColor('common')">Privacy <i class="fas fa-angle-right"></i></a>
            </li>
            <li>
              <a routerLink="/faq" (click)="changeColor('common')">Veelgestelde vragen <i class="fas fa-angle-right"></i></a>
            </li>
            <li>
              <a routerLink="/contact" (click)="changeColor('common')">Contact <i class="fas fa-angle-right"></i></a>
            </li>
          </ul>
        </nav>

    </div>
  </div>
</footer>
