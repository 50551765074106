import { Directive, Input } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';


@Directive({
    selector: '[appDateCheck]',
    providers: [ { provide: NG_VALIDATORS, useExisting: DateCheckDirective, multi: true } ]
})
export class DateCheckDirective implements Validator {
    @Input()
    appDateCheck: string;

    validate(control: AbstractControl): {[key: string]: any} | null {
        const value = control.value;

        if (!value) {
            return null;
        }

        if (value.length === 10) {
            const day = Number(value.substr(0, 2));
            const month = Number(value.substr(3, 2)) - 1;
            const year = Number(value.substr(6, 4));

            const date = new Date(year, month, day);
            const isValid = date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
            const isInPast = date.getTime() < new Date().getTime();
            if (isValid && isInPast) {
                return null;
            }
        }
        return {'appDateCheck': { value: value } };
    }
}
