import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {SchoolYear} from "../model/schoolYear";

@Injectable()
export class SchoolYearService {

  private schoolYearConfigurationUrl = 'api/schoolyear/';  // URL to web api
  private selectedSchoolYear: SchoolYear;
  /**
   * Class verantwoordelijk voor het ophalen van de schooljaren / school informatie
   */
  constructor(private http: HttpClient) { }

  setSchoolYear(schoolYear: SchoolYear) {
    this.selectedSchoolYear = schoolYear;
  }
  getSchoolYear(): SchoolYear {
    return this.selectedSchoolYear;
  }
  getSchoolYears(): Observable<SchoolYear[]> {
    return this.http.get<SchoolYear[]>(this.schoolYearConfigurationUrl);
  }

}
