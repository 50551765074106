import {CustomQuestion} from "./signup";
import {SectionType} from "./formSection";

export interface CustomForm {
  id?: string;
  formId: string;
  createdAt: string;
  modifiedAt: string;
  lastStep: string;
  isCompleted: boolean;
  customSections: CustomSection[];
}

export class CustomSection {
  name: string;
  type: SectionType;

  customQuestions: CustomQuestion[];
  customQuestionField: any;
}
export function getCustomSectionByType(
  customForm: CustomForm,
  sectionType: string
): CustomSection {
  let sect = customForm.customSections?.find(
    (s) => s.type == sectionType
  );
  return sect;
}

export function getCustomFormValue(
  formDefinitionSectionMap: any,
  customForm: CustomForm,
  sectionType: string,
  varName: string
): string {
  let valueContainer =
    customForm?.[formDefinitionSectionMap[sectionType]];
  if (varName.startsWith('customQuestionField')) {
    if (valueContainer) {
      valueContainer = valueContainer.customQuestions;
    } else {
      valueContainer = getCustomSectionByType(
        customForm,
        sectionType
      )?.customQuestions;
    }
    return valueContainer?.find((item) => item.varName === varName)?.value;
  }
  else if (varName.indexOf('.') > 0) {
    return varName
      .split('.')
      .reduce((acc: any, next: string): any => acc?.[next], valueContainer);
  }
  if (valueContainer) {
    return valueContainer[varName]
  }
  else {
    return undefined;
  }
};
