import { Component, OnInit } from "@angular/core";
import { StatusService } from "../../shared/services/status.service";
import {ActivatedRoute, Router} from "@angular/router";
import {KeycloakService} from "keycloak-angular";
import {DirectLinkService} from "../../shared/services/direct-link.service";

@Component({
  selector: 'app-direct-link',
  styleUrls: ['./direct-link.component.html'],
  templateUrl: './direct-link.component.html'
})
export class DirectLinkComponent implements OnInit {

  constructor(
    private statusService: StatusService,
    private router: Router,
    private readonly route: ActivatedRoute,
    private keycloakService: KeycloakService,
    private directLinkService: DirectLinkService
  ) {
  }

  ngOnInit() {
    // Set direct link in application storage
    const directLink = this.route.snapshot.paramMap.get('dl');
    localStorage.setItem("directLink", directLink);

    // Go to welcome page if not logged in
    if (!this.keycloakService.isLoggedIn()) {
      this.router.navigate(['']);
      return;
    } else {
      this.statusService.getStatus()
          .subscribe((s) => {
            this.statusService.setPortalType(s.portalType);
            if (s.portalOpen) {
              this.directLinkService.navigate(directLink);
            }
            else {
              this.router.navigate(['']);
            }
          });
    }
  }
}
