import { Injectable, OnInit } from "@angular/core";

@Injectable()
export class ColorService implements OnInit {
  assets = '/api/css/logo/';
  currentColor = '/api/css/logo/common';
  footerLogo = '/api/css/logo/footer';

  ngOnInit(): void {
    this.currentColor = this.assets + this.currentColor;
  }

  public changeColor(stylingId: string): void {
    this.setWhitelabelCss(stylingId);
    this.changeLogo(stylingId);
  }

  private setWhitelabelCss(stylingId: string): void {
    if (stylingId === 'common') {
      const link = document.getElementById('currentColor');

      if (link !== null) {
        link.setAttribute('disabled', 'disabled');
      }
    } else if(document.getElementById('currentColor') !== null) {

      const link = document.getElementById('currentColor');

      link.removeAttribute('disabled');
      link.setAttribute('href', `/api/css/${stylingId}.css`);
    } else {
      const head = document.getElementsByTagName('head')[0];
      const link = document.createElement('link');

      link.removeAttribute('disabled');
      link.id = 'currentColor';
      link.rel = 'stylesheet';
      link.href = `/api/css/${stylingId}.css`;
      head.appendChild(link);
    }
  }

  private changeLogo(schoolId: string): void {
    this.currentColor = this.assets + schoolId;
  }
}
