import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {
  EducationType,
  Lesson,
  Level,
  Profile,
  SchoolConfiguration,
  SchoolLocation,
  Year
} from '../../shared/model/schoolConfiguration';
import {School, SignUp} from '../../shared/model/signup';
import {SchoolService} from '../../shared/services/school.service';
import {SignUpService} from '../../shared/services/signup.service';
import {FormService} from "../../shared/services/form.service";
import {DynamicSectionComponent} from "../../shared/abstract/dynamic-section-component";
import {FormType, SectionType} from "../../shared/model/formSection";
import {ConditionType, FormField, SelectOption} from "../../shared/model/formField";
import {NotificationService} from "../../shared/services/notification.service";
import {BsModalService} from "ngx-bootstrap/modal";
import {RuleEvaluatorService} from "../../shared/services/rule-evaluator.service";
import {ScrollService} from "../../shared/services/scroll.service";
import {Observable} from "rxjs";
import {map, shareReplay} from "rxjs/operators";
import {SchoolChoiceConfiguration} from "../../shared/model/SchoolChoiceConfiguration";
import {StatusService} from "../../shared/services/status.service";

@Component({
  selector: 'app-school-dynamic',
  templateUrl: '../../shared/abstract/dynamic-section.component.html'
})
export class SchoolDynamicComponent extends DynamicSectionComponent implements OnInit {
  @Input()
  checking: boolean;
  formId: string;
  signUp: SignUp;
  school: SchoolConfiguration;
  schools$: Observable<SchoolChoiceConfiguration[]>;
  selectedSchool: School = {
    schoolLocation: '',
    educationType: '',
    choice: 1
  } as School;

  referralModuleActive: boolean = false;

  schoolLocations: SchoolLocation[] = [];
  schoolLocation: SchoolLocation;
  educationTypes: EducationType[] = [];
  educationType: EducationType;
  educationYears: Year[] = [];
  educationYear: Year;
  educationLevels: Level[] = [];
  educationLevel: Level;
  educationProfiles: Profile[] = [];
  educationLessons: Lesson[] = [];
  selectedEducationLevels: { [educationType: string]: string[] } = {};
  choices = ['second', 'third', 'fourth', 'fifth'];

  constructor(private route: ActivatedRoute,
              router: Router,
              notificationService: NotificationService,
              ruleEvaluatorService: RuleEvaluatorService,
              private formBuilder: FormBuilder,
              private schoolService: SchoolService,
              private statusService: StatusService,
              formService: FormService,
              private signUpService: SignUpService,
              modalService: BsModalService,
              scrollService: ScrollService) {
    super(router,formService,notificationService, modalService, ruleEvaluatorService, scrollService, SectionType.SCHOOL, FormType.SIGNUP);
    this.formGroup = this.formBuilder.group({});
  }

  ngOnInit() {
    this.scrollService.onActivate(true);
    let formId = sessionStorage.getItem("formulier");

    if (formId !== undefined && formId !== null) {
      this.formId = formId;
    }

    this.statusService.isReferralModuleActive().subscribe((isActive) => {
      this.referralModuleActive = isActive
      if(isActive) {
        this.schools$ = this.schoolService.getReferralSchools().pipe(
          map((schoolChoiceConfigurations: SchoolChoiceConfiguration[]) => {
            const uniqueSchoolNames = new Set();

            return schoolChoiceConfigurations.filter(schoolConfig => {
              if (!uniqueSchoolNames.has(schoolConfig.name)) {
                uniqueSchoolNames.add(schoolConfig.name);
                return true;
              }
              return false;
            });
          }),
          shareReplay(1)
        );
      }

      this.signUp = this.signUpService.getSignUp();
      this.school = this.schoolService.getSchool();

      // On reload, school is empty. Route back home.
      if (!this.school) {
        this.getRouter().navigate(['/home']);
        return;
      }
      // Set school in dynamic class
      super.setSchool(this.school)
      this.getCurrentSection().title = this.school.name;

      if (this.signUp || this.school) {
        if (this.signUp.school !== undefined && this.signUp.school !== null) {

          this.selectedSchool = this.signUp.school;

          this.schoolLocations = this.school.locations;
          this.schoolLocation = this.school.locations.find(
            (location) => location.name === this.selectedSchool.schoolLocation);

          this.educationTypes = this.schoolLocation.educationType;
          this.educationType = this.schoolLocation.educationType.find(
            (educationType) => educationType.label === this.selectedSchool.educationType);

          this.educationYears = this.educationType.years;
          this.educationYear = this.educationType.years.find(
            (year) => year.year === this.selectedSchool.educationYear);

          this.educationLevels = this.educationYear.levels;
          if (this.educationLevels.length > 0) {
            this.educationLevel = this.educationYear.levels.find(
              (level) => level.label === this.selectedSchool.educationLevel
            );
            this.educationLessons = this.educationLevel.lessons;
            this.educationProfiles = this.educationLevel.profiles;
          }

          this.choices.forEach(choice => {
            const preferenceProperty = `${choice}SchoolPreference`
            this.updateChoiceSchoolLocationOptions(`${preferenceProperty}.schoolLocation`, choice);
            this.updateChoiceEducationTypeOptions(`${preferenceProperty}.educationType`);
            this.updateChoiceEducationLevelOptions(`${preferenceProperty}.educationLevel`)
          })

        } else if (this.school) {
          this.signUp.isInflux = this.school.isInflux;
          this.signUp.schoolYear = this.school.schoolYear; // Used in dynamic section for startDate
          this.selectedSchool.name = this.school.name;
          this.selectedSchool.schoolId = this.school.id;
          this.selectedSchool.schoolRoleId = this.school.schoolRoleId;
          this.selectedSchool.schoolYear = this.school.schoolYear;

          this.schoolLocations = this.school.locations;
          if (this.schoolLocations.length == 1) {
            this.schoolLocation = this.schoolLocations[0];
            this.selectedSchool.schoolLocation = this.schoolLocation.name;
            this.educationTypes = this.schoolLocation.educationType;
            if (this.educationTypes.length == 1) {
              this.educationType = this.schoolLocation.educationType[0];
              this.selectedSchool.educationType = this.educationType.label;
              this.educationYears = this.educationType.years;
            }
          }
        }
      }
      this.initCustomQuestions(this.selectedSchool);

      super.commonNgOnInit();
      if (!this.getSection()) return;

      this.setAllOptions();

      this.getSection().fields.forEach(fld => {
        this.addCallbackFunctions(fld)
        this.checkHideLabel(fld);
        this.checkControl(fld,this.selectedSchool, this.signUp);
      })

    })

  }

  addCallbackFunctions(fld: FormField): void {
    const handleRegexCases = (varName: string) => {
      if (/^(second|third|fourth|fifth)SchoolPreference.schoolName$/.test(varName)) {
        return () => this.onChoiceSchoolNameChange(varName);
      } else if (/^(second|third|fourth|fifth)SchoolPreference.educationLevel$/.test(varName)) {
        return () => this.onChoiceEducationLevelChange(varName);
      } else if (/^(second|third|fourth|fifth)SchoolPreference.schoolLocation$/.test(varName)) {
        return () => this.onChoiceSchoolLocationChange(varName);
      } else if (/^(second|third|fourth|fifth)SchoolPreference.educationType$/.test(varName)) {
        return () => this.onChoiceEducationTypeChange(varName);
      } return null
    };

    switch (fld.varName) {
      case "startDate":
        fld.changeFn = this.onStartDateChange;
        break;
      case "schoolLocation":
        fld.changeFn = this.onSchoolLocationChange;
        break;
      case "educationType":
        fld.changeFn = this.onEducationTypeChange;
        break;
      case "educationYear":
        fld.changeFn = this.onEducationYearChange;
        break;
      case "educationLevel":
        fld.changeFn = this.onEducationLevelChange;
        break;
      case "educationLevelAdvice":
        fld.changeFn = this.onEducationLevelAdviceChange;
        break
      case "choice":
        fld.changeFn = this.onChoiceChange;
        break;
      default:
        fld.changeFn = handleRegexCases(fld.varName);
        break;
    }
  }

  setAllOptions(): void {
    this.setSchoolLocationOptions();
    this.setEducationTypeOptions();
    this.setEducationYearOptions();
    this.setEducationLevelOptions();
    this.setEducationAdviceLevelOptions();
    this.setEducationProfileOptions();
    this.setEducationLessonOptions();

    this.choices.forEach(choice => {
      this.setSchoolOptions(`${choice}SchoolPreference.schoolName`);
      this.setChoiceEducationLevelOptions(`${choice}SchoolPreference.educationLevel`)
      this.setChoiceEducationTypeOptions(`${choice}SchoolPreference.educationType`)
      this.setChoiceSchoolLocationOptions(`${choice}SchoolPreference.schoolLocation`)
    });
  }

  checkHideLabel(fld: FormField): void {
    // hide labels ?
    switch (fld.varName) {
      case "schoolLocation":
        fld.hideLabel = !(this.schoolLocations != undefined && this.schoolLocations.length > 1);
        break;
      case "educationType":
        fld.hideLabel = !(this.educationTypes != undefined && this.educationTypes.length > 1);
        break;
      case "secondSchoolPreference.schoolLocation":
        fld.hideLabel = !(this.selectedSchool.secondSchoolPreference != undefined && this.checkLocationList("second"));
        break;
      case "thirdSchoolPreference.schoolLocation":
        fld.hideLabel = !(this.selectedSchool.thirdSchoolPreference != undefined && this.checkLocationList("third"));
        break;
      case "fourthSchoolPreference.schoolLocation":
        fld.hideLabel = !(this.selectedSchool.fourthSchoolPreference != undefined && this.checkLocationList("fourth"));
        break;
      case "fifthSchoolPreference.schoolLocation":
        fld.hideLabel = !(this.selectedSchool.fifthSchoolPreference != undefined && this.checkLocationList("fifth"));
        break;
      case "secondSchoolPreference.educationType":
        fld.hideLabel = !(this.selectedSchool.secondSchoolPreference != undefined &&  this.checkEducationTypeList("second"));
        break
      case "thirdSchoolPreference.educationType":
        fld.hideLabel = !(this.selectedSchool.thirdSchoolPreference != undefined && this.checkEducationTypeList("third"));
        break
      case "fourthSchoolPreference.educationType":
        fld.hideLabel = !(this.selectedSchool.fourthSchoolPreference != undefined && this.checkEducationTypeList("fourth"));
        break
      case "fifthSchoolPreference.educationType":
        fld.hideLabel = !(this.selectedSchool.fifthSchoolPreference != undefined && this.checkEducationTypeList("fifth"));
        break
    }
  }

  checkLocationList(choice: string): boolean {
    let hasMultipleLocations = false;
    const preferenceProperty = `${choice}SchoolPreference`;

    if(!this.schools$) return;
    this.schools$.pipe(
      map(schools => {
        const school = schools.find(s => s.name === this.selectedSchool[preferenceProperty]?.schoolName);
        if (!school) return false;

        hasMultipleLocations = school.locations.length > 1;

        return hasMultipleLocations;
      })
    ).subscribe();

    return hasMultipleLocations;
  }

  checkEducationTypeList(choice: string): boolean {
    let hasMultipleEducationTypes = false;
    const preferenceProperty = `${choice}SchoolPreference`;

    if(!this.schools$) return;
    this.schools$.pipe(
      map(schools => {
        const school = schools.find(s => s.name === this.selectedSchool[preferenceProperty]?.schoolName);
        if (!school) return false;

        const location = school.locations.find(loc => loc.name === this.selectedSchool[preferenceProperty]?.schoolLocation);
        if (!location) return false;

        const options = location.educationType.map(educationType => new SelectOption(educationType.label, educationType.label));
        hasMultipleEducationTypes = options.length > 1;

        this.updateChoiceEducationLevelOptions(`${choice}SchoolPreference.educationLevel`);

        return hasMultipleEducationTypes;
      })
    ).subscribe();

    return hasMultipleEducationTypes;
  }

  checkCondition(fld: FormField): boolean {
    let checkOk = true;
    this.checkHideLabel(fld)
    fld.condition.forEach(condition => {
      switch (condition) {
        case ConditionType.NOT_INFLUX:

          if (this.school.isInflux || this.signUp.isInflux || (this.selectedSchool.educationYear && this.selectedSchool.educationYear > 1)) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.INFLUX:
          if (!(this.school.isInflux || this.signUp.isInflux || (this.selectedSchool.educationYear && this.selectedSchool.educationYear > 1))) {

            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.LEVEL_NOT_EMPTY:
          if (this.selectedSchool.educationLevel == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.PROFILES_NOT_EMPTY:
          if (this.selectedSchool.educationLevel == undefined || this.educationLevel.profiles == undefined || this.educationLevel.profiles.length == 0) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.LESSONS_NOT_EMPTY:
          if (this.selectedSchool.educationLevel == undefined || this.educationLevel.lessons == undefined || this.educationLevel.lessons.length == 0) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.LOCATION_NOT_EMPTY:
          if (this.selectedSchool.schoolLocation == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          } else {
            fld.conditionalDisabled = false;
          }
          break;
        case ConditionType.EDUCATION_TYPE_NOT_EMPTY:
          if (this.selectedSchool.educationType == undefined || this.selectedSchool.educationType == '') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.LWOO:
          if (!this.educationLevel || !this.educationLevel.hasLwoo || this.educationLevel.hasLwoo.toString() == 'false') {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.YEAR_NOT_EMPTY:
          if (this.selectedSchool.educationYear == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.FIRST_YEAR:
          if (this.selectedSchool.educationYear == undefined ||  this.selectedSchool.educationYear > 1) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.HIGHER_YEAR:
          if (this.selectedSchool.educationYear == undefined ||  this.selectedSchool.educationYear == 1) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.SECOND_CHOICE:
          if (this.selectedSchool.choice < 2) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.SECOND_SCHOOLNAME:
          if (this.selectedSchool.secondSchoolPreference == undefined || this.selectedSchool.secondSchoolPreference.schoolName == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.SECOND_SCHOOLLOCATION:
          if (this.selectedSchool.secondSchoolPreference == undefined || this.selectedSchool.secondSchoolPreference.schoolLocation == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.SECOND_EDUCATIONTYPE:
          if (this.selectedSchool.secondSchoolPreference == undefined || this.selectedSchool.secondSchoolPreference.educationType == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.SECOND_EDUCATIONLEVEL:
          if (this.selectedSchool.secondSchoolPreference == undefined || this.selectedSchool.secondSchoolPreference.educationLevel == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.THIRD_SCHOOLNAME:
          if (this.selectedSchool.thirdSchoolPreference == undefined || this.selectedSchool.thirdSchoolPreference.schoolName == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.THIRD_SCHOOLLOCATION:
          if (this.selectedSchool.thirdSchoolPreference == undefined || this.selectedSchool.thirdSchoolPreference.schoolLocation == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.THIRD_EDUCATIONTYPE:
          if (this.selectedSchool.thirdSchoolPreference == undefined || this.selectedSchool.thirdSchoolPreference.educationType == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.THIRD_EDUCATIONLEVEL:
          if (this.selectedSchool.thirdSchoolPreference == undefined || this.selectedSchool.thirdSchoolPreference.educationLevel == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.FOURTH_SCHOOLNAME:
          if (this.selectedSchool.fourthSchoolPreference == undefined || this.selectedSchool.fourthSchoolPreference.schoolName == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.FOURTH_SCHOOLLOCATION:
          if (this.selectedSchool.fourthSchoolPreference == undefined || this.selectedSchool.fourthSchoolPreference.schoolLocation == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.FOURTH_EDUCATIONTYPE:
          if (this.selectedSchool.fourthSchoolPreference == undefined || this.selectedSchool.fourthSchoolPreference.educationType == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.FOURTH_EDUCATIONLEVEL:
          if (this.selectedSchool.fourthSchoolPreference == undefined || this.selectedSchool.fourthSchoolPreference.educationLevel == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.FIFTH_SCHOOLNAME:
          if (this.selectedSchool.fifthSchoolPreference == undefined || this.selectedSchool.fifthSchoolPreference.schoolName == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.FIFTH_SCHOOLLOCATION:
          if (this.selectedSchool.fifthSchoolPreference == undefined || this.selectedSchool.fifthSchoolPreference.schoolLocation == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
        case ConditionType.FIFTH_EDUCATIONTYPE:
          if (this.selectedSchool.fifthSchoolPreference == undefined || this.selectedSchool.fifthSchoolPreference.educationType == undefined) {
            fld.conditionalDisabled = true;
            this.removeControlFromFormGroup(fld.varName);
            checkOk = false;
            return false;
          }
          break;
      }
    })
    return checkOk;
  }

  setSchoolLocationOptions(): void {
    if (this.schoolLocations == undefined) return;
    let fld = this.getFieldByVarName('schoolLocation');
    fld.options = Array();
    for (const element of this.schoolLocations) {
      let opt = new SelectOption('' + element.name, '' + element.name);
      fld.options.push(opt);
    }
  }
  setEducationTypeOptions(): void {
    if (this.educationTypes == undefined) return;
    let fld = this.getFieldByVarName('educationType');
    if (!fld) return
    fld.options = Array();
    for (const element of this.educationTypes) {
      let opt = new SelectOption('' + element.label, '' + element.label);
      fld.options.push(opt);
    }
  }

  setEducationYearOptions(): void {
    if (this.educationYears == undefined) return;
    let fld = this.getFieldByVarName('educationYear');
    if (!fld) return
    fld.options = Array();
    for (const element of this.educationYears) {
      let opt = new SelectOption('' + element.year, '' + element.year);
      fld.options.push(opt);
    }
  }

  setEducationLevelOptions(): void {
    if (this.educationLevels == undefined) return;
    let fld = this.getFieldByVarName('educationLevel');
    if (!fld) return;
    fld.options = Array();
    for (const element of this.educationLevels) {
      let opt = new SelectOption(element.label, element.label);
      fld.options.push(opt);
    }
  }

  updateChoiceEducationLevelOptions(fieldName: string): void {
    const field = this.getFieldByVarName(fieldName);
    if (!field) return;

    const choice = fieldName.split('SchoolPreference')[0];
    const preferenceProperty = `${choice}SchoolPreference`;
    const selectedSchool = this.selectedSchool[preferenceProperty];
    const educationType = selectedSchool?.educationType;
    if (!educationType || !selectedSchool) return;

    if (!this.selectedEducationLevels[educationType]) {
      this.selectedEducationLevels[educationType] = [];
    }

    if(!this.schools$) return;
    this.schools$.subscribe(schools => {
      const options: SelectOption[] = [];
      if(!this.formGroup.controls[fieldName]) return

      const school = schools.find(s => s.name === selectedSchool.schoolName);
      if (school) {
        const location = school.locations.find(loc => loc.name === selectedSchool.schoolLocation);
        if (location) {
          const educationTypeObj = location.educationType.find(type => type.label === educationType);
          if (educationTypeObj) {
            educationTypeObj.years.forEach(year => {
              if (year.year === 1) {
                year.levels.forEach(level => {
                    options.push(new SelectOption(level.label, level.label));
                });
              }
            });
          }
        }
      }

      field.options = options;
    });
  }

  setSchoolOptions(varName: string): void {
    let field = this.getFieldByVarName(varName);
    if (!field) return;
    if(!this.schools$) return;

    this.schools$.subscribe(schools => {
      field.options = schools
        .filter(school => school.name !== this.selectedSchool.name)
        .map(school => new SelectOption(school.name, school.name));
    });
  }

  setChoiceSchoolLocationOptions(varName: string): void {
    let field = this.getFieldByVarName(varName);
    if (!field) return;
    field.options = []
  }

  setChoiceEducationTypeOptions(varName: string): void {
    let field = this.getFieldByVarName(varName);
    if (!field) return;
    field.options = []
  }

  updateChoiceSchoolLocationOptions(varName: string, choice: string): void {
    let field = this.getFieldByVarName(varName);
    if (!field) return;
    if(!this.schools$) return;
    const preferenceProperty = `${choice}SchoolPreference`;

    this.schools$.subscribe(schools => {
      const locationNames = new Set<string>();

      schools.forEach(school => {
        if (school.name === this.selectedSchool[preferenceProperty]?.schoolName) {
          if (school.locations.length === 1){
            this.selectedSchool[preferenceProperty].schoolLocation = school.locations[0].name
          }
          school.locations.forEach(location => {
            locationNames.add(location.name)
          })
        }
        this.checkHideLabel(field)
      })
      field.options = Array.from(locationNames).map(
        locationName => new SelectOption(locationName, locationName)
      );
    })
  }

  updateChoiceEducationTypeOptions(varName: string): void {
    const field = this.getFieldByVarName(varName);
    if (!field) return;
    if(!this.schools$) return;

    field.options = [];

    const choice = varName.split('SchoolPreference')[0];
    const preferenceProperty = `${choice}SchoolPreference`;

    this.schools$.subscribe(schools => {
      const options: SelectOption[] = [];

      const selectedSchool = this.selectedSchool[preferenceProperty];
      if (selectedSchool) {
        const school = schools.find(school => school.name === selectedSchool.schoolName);
        if (school) {
          const location = school.locations.find(loc => loc.name === selectedSchool.schoolLocation);
          if (location) {
            if (location.educationType.length === 1){
              this.selectedSchool[preferenceProperty].educationType = location.educationType[0].label
            }
            location.educationType.forEach(educationType => {
              options.push(new SelectOption(educationType.label, educationType.label));
            });
          }
        }
      }

      field.options = options;
      this.checkHideLabel(field);
      this.checkControlByVarName(`${preferenceProperty}.educationLevel`, this.selectedSchool)
    });
  }

  setChoiceEducationLevelOptions(varName: string): void {
    let field = this.getFieldByVarName(varName);
    if (!field) return;
    field.options = [];
  }

  setEducationAdviceLevelOptions(): void {
    if (this.school == undefined) return;
    let fld = this.getFieldByVarName('educationLevelAdvice');
    if (!fld) return
    fld.options = Array();
    if (this.schoolLocation) {
      for (const element of this.schoolLocation.educationAdviceLevel) {
        let opt = new SelectOption(element.label, element.label);
        fld.options.push(opt);
      }
    }
  }

  setEducationProfileOptions(): void {
    if (this.educationLevel == undefined || this.educationLevel.profiles == undefined) return;
    let fld = this.getFieldByVarName('educationProfile');
    if (!fld) return
    fld.options = Array();
    for (const element of this.educationLevel.profiles) {
      let opt = new SelectOption(element.label, element.label);
      fld.options.push(opt);
    }
  }

  setEducationLessonOptions(): void {
    if (this.educationLevel == undefined || this.educationLevel.lessons == undefined) return;
    let fld = this.getFieldByVarName('educationLesson');
    if (!fld) return
    fld.options = Array();
    for (const element of this.educationLevel.lessons) {
      let opt = new SelectOption(element.label, element.label);
      fld.options.push(opt);
    }
  }

  onSchoolLocationChange = (): void => {
    if (this.formGroup && this.formGroup.controls && this.formGroup.controls['schoolLocation']) {
      this.selectedSchool.schoolLocation = this.formGroup.controls['schoolLocation'].value;
    }

    this.selectedSchool.educationType = '';
    this.selectedSchool.educationYear = undefined;
    this.selectedSchool.educationLevelAdvice = undefined;
    this.selectedSchool.educationLevel = undefined;
    this.selectedSchool.educationProfile = undefined;
    this.selectedSchool.educationLesson = undefined;

    this.educationProfiles = [];
    this.educationLessons = [];

    this.schoolLocation = this.school.locations.find(
      (location) => location.name === this.selectedSchool.schoolLocation);

    if (this.schoolLocation) {
      this.educationTypes = this.schoolLocation.educationType;
      if (this.educationTypes.length == 1) {
        this.educationType = this.educationTypes[0];
        this.selectedSchool.educationType = this.educationType.label;
        this.educationYears = this.educationType.years;
      }
    }

    this.setEducationTypeOptions();
    this.checkControlByVarName('educationType',this.selectedSchool);
    this.setEducationYearOptions();
    this.checkControlByVarName('educationYear',this.selectedSchool);
    this.checkControlByVarName('choice',this.selectedSchool);
    this.checkControlByVarName('firstChoiceSchoolName',this.selectedSchool);
  }

  onEducationTypeChange = (): void => {
    if (this.formGroup && this.formGroup.controls && this.formGroup.controls['educationType']) {
      this.selectedSchool.educationType = this.formGroup.controls['educationType'].value;
    }

    this.selectedSchool.educationYear = undefined;
    this.selectedSchool.educationLevelAdvice = undefined;
    this.selectedSchool.educationLevel = undefined;
    this.selectedSchool.educationProfile = undefined;
    this.selectedSchool.educationLesson = undefined;

    this.educationProfiles = [];
    this.educationLessons = [];

    this.schoolLocation= this.school.locations.find(
      (location) => location.name === this.selectedSchool.schoolLocation);

    this.educationType = this.schoolLocation.educationType.find(
      (educationType) => educationType.label === this.selectedSchool.educationType);
    this.educationYears = this.educationType.years;

    this.checkControlByVarName('educationYear',this.selectedSchool);
    this.setEducationYearOptions();

    this.checkControlByVarName('educationLevelAdvice',this.selectedSchool);
    this.checkControlByVarName('educationLevel',this.selectedSchool);
    this.checkControlByVarName('educationProfile',this.selectedSchool);
    this.checkControlByVarName('educationLesson',this.selectedSchool);
    this.setEducationAdviceLevelOptions();
    this.setEducationLevelOptions();

    this.choices.forEach(choice => {
      this.setSchoolOptions(`${choice}SchoolPreference.schoolName`);
      this.setChoiceEducationLevelOptions(`${choice}SchoolPreference.educationLevel`)
      this.setChoiceSchoolLocationOptions(`${choice}SchoolPreference.schoolLocation`)
      this.setChoiceEducationTypeOptions(`${choice}SchoolPreference.educationType`)
    });
  }
  onStartDateChange= (): void => {
    this.selectedSchool.startDate = this.formGroup.controls['startDate'].value;
  }

  onEducationLevelAdviceChange = (): void => {
    this.selectedSchool.educationLevelAdvice = this.formGroup.controls['educationLevelAdvice'].value;
  }

  onEducationYearChange = (): void => {
    this.selectedSchool.educationYear = +this.formGroup.controls['educationYear'].value;

    this.selectedSchool.educationLevelAdvice = undefined;
    this.selectedSchool.educationLevel = undefined;
    this.selectedSchool.educationProfile = undefined;
    this.selectedSchool.educationLesson = undefined;

    if (this.selectedSchool.educationYear === 1) {
      this.signUp.isInflux = this.school.isInflux;
      if (!this.signUp.isInflux) {
        this.formGroup.controls['startDate']?.setValue(null);
        this.selectedSchool.startDate = this.formGroup.controls['startDate']?.value;
      }
    }

    this.educationProfiles = [];
    this.educationLessons = [];

    this.schoolLocation= this.school.locations.find(
      (location) => location.name === this.selectedSchool.schoolLocation);

    this.educationType = this.schoolLocation.educationType.find(
      (educationType) => educationType.label === this.selectedSchool.educationType);

    this.educationYear = this.educationType.years.find(
      (year) => year.year === this.selectedSchool.educationYear);

    this.educationLevels = this.educationYear.levels;
    if (this.educationLevels.length == 1) {
      this.educationLevel = this.educationLevels[0];
      this.selectedSchool.educationLevel = this.educationLevel.label;
      this.setEducationProfileOptions();
      this.setEducationLessonOptions();
    }

    this.checkControlByVarName('educationLevelAdvice',this.selectedSchool);
    this.checkControlByVarName('educationLevel',this.selectedSchool);
    this.checkControlByVarName('educationProfile',this.selectedSchool);
    this.checkControlByVarName('educationLesson',this.selectedSchool);

    if(this.referralModuleActive) {
      this.checkControlByVarName('secondSchoolPreference.schoolName', this.selectedSchool)
    }

    this.checkControlByVarName('startDate',this.selectedSchool);

    this.setEducationAdviceLevelOptions();
    this.setEducationLevelOptions();

    this.checkControlByVarName('choice',this.selectedSchool);
    this.checkControlByVarName('firstChoiceSchoolName',this.selectedSchool);
  }

  onEducationLevelChange = (): void => {
    this.selectedSchool.educationLevel = this.formGroup.controls['educationLevel'].value;

    this.selectedSchool.educationProfile = undefined;
    this.selectedSchool.educationLesson = undefined;

    this.schoolLocation= this.school.locations.find(
      (location) => location.name === this.selectedSchool.schoolLocation);

    this.educationType = this.schoolLocation.educationType.find(
      (educationType) => educationType.label === this.selectedSchool.educationType);

    this.educationYear = this.educationType.years.find(
      (year) => year.year === this.selectedSchool.educationYear);

    this.educationLevel = this.educationYear.levels.find(
      (level) => level.label === this.selectedSchool.educationLevel
    );

    this.educationProfiles = this.educationLevel.profiles;
    this.educationLessons = this.educationLevel.lessons;

    this.checkControlByVarName('educationLwoo',this.selectedSchool);
    this.checkControlByVarName('educationProfile',this.selectedSchool);
    this.checkControlByVarName('educationLesson',this.selectedSchool);

    this.setEducationProfileOptions();
    this.setEducationLessonOptions();
  }

  onChoiceChange = (): void => {
    this.selectedSchool.choice = this.formGroup.controls['choice'].value;
    this.checkControlByVarName('firstChoiceSchoolName',this.selectedSchool);
  }

  onChoiceSchoolLocationChange(varName: string) {
    const choiceEducationTypeField = varName.replace('schoolLocation', 'educationType')
    const choiceEducationLevelField = varName.replace('schoolLocation', 'educationLevel')
    const choice = varName.split('SchoolPreference')[0];
    const preferenceProperty = `${choice}SchoolPreference`;
    this.selectedSchool[preferenceProperty].schoolLocation = this.formGroup.controls[varName]?.value;

    this.selectedSchool[preferenceProperty].educationType = '';
    this.selectedSchool[preferenceProperty].educationYear = undefined;
    this.selectedSchool[preferenceProperty].educationLevelAdvice = undefined;
    this.selectedSchool[preferenceProperty].educationLevel = undefined;
    this.selectedSchool[preferenceProperty].educationProfile = undefined;
    this.selectedSchool[preferenceProperty].educationLesson = undefined;

    this.updateChoiceEducationTypeOptions(choiceEducationTypeField)
    this.updateChoiceEducationLevelOptions(choiceEducationLevelField)

    let fldEducationType = this.getFieldByVarName(choiceEducationTypeField);
    this.checkHideLabel(fldEducationType)

    this.checkControlByVarName(`${preferenceProperty}.educationType`, this.selectedSchool)
  }

  onChoiceEducationTypeChange(varName: string) {
    const choice = varName.split('SchoolPreference')[0];
    const preferenceProperty = `${choice}SchoolPreference`;
    this.selectedSchool[preferenceProperty].educationType = this.formGroup.controls[varName]?.value;
    this.checkControlByVarName(`${preferenceProperty}.educationLevel`, this.selectedSchool)
    this.updateChoiceEducationLevelOptions(`${preferenceProperty}.educationLevel`)
  }

  onChoiceEducationLevelChange = (fieldVarName: string): void => {
    const choiceSchoolNameField = fieldVarName.replace('educationLevel', 'schoolName');
    const choiceSchoolName = this.formGroup.controls[choiceSchoolNameField]?.value;
    const newChoiceEducationLevel = this.formGroup.controls[fieldVarName]?.value;

    const choicePrefix = fieldVarName.split('SchoolPreference')[0];
    const preferenceProperty = `${choicePrefix}SchoolPreference`;

    if (choiceSchoolName && newChoiceEducationLevel) {
      const educationType = this.selectedSchool[preferenceProperty].educationType

      if (educationType) {
        if (!this.selectedEducationLevels[educationType]) {
          this.selectedEducationLevels[educationType] = [];
        }

        if (this.selectedSchool[preferenceProperty]) {
          this.selectedSchool[preferenceProperty].educationLevel = newChoiceEducationLevel;
        }
      }
    }

    const currentChoiceIndex = this.choices.indexOf(choicePrefix);
    if (currentChoiceIndex !== -1 && currentChoiceIndex < this.choices.length - 1) {
      const nextChoice = this.choices[currentChoiceIndex + 1]
      const nextChoiceSchoolNameField = `${nextChoice}SchoolPreference.schoolName`
      this.checkControlByVarName(nextChoiceSchoolNameField, this.selectedSchool)
    }
  };

  save(): boolean {
    if (!this.prepareSave(this.selectedSchool)) return false;
    this.prepareCustomQuestionsForSave(this.selectedSchool);
    if (this.signUp.id) {
      this.signUpService.saveSchool(this.selectedSchool).subscribe((signup) => {
        this.isSubmitted = false;
        this.goToNext(signup);
      }, (err) => {
        this.getRouter().navigate(['/home']);
        this.handleSaveError(err);
      });
    } else {
      this.signUpService.start(this.selectedSchool, this.formId).subscribe((signup) => {
        this.isSubmitted = false;
        this.goToNext(signup);
      });
    }
    return false;
  }

  onChoiceSchoolNameChange = (fieldVarName: string): void => {
    const choiceSchoolName = this.formGroup.controls[fieldVarName]?.value;
    if (!choiceSchoolName) return;
    if(!this.schools$) return;

    const educationLevelAdvice = this.selectedSchool.educationLevelAdvice

    this.schools$.subscribe(schools => {
      const selectedSchool = schools.find(school => school.name === choiceSchoolName);
      if (!selectedSchool) return;

      const firstLocation = selectedSchool.locations[0];
      if (!firstLocation) return;

      const schoolPreference = {
        schoolId: selectedSchool.id,
        contractNr: selectedSchool.contractNr,
        schoolName: selectedSchool.name,
        schoolYear: this.signUp.schoolYear,
        educationType: null,
        educationLevelAdvice: educationLevelAdvice
      };

      const choice = fieldVarName.split('SchoolPreference')[0];
      const preferenceProperty = fieldVarName.split('SchoolPreference')[0] + 'SchoolPreference';
      this.selectedSchool[preferenceProperty] = schoolPreference;

      const educationTypeWithYear = firstLocation.educationType.find(type =>
        type.years.some(year => year.year === 1)
      );
      if (!educationTypeWithYear) return;

      const yearWithOptions = educationTypeWithYear.years.find(year => year.year === 1);
      if (!yearWithOptions) return;

      this.updateChoiceSchoolLocationOptions(`${preferenceProperty}.schoolLocation`, choice)
      this.updateChoiceEducationTypeOptions(`${preferenceProperty}.educationType`)

      this.checkControlByVarName(fieldVarName,this.selectedSchool);
      this.checkControlByVarName(`${preferenceProperty}.schoolLocation`, this.selectedSchool)
      this.checkControlByVarName(`${preferenceProperty}.educationType`, this.selectedSchool)
      this.checkControlByVarName(`${preferenceProperty}.educationLevel`, this.selectedSchool)

      let fldSchoolLocation = this.getFieldByVarName(`${preferenceProperty}.schoolLocation`);
      let fldEducationType = this.getFieldByVarName(`${preferenceProperty}.educationType`);

      this.checkHideLabel(fldSchoolLocation)
      this.checkHideLabel(fldEducationType)
    });
  };
}
