import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SchoolConfiguration} from "../../../shared/model/schoolConfiguration";
import {Observable} from "rxjs";

@Component({
  selector: 'app-home-select-school',
  templateUrl: './home-select-school.component.html',
  styleUrls: ['./home-select-school.component.css']
})
export class HomeSelectSchoolComponent implements OnInit {

  @Input()
  schools: Observable<SchoolConfiguration[]>;
  filteredSchools: SchoolConfiguration[];
  startSchools: SchoolConfiguration[];

  @Output()
  schoolSelected = new EventEmitter<string>();

  searchFilterText: string = '';
  constructor() {}

  ngOnInit() {
    this.schools.subscribe(schools => {
      this.startSchools = schools
      this.getSchoolsBySearch()
    });
  }

  selectSchool(school: SchoolConfiguration) {
    this.schoolSelected.emit(school.id)
  }

  getSchoolsBySearch() {
    this.filteredSchools = this.startSchools.filter((school: SchoolConfiguration) => {
      // Check if search text can be found in school name or school address.
      return school.name?.toLowerCase().includes(this.searchFilterText.toLowerCase()) || school.address?.toLowerCase().includes(this.searchFilterText.toLowerCase())
    })
  }
}
