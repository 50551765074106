import {Injectable} from "@angular/core";

@Injectable()
export class TextFormatService {
  constructor() {}

  capitalizeFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  capitalizeFirstLetterOfEachWord(text: string): string {
    text = text.toLowerCase()
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');

    return text;
  }

  formatInitials(text: string): string {
    text = text.replace(/ /g,'').toUpperCase();  // Replace spaces, convert to capital letters
    if (text.length === 1) {
      text = text + '.';
    }

    return text;
  }
}
