import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CountryCode } from '../model/countrycode';
import { NationalityCode } from '../model/nationalitycode';
import { Observable, shareReplay } from "rxjs";
import { SelectOption } from "../model/formField";
import { map } from 'rxjs/operators';

@Injectable()
export class CountryService {
  countryUrl = '/api/country/';

  countries$: Observable<CountryCode[]>;
  nationalities$: Observable<NationalityCode[]>;

  constructor(private http: HttpClient) {
      this.initCache();
  }

  initCache() {
    this.countries$ = this.http
      .get<CountryCode[]>(this.countryUrl + 'countries')
      .pipe(shareReplay(1));

    this.nationalities$ = this.http
        .get<NationalityCode[]>(this.countryUrl + 'nationalities')
        .pipe(shareReplay(1));
  }

  getCountries(): Observable<CountryCode[]> {
    return this.countries$;
  }

  getNationalities(): Observable<NationalityCode[]> {
    return this.nationalities$;
  }

  getCountriesOptions(): Observable<SelectOption[]> {
    return this.countries$.pipe(
      map(res => res.map(cc => ({
        value : cc.countryName,
        text : cc.countryName
      })))
    );
  }

  // Methode die de methode getCountriesOptions gaat vervangen. 1 Keer een call om de countries op te halen, vervolgens value op countryCode zetten, en text op countryName
  getCountriesOptionsFromPreviousSavedCountries(countries: CountryCode[]): SelectOption[] {
      return countries.map(cc => ({
        value : cc.countryCode,
        text : cc.countryName
      }))
  }

  getNationalitiesOptions(): Observable<SelectOption[]> {
    return this.nationalities$.pipe(
      map(res => res.map(nc => ({
        value : nc.nationalityName,
        text : nc.nationalityName
      })))
    );
  }
}
