import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Page } from '../../shared/model/page';
import { PageService } from '../../shared/services/page.service';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
})
export class PageComponent {

  page: Page = {
    title: '',
    body: ''
  } as Page;

  constructor(private router: Router, private pageService: PageService) {
    this.pageService.getPage(this.router.url.substring(1)).subscribe((page: Page) => {
      this.page = page;
    });
  }

}
