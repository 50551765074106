import {AbstractControl} from "@angular/forms";

export function ValidateInitials(control: AbstractControl) {
  const value = typeof control.value === 'string' ? control.value : String(control.value);

  if (!value || value === 'null') {
    return null;
  }

  if (value.length == 1) {
    if (value[0].toUpperCase() === value[0].toLowerCase()) { // not a letter as first character
      return {
        'appCheckInitials': {value: value}
      };
    }
    else { // first character is a letter, dot is automatically added on blur
      return null;
    }
  }

  for (let i = 0; i < value.length; i++) {
    if (i % 2 === 1 && value[i] !== '.') { // no dot between letters
      return {
        'appCheckInitials': {value: value}
      };
    } else if (i % 2 === 0) {
      if (value[i].toUpperCase() === value[i].toLowerCase() // not a letter
      || value[i].toUpperCase() !== value[i].toLowerCase() && i === value.length - 1) { // letter at end instead of dot
        return {
          'appCheckInitials': {value: value}
        };
      }
    }
  }
  return null;
}
