import { Directive, Input } from '@angular/core';

import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
  selector: '[appCheckBSN]',
  providers: [{provide: NG_VALIDATORS, useExisting: BsnValidator, multi: true}]
})
export class BsnValidator implements Validator {
  @Input() appCheckBSN: string;

  validate(control: AbstractControl): {[key: string]: any} | null {
    const value = typeof control.value === 'string' ? control.value : String(control.value);

    if (!value) {
      return null;
    }

    if (value.length < 9) {
      return {
        'appCheckBSN': {value: value}
      };
    }

    let sum = 0;
    for (let i = 0; i < 8; i++) {
      sum += (9 - i) * parseInt(value[i]);
    }
    sum -= parseInt(value[8]);

    if (((sum % 11) === 0)) {
      return null;
    } else {
      return {
        'appCheckBSN': {value: value}
      };
    }
  }
}
