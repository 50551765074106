<div class="form-row">
  <div class="col-sm-12">
    <h1>{{getCurrentSection()?.title}}</h1>
    <h2 class="hide-for-desktop">{{getCurrentSectionStep()}}</h2>
    <hr/>
  </div>
</div>
<div class="row" *ngIf="getCurrentSection()?.introduction">
  <div class="col-sm-10" [innerHTML]="getCurrentSection()?.introduction">
  </div>
</div>

<div class="row">
  <div class="col-sm-10 alert alert-danger" *ngIf="signUp.malwareDetected">
    <p>
      LET OP: in ten minste één van de door u geüploade bestanden is een virus of malware gedetecteerd.
    </p>
    <p>
      U kunt uw aanmelding wel afronden, maar geïnfecteerde bestanden zullen worden verwijderd.
    </p>
  </div>
</div>
<app-school-dynamic [checking]="true"></app-school-dynamic>
<app-student-dynamic [checking]="true"></app-student-dynamic>
<app-parent-dynamic [checking]="true"></app-parent-dynamic>
<app-family-dynamic [checking]="true" *ngIf="isSectionEnabled('FAMILY')"></app-family-dynamic>
<app-previous-school-dynamic [checking]="true" *ngIf="isSectionEnabled('PREVIOUSSCHOOL')"></app-previous-school-dynamic>
<app-particularities-dynamic [checking]="true" *ngIf="isSectionEnabled('PARTICULARITIES')"></app-particularities-dynamic>
<app-additional-dynamic [checking]="true" *ngIf="isSectionEnabled('ADDITIONAL')"></app-additional-dynamic>

<div class="row">
  <div class="col-sm-12">
    <h1>Ondertekenen en versturen</h1>
    <hr />
    <form [formGroup]="formGroup" autocomplete="off">
      <ng-container *ngFor="let row of rows">
        <div class="form-row" *ngIf="row.fields && row.fields[0] && row.fields[0].enabled && !row.fields[0].conditionalDisabled">
          <ng-container *ngFor="let field of row.fields">
            <div [class]="'form-group col-sm-'+ field.nrColumns" *ngIf="!field.conditionalDisabled">
              <app-dynamic-formfield [field]="field" [form]="formGroup" [keyupFn]="field.callbackFn" [changeFn]="field.changeFn" [isSubmitted]="isSubmitted"></app-dynamic-formfield>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </form>
  </div>
</div>

<div class="row">
    <div class="col-sm-12">
      <div class="flex-navigation">
        <span (click)="save()"
              [class]="!btnClickAllowed ? 'span-disabled nav-button-next nav-button-next-only' : 'nav-button-next nav-button-next-only'"
              [innerHTML]="btnSignValue"></span>
      </div>
    </div>
</div>
