import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {Observable} from 'rxjs';
import {FormDefinition} from "../model/formDefinition";
import {FormType} from "../model/formSection";

@Injectable()
export class FormService {

  private url = 'api/form/';  // URL to web api
  private selectedForm: FormDefinition;
  private selectedFormType: FormType;

  constructor(private http: HttpClient) { }

  getFormById(id: string): Observable<FormDefinition> {
    const url = this.url+ id;
    return this.http.get<FormDefinition>(url);
  }

  setForm(form: FormDefinition): void {
    this.selectedForm = form;
  }

  getForm(): FormDefinition {
    return this.selectedForm;
  }

  setFormType(formType: FormType): void {
    this.selectedFormType = formType;
  }
  getFormType(): FormType {
    if (!this.selectedFormType) return FormType.SIGNUP;
    return this.selectedFormType;
  }
}
