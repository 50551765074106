import {Component, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Router} from "@angular/router";

@Component({
  selector: 'modal-content',
  templateUrl: 'app-modal-content.component.html'
})
export class ModalContentComponent implements OnInit {
  title?: string;
  closeBtnName?: string;
  text: string;
  routingUrl: string;
  type: string;

  constructor(public bsModalRef: BsModalRef, private router: Router) {}

  ngOnInit(): void {}


  closeAndRedirect(): void {
    this.bsModalRef.hide();
    this.router.navigate([this.routingUrl]);
  }
}
