import { HttpClient } from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable()
export class FileService {
    private fileUrl = 'api/files/';

    constructor(private http: HttpClient) {
    }

    saveFile(signUpId: string, formData: FormData) {
      return this.http.post(this.fileUrl + signUpId, formData, {
          reportProgress: true,
          observe: 'events'
      })
    }

    removeFile(signUpId: string, filename: string) {
      return this.http.delete(this.fileUrl + signUpId + '/' + filename);
    }
}
