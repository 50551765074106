import {Component, Input, OnInit} from '@angular/core';
import {SchoolConfiguration} from "../../../shared/model/schoolConfiguration";
import {SchoolService} from "../../../shared/services/school.service";
import {FormService} from "../../../shared/services/form.service";
import {ColorService} from "../../../shared/services/color.service";
import {WorkflowService} from "../../../shared/services/workflow.service";
import {Router} from "@angular/router";
import {BehaviorSubject, Observable} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ValidateFutureDate} from "../../../shared/validators/futuredate.validator";
import {ValidateDate} from "../../../shared/validators/date.validator";

@Component({
  selector: 'app-home-po-start',
  templateUrl: './home-po-start.component.html',
  styleUrls: ['../base-home/base-home-start.component.css','./home-po-start.component.css']
})
export class HomePoStartComponent implements OnInit {

  @Input()
  formId: string;
  dateCheckForm: FormGroup;
  startRegistration = false;
  directLink: string;

  errorMessage: string;
  schools: SchoolConfiguration[] = [];
  private _data: BehaviorSubject<SchoolConfiguration[]> = new BehaviorSubject<SchoolConfiguration[]>([]);

  constructor(
    private router: Router,
    private workflowService: WorkflowService,
    private schoolService: SchoolService,
    private formService: FormService,
    private colorService: ColorService,
    private fb: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.dateCheckForm = this.fb.group({
      startDate: ['',[Validators.required, ValidateFutureDate]],
      birthDate: ['',[Validators.required, ValidateDate]]
    })
    this.directLink = localStorage.getItem('directLink');
    const directLinkPattern = /^[a-zA-Z]\d[a-zA-Z]\d[a-zA-Z]$/;
    if (!directLinkPattern.test(this.directLink)) {
      this.directLink = null;
    }
  }

  public get filteredSchools(): Observable<SchoolConfiguration[]>{
    return this._data.asObservable();
  }
  public get validDates(): boolean {
    return this.dateCheckForm.controls['startDate'].valid && this.dateCheckForm.controls['birthDate'].valid;
  }
  updateSchools(): void {
    this._data.next(this.schools);
  }
  public selectSchools() {
    let bd = this.dateCheckForm.controls['birthDate'].value;
    if (this.isYoungerThan3Years(bd)) {
      this.errorMessage = "U kunt een kind jonger dan 3 jaar niet aanmelden."
      return;
    }
    let sd = this.dateCheckForm.controls['startDate'].value;
    this.schoolService.getPoSchoolsByDates(sd).subscribe(s => {
      this.schools = this.schoolService.filterSchoolsByIsVisibleIsTrue(s);
      if (this.directLink !== undefined && this.directLink !== null) {
        const school = this.schoolService.filterSchoolsByDirectLink(this.schools, this.directLink);
        if (school !== null) {
          this.goToSchool(school.id);
        } else this.errorMessage = 'De school heeft geen actief aanmeldjaar dat overeenkomt met uw startdatum. ' +
          'Wijzig uw startdatum of neem contact op met de school.';
        return;
      }
      this.updateSchools();
    } )
  }

  public resetError() {
    this.errorMessage = undefined;
  }

  isYoungerThan3Years(birthDate: string) {
    const parts = birthDate.split('-');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1],10) - 1; // Month is 0-indexed, so subtract 1
    const year = parseInt(parts[2], 10);
    const inputDate = new Date(year, month, day);

    // Get the current date
    const currentDate = new Date();

    // Calculate 3 years ago
    const referenceDate = new Date(currentDate.getFullYear() - 3, currentDate.getMonth(), currentDate.getDate());

    // Check if the input date is earlier than the reference date
    return inputDate > referenceDate;
  }
  public resetSchools() {
    this.schools = [];
    this.updateSchools();
  }
  public register() {
    this.startRegistration = true;
  }
  public cancel() {
    this.startRegistration = false;
  }
  goToSchool(schoolId: string) {
    localStorage.removeItem('directLink');
    this.schoolService.getSchoolById(schoolId).subscribe((s) => {
      this.schoolService.setSchool(s);
      this.colorService.changeColor(s.stylingId);
      this.formService.getFormById(this.formId ? this.formId : s.registrationFormId).subscribe(form => {
          this.formService.setForm(form);
          let birthDate = this.dateCheckForm.controls['birthDate'].value;
          let startDate = this.dateCheckForm.controls['startDate'].value;
          this.workflowService.startWorkflow(schoolId,startDate,birthDate).subscribe(workflow => {
            this.workflowService.setWorkflow(workflow,false);
            this.router.navigate(['/po/aanmelding/leerling']);
          })
      });
    })
  }
}
