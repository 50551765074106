import {AbstractControl} from "@angular/forms";

export function ValidateBSN(control: AbstractControl) {
  const value = typeof control.value === 'string' ? control.value : String(control.value);

  if (!value) {
    return null;
  }

  if (value.length != 9) {
    return {
      'appCheckBSN': {value: value}
    };
  }

  let sum = 0;
  for (let i = 0; i < 8; i++) {
    sum += (9 - i) * parseInt(value[i]);
  }
  sum -= parseInt(value[8]);

  if (((sum % 11) === 0)) {
    return null;
  } else {
    return {
      'appCheckBSN': {value: value}
    };
  }
}
