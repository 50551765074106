import {Observable} from "rxjs";
import {SentFile, UploadedDocumentType} from "./sentfile";

export interface FormField {
  label: string;
  type: FieldType;
  controlType: ControlType;
  explanation: string;
  varName: string;
  required: boolean;
  enabled: boolean;
  infoText: string;
  helpText: string;
  nrColumns: number;
  rowBreak: boolean;
  condition: ConditionType[];
  options: SelectOption[];
  options$: Observable<SelectOption[]>
  conditionalDisabled: boolean;
  hideLabel: boolean;
  callbackFn: () => void;
  changeFn: () => void;
  errorMessage: string;
  autoCompletor: AutoCompletor;
  uploader: Uploader;
  placeHolder: string;
  rulesSet?: FieldRuleSet;
}

export class AutoCompletor {
  data: any;
  template: string;
  inputFn: () => void;
  fallBackFn: () => void;
  onSelectFn: (event,parent) => void;
  parent: any;
}

export class Uploader {
  files: SentFile[];
  parent: any;
  type: UploadedDocumentType;
  constructor(files,type,par) {
    this.files = files;
    this.type = type;
    this.parent = par;
  }
}

export class SelectOption{
  value: string;
  text: string;

  constructor(val: string, txt: string) {
    this.value = val;
    this.text = txt;
  }
}

export enum FieldType {
  BSN = "BSN",
  OWN = "OWN",
  IBAN = "IBAN",
  FIRSTNAME = "FIRSTNAME",
  PREFIX = "PREFIX",
  LASTNAME = "LASTNAME",
  BIRTHDATE = "BIRTHDATE",
  DATE = "DATE",
  CLUSTERDATE = "CLUSTERDATE",
  FUTUREDATE = "FUTUREDATE",
  GENDER = "GENDER",
  CITY = "CITY",
  COUNTRY = "COUNTRY",
  NATIONALITY = "NATIONALITY",
  ZIPCODE = "ZIPCODE",
  POSTALCODE = "POSTALCODE",
  HOUSENUMBER = "HOUSENUMBER",
  HOUSENUMBER_INT = "HOUSENUMBER_INT",
  HOUSENUMBER_ADDITION = "HOUSENUMBER_ADDITION",
  STREET = "STREET",
  PHONE_MOBILE = "PHONE_MOBILE",
  OT_CODE = "OT_CODE",
  YES_NO = "YES_NO",
  EMAIL = "EMAIL",
  SUBTITLE = "SUBTITLE",
  INFORMATION = "INFORMATION",
  CHECK = "CHECK",
  CUSTOM_CHECK = "CUSTOM_CHECK",
  CUSTOM_INFORMATION = "CUSTOM_INFORMATION",
  CUSTOM_DOCUMENT = "CUSTOM_DOCUMENT",
  IMAGE = "IMAGE"
}

export enum ControlType {
  TEXT = "TEXT",
  TEXTAREA = "TEXTAREA",
  CHECKBOX = "CHECKBOX",
  RADIO = "RADIO",
  SELECT = "SELECT",
  HIDDEN = "HIDDEN",
  READONLY= "READONLY",
  READONLY_OR_HIDDEN= "READONLY_OR_HIDDEN",
  SELECT_OR_HIDDEN = "SELECT_OR_HIDDEN",
  SUBTITLE = "SUBTITLE",
  INFORMATION = "INFORMATION",
  CANVAS = "CANVAS",
  MULTIPLE_UPLOAD = "MULTIPLE_UPLOAD"
}

export enum ConditionType {
  FIRST_YEAR = "FIRST_YEAR",
  HIGHER_YEAR = "HIGHER_YEAR",
  LOCATION_NOT_EMPTY = "LOCATION_NOT_EMPTY",
  EDUCATION_TYPE_NOT_EMPTY = "EDUCATION_TYPE_NOT_EMPTY",
  INFLUX = "INFLUX",
  NOT_INFLUX = "NOT_INFLUX",
  YEAR_NOT_EMPTY = "YEAR_NOT_EMPTY",
  LEVEL_NOT_EMPTY = "LEVEL_NOT_EMPTY",
  PROFILES_NOT_EMPTY = "PROFILES_NOT_EMPTY",
  LESSONS_NOT_EMPTY = "LESSONS_NOT_EMPTY",
  EDUCATION_TYPE = "EDUCATION_TYPE",
  LWOO = "LWOO",
  BORN_OUTSIDE_NL = "BORN_OUTSIDE_NL",
  BORN_IN_NL = "BORN_IN_NL",
  USE_PLACE_OF_BIRTH = "USE_PLACE_OF_BIRTH",
  SECOND_CHOICE = "SECOND_CHOICE",
  SECOND_SCHOOLNAME = "SECOND_SCHOOLNAME",
  SECOND_SCHOOLLOCATION = "SECOND_SCHOOLLOCATION",
  SECOND_EDUCATIONTYPE = "SECOND_EDUCATIONTYPE",
  SECOND_EDUCATIONLEVEL = "SECOND_EDUCATIONLEVEL",
  THIRD_SCHOOLNAME = "THIRD_SCHOOLNAME",
  THIRD_SCHOOLLOCATION = "THIRD_SCHOOLLOCATION",
  THIRD_EDUCATIONTYPE = "THIRD_EDUCATIONTYPE",
  THIRD_EDUCATIONLEVEL = "THIRD_EDUCATIONLEVEL",
  FOURTH_SCHOOLNAME = "FOURTH_SCHOOLNAME",
  FOURTH_SCHOOLLOCATION = "FOURTH_SCHOOLLOCATION",
  FOURTH_EDUCATIONTYPE = "FOURTH_EDUCATIONTYPE",
  FOURTH_EDUCATIONLEVEL = "FOURTH_EDUCATIONLEVEL",
  FIFTH_SCHOOLNAME = "FIFTH_SCHOOLNAME",
  FIFTH_SCHOOLLOCATION = "FIFTH_SCHOOLLOCATION",
  FIFTH_EDUCATIONTYPE = "FIFTH_EDUCATIONTYPE",
  LAS_MAGISTER = "LAS_MAGISTER",
  LAS_SOMTODAY = "LAS_SOMTODAY",
  SECOND_CARETAKER = "SECOND_CARETAKER",
  THIRD_CARETAKER = "THIRD_CARETAKER",
  LIVES_ELSEWHERE = "LIVES_ELSEWHERE",
  PARENT_DIED = "PARENT_DIED",
  PARENTAL_AUTHORITY_NOT_BOTH = "PARENTAL_AUTHORITY_NOT_BOTH",
  PARENTAL_AUTHORITY_OTHERWISE = "PARENTAL_AUTHORITY_OTHERWISE",
  PREVIOUS_VO = "PREVIOUS_VO",
  PREVIOUS_ABROAD = "PREVIOUS_ABROAD",
  MEDICAL_DIAGNOSIS = "MEDICAL_DIAGNOSIS",
  NEED_FOR_SUPPORT = "NEED_FOR_SUPPORT",
  HAS_OPP = "HAS_OPP",
  CLUSTER_INDICATION = "CLUSTER_INDICATION",
  MEDICAL_PARTICULARITIES = "MEDICAL_PARTICULARITIES",
  HAS_MEDICATION_USE = "HAS_MEDICATION_USE",
  ATTACHMENTS = "ATTACHMENTS",
  NOT_SEND_BY_POST = "NOT_SEND_BY_POST"
}


export interface FieldRuleSet {
  condition: string;
  rules: FieldRule[];
}

export interface FieldRule {
  field: string;
  operator: string;
  type: string;
  value: any;
  condition: string;
  rules: FieldRule[];
}
