import {AbstractControl} from "@angular/forms";

export function ValidateDate(control: AbstractControl) {
  const value = control.value;

  if (!value) {
    return null;
  }

  if (value.length === 10) {
    const day = Number(value.substr(0, 2));
    const month = Number(value.substr(3, 2)) - 1;
    const year = Number(value.substr(6, 4));

    const hasValidSeparators = value.charAt(2) == '-' && value.charAt(5) == '-';

    const date = new Date(year, month, day);
    const isValid = date.getFullYear() === year && date.getMonth() === month && date.getDate() === day;
    const isInPast = date.getTime() < new Date().getTime();

    // Add realistic birth year, not older than 67 years from today.
    const realisticYear = new Date();
    realisticYear.setFullYear(realisticYear.getFullYear() - 67);
    const isRealisticDate = date > realisticYear;
    if (isValid && isInPast && hasValidSeparators && isRealisticDate) {
      return null;
    }
  }
  return {'appDateCheck': { value: value } };
}
