import {AbstractControl} from "@angular/forms";

function isEmptyInputValue(value: any): boolean {
  /**
   * Check if the object is a string or array before evaluating the length attribute.
   * This avoids falsely rejecting objects that contain a custom length attribute.
   * For example, the object {id: 1, length: 0, width: 0} should not be returned as empty.
   */
  return value == null ||
    ((typeof value === 'string' || Array.isArray(value)) && value.length === 0);
}

export function ValidateDutchPostalCode(control: AbstractControl) {
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }

  const value = typeof control.value === 'string' ? control.value : String(control.value);

  let parsedValue: string = String(value);

  /**
   * https://nl.wikipedia.org/wiki/Postcodes_in_Nederland
   * https://stackoverflow.com/questions/17898523/regular-expression-for-dutch-zip-postal-code
   * postcode no leading 0, SA/SD/SS are no valid combi
   */
  const regexp = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
  if (!regexp.test(parsedValue)) {
    return { invalidPostalCode: true };
  }
  return null;
}

export function ValidateGermanPostalCode(control: AbstractControl) {
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }

  const value = typeof control.value === 'string' ? control.value : String(control.value);

  let parsedValue: string = String(value);

  // test for illegal characters
  const regexp = /^D-\d{5}$|^\d{5}$/;
  if (!regexp.test(parsedValue)) {
    return { invalidPostalCode: true };
  }
  return null;
}

export function ValidateBelgianPostalCode(control: AbstractControl) {
  if (isEmptyInputValue(control.value)) {
    return null;  // don't validate empty values to allow optional controls
  }

  const value = typeof control.value === 'string' ? control.value : String(control.value);

  let parsedValue: string = String(value);

  // test for illegal characters
  const regexp = /^\d{4}$/;
  if (!regexp.test(parsedValue)) {
    return { invalidPostalCode: true };
  }
  return null;
}
