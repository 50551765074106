import {AbstractControl} from "@angular/forms";

export function ValidateOTCode(control: AbstractControl) {
  const value = typeof control.value === 'string' ? control.value : String(control.value);

  if (value === undefined || value === null || value === '' || value === 'null') {
    return null;
  }

  const regex = /^[a-zA-Z0-9]{8}$/;

  if (regex.test(value)) {
    return null;
  } else {
    return {
      'appCheckOT': {value: value}
    };
  }
}
