import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable} from "rxjs";
import {Status} from "../model/status";

const httpOptions = {
    headers: new HttpHeaders({'Content-Type': 'application/json'})
};

@Injectable()
export class StatusService {
    private statusUrl = 'api/status/';

    constructor(private http: HttpClient) {
    }

    getStatus(): Observable<Status> {
      return this.http.get<Status>(this.statusUrl);
    }

  setPortalType(t: string): void {
    let nr = window.location.hostname.substring(0,4);
    sessionStorage.setItem("portalType" + nr,t);
  }

  getPortalType(): string {
    let nr = window.location.hostname.substring(0,4);
    return sessionStorage.getItem("portalType" + nr);
  }
}
