import {CareTakers, Signature, SignUp, Student} from "./signup";
import {CustomForm, CustomSection} from "./customForm";

export interface Workflow {
  id?: string;
  schoolYear: string;
  accountId: string;
  schoolId: string;
  createdAt: string;
  modifiedAt: string;
  workflowStatus: string;
  referralStatus: string;
  isInflux: boolean;
  isTest: boolean;
  registrationId: string;
  registration: Registration;
  intakeId: string;
  intake: Intake;
  signUpId: string;
  signUp: SignUp;
  schoolName: string;
  referral: Referral;
}

export interface Registration {
  id?: string;
  formId: string;
  student: Student;
  careTakers: CareTakers;
  signature: Signature;
  lastStep: string;
  isCompleted: boolean;
  pdfReady: boolean;
  schoolName: string;
  fullName: string;
}

export interface Intake extends CustomForm {
  signature: Signature;
  customSections: CustomSection[];
}

export interface IntakeDTO {
  formId: string;
  lastStep: string;
  customSections: CustomSection[];
}

export interface RegistrationDTO {
  id?: string;
  fullName: string;
  lastStep: string;
  status: string;
  isCompleted: boolean;
  pdfReady: boolean;
}

export interface Referral {
  fromSchoolId: string;
  fromSchoolName: string;
  toSchoolId: string;
  toSchoolName: string;
  referredAt: string;
  noteToParent: string;
}

export enum WorkflowStatus {
  REG_INPROGRESS="In bewerking",
  REG_SENT = "Aanmelding verstuurd",
  INTAKE_COMPLETED = "Intake afgerond",
  INTAKE_INVITE = "Uitgenodigd voor intake",
  INTAKE_INPROGRESS="Intake in bewerking",
  FORWARDED_SENT="Aanmelding is doorverwezen",
  FORWARDED_ACCEPTED="Doorverwijzing is geaccepteerd",
  SIGNUP_INVITE="Inschrijven mogelijk",
  SIGNUP_INPROGRESS="Inschrijving in bewerking",
  SIGNUP_SENT = "Inschrijving afgerond",
  EXPORTED = "Inschrijving afgerond" // in ap exported is same as signup_sent
}

export enum ReferralStatus {
  PENDING = "Doorverwijzing in afwachting van akkoord",
  APPROVED="Doorverwijzing goedgekeurd",
  REJECTED="Doorverwijzing afgewezen",
  REFERRED="Doorverwezen"
}
